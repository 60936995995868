/* eslint-disable react/prop-types */
/* eslint-disable operator-linebreak */
import React from 'react';
import { Link } from 'react-router-dom';

const WebServicePrimaryButton = (props: any) => {
  const { isDisabled, buttonName, onClick, link, size } = props;

  return (
    <Link to={link} style={{ width: '100%' }}>
      <button
        className={
          'web-service-primary-button' +
          (isDisabled ? ' disabled' : '') +
          (size === 'small' ? ' small' : '')
        }
        onClick={onClick}
      >
        {buttonName}
      </button>
    </Link>
  );
};

export default WebServicePrimaryButton;
