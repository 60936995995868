/* eslint-disable indent */
import React, { useState } from 'react';

import WebServiceWrapper from 'components/web_service_wrapper';
import WebServiceNormalBody from 'components/web_service/body/normal_body';
import WebServiceCenterTitleHeader from 'components/web_service/header/center_title';
import WebServiceMoreCard from 'components/more/card';
import { useSelector, useDispatch } from 'react-redux';
import {
  switchUseAvailableTime,
  selectAvailableVoice,
  selectAvailableDate,
  changeAvailableStartTime,
  changeAvailableEndTime
} from 'redux/more/available_setting';
import WebServiceSubTitle from 'components/web_service/content_title/sub_title';
import WebServiceBackTextHeader from 'components/web_service/header/back_text';
import WebServiceMoreTextarea from 'components/more/textarea';
import WebServiceOkModal from 'components/web_service/modal/ok_modal';

const WebServiceMoreAvailable = () => {
  const [useTextInput, setUseTextInput] = useState(false);
  const [usePickDate, setUsePickDate] = useState(false);
  const [usePickStartTime, setUsePickStartTime] = useState(false);
  const [usePickEndTime, setUsePickEndTime] = useState(false);

  if (useTextInput && !usePickDate && !usePickStartTime && !usePickEndTime) {
    return (
      <WebServiceMoreAvailableType
        useTextInput={useTextInput}
        setUseTextInput={setUseTextInput}
      />
    );
  } else if (
    !useTextInput &&
    usePickDate &&
    !usePickStartTime &&
    !usePickEndTime
  ) {
    return (
      <WebServiceMoreAvailableDate
        usePickDate={usePickDate}
        setUsePickDate={setUsePickDate}
      />
    );
  } else if (
    !useTextInput &&
    !usePickDate &&
    (usePickStartTime || usePickEndTime)
  ) {
    return (
      <WebServiceMoreAvailableTime
        usePickStartTime={usePickStartTime}
        usePickEndTime={usePickEndTime}
        setUsePickStartTime={setUsePickStartTime}
        setUsePickEndTime={setUsePickEndTime}
      />
    );
  } else {
    return (
      <WebServiceMoreAvailableSetting
        useTextInput={useTextInput}
        setUseTextInput={setUseTextInput}
        setUsePickDate={setUsePickDate}
        setUsePickStartTime={setUsePickStartTime}
        setUsePickEndTime={setUsePickEndTime}
      />
    );
  }
};

const WebServiceMoreAvailableType = (props: any) => {
  const { useTextInput, setUseTextInput } = props;
  return (
    <WebServiceWrapper hideNav={false} isCalling={false} scrollLock={false}>
      <WebServiceBackTextHeader
        title="부재중 안내문구"
        rightButtonText="완료"
        rightOnClick={() => setUseTextInput(false)}
        backState={useTextInput}
        setBackState={setUseTextInput}
      />
      <WebServiceNormalBody type="normal" useOverlay={false} background="">
        <WebServiceMoreTextarea />
      </WebServiceNormalBody>
    </WebServiceWrapper>
  );
};

const WebServiceMoreAvailableDate = (props: any) => {
  const dispatch = useDispatch();
  const { availableSetting }: any = useSelector((state) => state);
  const { usePickDate, setUsePickDate } = props;

  return (
    <WebServiceWrapper hideNav={false} isCalling={false} scrollLock={false}>
      <WebServiceCenterTitleHeader
        title="통화 가능 요일"
        backState={usePickDate}
        setBackState={setUsePickDate}
      />
      <WebServiceNormalBody type="normal" useOverlay={false} background="">
        {availableSetting.availableDate.map((item: any, idx: number) => (
          <WebServiceMoreCard
            key={idx}
            type="checkboxOnly"
            name={item.name}
            bottomBorder={true}
            isChecked={item.selected}
            onClick={() => dispatch(selectAvailableDate(idx))}
          />
        ))}
      </WebServiceNormalBody>
    </WebServiceWrapper>
  );
};

const WebServiceMoreAvailableTime = (props: any) => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const { availableSetting }: any = useSelector((state) => state);
  const {
    usePickStartTime,
    setUsePickStartTime,
    usePickEndTime,
    setUsePickEndTime
  } = props;
  const [inputHour, setInputHour] = useState(
    usePickStartTime
      ? availableSetting.availableStartTime.substring(0, 2)
      : usePickEndTime
      ? availableSetting.availableEndTime.substring(0, 2)
      : ''
  );
  const [inputMinute, setInputMinute] = useState(
    usePickStartTime
      ? availableSetting.availableStartTime.substring(3, 5)
      : usePickEndTime
      ? availableSetting.availableEndTime.substring(3, 5)
      : ''
  );
  const headerRightHandleChange = () => {
    if (inputHour && inputMinute) {
      const hour =
        inputHour.toString().length === 1
          ? '0' + inputHour.toString()
          : inputHour;
      const minute =
        inputMinute.toString().length === 1
          ? '0' + inputMinute.toString()
          : inputMinute;
      if (usePickStartTime) {
        dispatch(changeAvailableStartTime(hour + ':' + minute));
      } else if (usePickEndTime) {
        dispatch(changeAvailableEndTime(hour + ':' + minute));
      }
      setTimeout(() => {
        setUsePickStartTime(false);
        setUsePickEndTime(false);
      }, 500);
    } else {
      setOpenModal(true);
    }
  };

  const hourInputHandleChange = (e: any) => {
    const target = e.target.value;
    if (target.length <= 2 && Number(target) < 24) {
      setInputHour(e.target.value);
    }
  };

  const minuteInputHandleChange = (e: any) => {
    const target = e.target.value;
    if (target.length <= 2 && Number(target) < 60) {
      setInputMinute(e.target.value);
    }
  };

  return (
    <WebServiceWrapper hideNav={false} isCalling={false} scrollLock={false}>
      <WebServiceBackTextHeader
        title={
          usePickStartTime
            ? '통화가능 시작시간'
            : usePickEndTime
            ? '통화가능 종료시간'
            : null
        }
        backState={
          usePickStartTime
            ? usePickStartTime
            : usePickEndTime
            ? usePickEndTime
            : null
        }
        setBackState={
          usePickStartTime
            ? setUsePickStartTime
            : usePickEndTime
            ? setUsePickEndTime
            : null
        }
        rightButtonText="저장"
        rightOnClick={headerRightHandleChange}
      />
      <WebServiceNormalBody type="normal" useOverlay={false} background="">
        <div className="web-service-more-available-time-wrap">
          <div className="web-service-more-available-time-set">
            <div className="web-service-more-available-time-label">시</div>
            <input
              type="number"
              maxLength={2}
              defaultValue={
                usePickStartTime
                  ? availableSetting.availableStartTime.substring(0, 2)
                  : usePickEndTime
                  ? availableSetting.availableEndTime.substring(0, 2)
                  : '00'
              }
              className="web-service-more-available-time-input"
              onChange={hourInputHandleChange}
              value={inputHour}
            />
          </div>
          <div className="web-service-more-available-time-dots">:</div>
          <div className="web-service-more-available-time-set">
            <div className="web-service-more-available-time-label">분</div>
            <input
              type="number"
              maxLength={2}
              defaultValue={
                usePickStartTime
                  ? availableSetting.availableStartTime.substring(3, 5)
                  : usePickEndTime
                  ? availableSetting.availableEndTime.substring(3, 5)
                  : '00'
              }
              className="web-service-more-available-time-input"
              onChange={minuteInputHandleChange}
              value={inputMinute}
            />
          </div>
        </div>
      </WebServiceNormalBody>
      <WebServiceOkModal
        title="입력 오류"
        text="시간이 정상적으로 입력되지 않았습니다."
        buttonName="확인"
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
    </WebServiceWrapper>
  );
};

const WebServiceMoreAvailableSetting = (props: any) => {
  const {
    setUseTextInput,
    setUsePickDate,
    setUsePickStartTime,
    setUsePickEndTime
  } = props;
  const dispatch = useDispatch();
  const { availableSetting }: any = useSelector((state) => state);
  return (
    <WebServiceWrapper hideNav={false} isCalling={false} scrollLock={false}>
      <WebServiceCenterTitleHeader title="통화 가능 시간대 설정" />
      <WebServiceNormalBody type="more-78" useOverlay={false} background="">
        <WebServiceMoreCard
          type="switch"
          name="통화 가능 시간대 사용"
          bottomBorder={false}
          outerText="전화를 받을 요일과 시간대 설정을 사용합니다."
          isSwitchOn={availableSetting.useAvailableTime}
          onClick={() => dispatch(switchUseAvailableTime())}
        />
        <div
          className={
            'web-service-more-hide' +
            (availableSetting.useAvailableTime ? ' active mh800' : '')
          }
        >
          <WebServiceMoreCard
            type="arrowWithText"
            name="통화 가능 요일"
            bottomBorder={false}
            rightText={
              (availableSetting.availableDate[0].selected
                ? availableSetting.availableDate[0].name + ' '
                : '') +
              (availableSetting.availableDate[1].selected
                ? availableSetting.availableDate[1].name + ' '
                : '') +
              (availableSetting.availableDate[2].selected
                ? availableSetting.availableDate[2].name + ' '
                : '') +
              (availableSetting.availableDate[3].selected
                ? availableSetting.availableDate[3].name + ' '
                : '') +
              (availableSetting.availableDate[4].selected
                ? availableSetting.availableDate[4].name + ' '
                : '') +
              (availableSetting.availableDate[5].selected
                ? availableSetting.availableDate[5].name + ' '
                : '') +
              (availableSetting.availableDate[6].selected
                ? availableSetting.availableDate[6].name + ' '
                : '')
            }
            onClick={() => setUsePickDate(true)}
          />
          <WebServiceSubTitle title="통화 가능 시간" />
          <WebServiceMoreCard
            type="arrowWithText"
            name="시작"
            bottomBorder={true}
            rightText={availableSetting.availableStartTime}
            onClick={() => setUsePickStartTime(true)}
          />
          <WebServiceMoreCard
            type="arrowWithText"
            name="종료"
            bottomBorder={false}
            rightText={availableSetting.availableEndTime}
            outerText="통화 가능 시간으로 설정한 시간 내에만 전화를 수신합니다."
            onClick={() => setUsePickEndTime(true)}
          />
          <WebServiceSubTitle title="통화 불가능 시간 ARS 음성" />
          {availableSetting.availableVoice.map((item: any, idx: number) => (
            <WebServiceMoreCard
              key={idx}
              type={item.type}
              name={item.name}
              bottomBorder={availableSetting.availableVoice.length !== idx + 1}
              isRadioOn={item.selected}
              onClick={() => dispatch(selectAvailableVoice(idx))}
            />
          ))}
          <div
            className={
              'web-service-more-hide' +
              (availableSetting.availableVoice.find(
                (item: any) => item.stateType === 'custom'
              ).selected
                ? ' active mh200'
                : '')
            }
          >
            <WebServiceSubTitle title="출력할 음성 설정" />
            <WebServiceMoreCard
              type="arrowOnly"
              name="출력할 음성"
              bottomBorder={false}
              innerText="안녕하세요.
믿음과 신뢰를 바탕으로 고객만족에 최선을 다하는 000 입니다.
전화주셔서 감사합니다. 오늘도 좋은하루되시길 기원하면서 오늘의 노래들려드립니다."
              onClick={() => setUseTextInput(true)}
            />
          </div>
        </div>
      </WebServiceNormalBody>
    </WebServiceWrapper>
  );
};

export default WebServiceMoreAvailable;
