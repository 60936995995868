/* eslint-disable max-len */
import WebServiceNormalBody from 'components/web_service/body/normal_body';
import WebServiceContactGroup from 'components/web_service/contact/contact_group';
import WebServiceContactHeader from 'components/web_service/header/contact';
// import WebServiceNoResult from 'components/web_service/no_result/no_result';
import WebServiceWrapper from 'components/web_service_wrapper';
import WebServiceSearchResultCardContact from 'components/web_service/search/result_card_contact';
import { ContactListItems } from 'configs/items';
import React, { useState } from 'react';
import store from 'redux/store';
import WebServiceTopSheetMenuContainer from 'components/web_service/fixed_component/top_sheet_menu_container';
import WebServiceSearchHeader from 'components/web_service/header/search';
import WebServiceSearchHistory from 'components/web_service/search/search_history';

const WebServiceContact = () => {
  const [openTopSheetMenu, setOpenTopSheetMenu] = useState(false);
  const [searchMode, setSearchMode] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [enterList, setEnterList] = useState<
    Array<{ name: string; url: string }>
  >([{ name: '터치콜', url: '' }]);
  const contactGroupList = [
    { name: '조직도', url: '' },
    { name: '개인주소록', url: '' }
  ];

  const searchHandleChange = (e: any) => {
    setSearchValue(e.target.value);
  };
  const listHandleChange = (name: any, url: any) => {
    setEnterList([...enterList, { name: name, url: url }]);
    console.log(enterList);
  };
  const onReset = () => {
    setSearchValue('');
  };
  const onCancel = () => {
    setSearchValue('');
    setSearchMode(false);
  };

  return (
    <WebServiceWrapper hideNav={false} isCalling={false} scrollLock={false}>
      {!searchMode && !editMode ? (
        <WebServiceContactHeader
          title={'주소록'}
          onCreateButtonClick={() => setOpenTopSheetMenu(true)}
          onSearchButtonClick={() => setSearchMode(true)}
          onSettingButtonClick={() => setEditMode(true)}
        />
      ) : null}
      {searchMode && !editMode ? (
        <WebServiceSearchHeader
          onCancel={() => onCancel()}
          searchValue={searchValue}
          onSearchValueChange={searchHandleChange}
          onSearchValueRemove={onReset}
        />
      ) : null}
      <WebServiceNormalBody
        type="normal"
        useOverlay={openTopSheetMenu}
        background=""
      >
        {!searchMode && !searchValue && !editMode && enterList.length === 1 ? (
          <div className="web-service-contact-folder-tab slider">
            {enterList.map((item: any, idx: any) => (
              <>
                <div
                  key={idx}
                  className="web-service-contact-folder-tab-name"
                  onClick={() => setEnterList([{ name: '터치콜', url: '' }])}
                >
                  {item.name}
                </div>
              </>
            ))}
          </div>
        ) : null}
        {!searchMode && !searchValue && !editMode && enterList.length > 1 ? (
          <div className="web-service-contact-folder-tab slider">
            {enterList.map((item: any, idx: any) => (
              <>
                <div
                  key={idx}
                  className="web-service-contact-folder-tab-name"
                  onClick={() => setEnterList([{ name: '터치콜', url: '' }])}
                >
                  {item.name}
                </div>
                {idx === enterList.length - 1 ? null : (
                  <div className="web-service-contact-folder-tab-arrow-wrap">
                    <img
                      src={
                        '/icon/' +
                        (store.getState().theme.theme === 'light'
                          ? 'lightmode/'
                          : 'darkmode/') +
                        'arrow_right-t3.png'
                      }
                      className="web-service-contact-folder-tab-arrow"
                    />
                  </div>
                )}
              </>
            ))}
          </div>
        ) : null}
        {!searchMode && !searchValue && !editMode && enterList.length === 1 ? (
          <>
            <WebServiceContactGroup
              useIcon={true}
              icon="org-outline-p1.png"
              fullName="조직도"
              equalName=""
              name="조직도"
              isFirst={true}
              onClick={() =>
                listHandleChange(
                  contactGroupList[0].name,
                  contactGroupList[0].url
                )
              }
              useArrow={true}
            />
            <WebServiceContactGroup
              useIcon={true}
              icon="person-outline-p1.png"
              fullName="개인 주소록"
              equalName=""
              name="개인 주소록"
              isFirst={false}
              onClick={() =>
                listHandleChange(
                  contactGroupList[1].name,
                  contactGroupList[1].url
                )
              }
              useArrow={true}
            />
            <div className="web-service-contact-bookmark-wrap">
              <div className="web-service-contact-bookmark-title">즐겨찾기</div>
              {/* <WebServiceNoResult
            type={'bookmark'}
            text={'즐겨찾기로 설정된 사람이 없습니다.'}
          /> */}
              <div>
                {ContactListItems.map((item: any, idx: any) => (
                  <WebServiceSearchResultCardContact
                    key={idx}
                    equalName={item.name}
                    name={item.name}
                    equalNumber={item.number}
                    number={item.number}
                    profile={item.profile}
                    state={item.state}
                    padding={true}
                    canSpread={true}
                  />
                ))}
              </div>
            </div>
          </>
        ) : null}
        {!searchMode && !searchValue && !editMode && enterList.length > 1 ? (
          <>
            <WebServiceContactGroup
              useIcon={false}
              fullName="개발팀"
              equalName=""
              name="개발팀"
              isFirst={true}
              onClick={() =>
                listHandleChange(
                  contactGroupList[0].name,
                  contactGroupList[0].url
                )
              }
              useArrow={true}
            />
            <WebServiceContactGroup
              useIcon={false}
              fullName="디자인팀"
              equalName=""
              name="디자인팀"
              isFirst={false}
              onClick={() =>
                listHandleChange(
                  contactGroupList[0].name,
                  contactGroupList[0].url
                )
              }
              useArrow={true}
            />
            <WebServiceContactGroup
              useIcon={false}
              fullName="기획팀"
              equalName=""
              name="기획팀"
              isFirst={false}
              onClick={() =>
                listHandleChange(
                  contactGroupList[0].name,
                  contactGroupList[0].url
                )
              }
              useArrow={true}
            />
            <WebServiceContactGroup
              useIcon={false}
              fullName="운영팀"
              equalName=""
              name="운영팀"
              isFirst={false}
              onClick={() =>
                listHandleChange(
                  contactGroupList[0].name,
                  contactGroupList[0].url
                )
              }
              useArrow={true}
            />
            <WebServiceContactGroup
              useIcon={false}
              fullName="인사팀"
              equalName=""
              name="인사팀"
              isFirst={false}
              onClick={() =>
                listHandleChange(
                  contactGroupList[0].name,
                  contactGroupList[0].url
                )
              }
              useArrow={true}
            />
            {ContactListItems.map((item: any, idx: any) => (
              <WebServiceSearchResultCardContact
                key={idx}
                equalName={item.name}
                name={item.name}
                equalNumber={item.number}
                number={item.number}
                profile={item.profile}
                state={item.state}
                padding={true}
                canSpread={true}
              />
            ))}
          </>
        ) : null}
        {searchMode && !searchValue && !editMode ? (
          <WebServiceSearchHistory
            items={[
              { keyword: '홍길동', date: '2022. 12. 31' },
              { keyword: '김길동', date: '2022. 12. 31' },
              { keyword: '최민동', date: '2022. 12. 31' }
            ]}
          />
        ) : null}
        {searchMode && searchValue && !editMode ? (
          <div className="web-service-search-contact-result-wrap">
            <WebServiceContactGroup
              icon=""
              fullName="홍길동팀"
              equalName="홍길"
              name="동팀"
              isFirst={true}
            />
            {ContactListItems.map((item: any, idx: any) => (
              <WebServiceSearchResultCardContact
                key={idx}
                name={item.name}
                number={item.number}
                profile={item.profile}
                state={item.state}
                padding={true}
                canSpread={true}
              />
            ))}
          </div>
        ) : null}
      </WebServiceNormalBody>

      <WebServiceTopSheetMenuContainer
        items={[
          {
            type: 'normal',
            name: '직접 추가',
            link: '/app/web_service/add_contact'
          },
          {
            type: 'normal',
            name: '내 핸드폰에서 찾기',
            link: '/app/web_service/contact/add_from_my'
          }
          // {
          //   type: 'normal',
          //   name: '명함 촬영',
          //   link: '/app/web_service_contact_camera'
          // }
        ]}
        openTopSheetMenu={openTopSheetMenu}
        setOpenTopSheetMenu={setOpenTopSheetMenu}
        useAlarm={false}
      />
    </WebServiceWrapper>
  );
};

export default WebServiceContact;
