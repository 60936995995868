/* eslint-disable react/prop-types */
/* eslint-disable operator-linebreak */
import React from 'react';
import store from 'redux/store';

const WebServiceCheckbox = (props: any) => {
  const { isLabel, label, labelSize, isChecked } = props;

  return (
    <div className="web-service-checkbox-wrap">
      <div className={`web-service-checkbox-img-wrap ${isChecked}`}>
        <img
          src={
            '/icon/' +
            (store.getState().theme.theme === 'light'
              ? 'lightmode/'
              : 'darkmode/') +
            'checkbox-false.png'
          }
          className={'web-service-checkbox-false'}
          alt="checkbox button"
        />
        <img
          src={
            '/icon/' +
            (store.getState().theme.theme === 'light'
              ? 'lightmode/'
              : 'darkmode/') +
            'checkbox-true.png'
          }
          className={'web-service-checkbox-true'}
          alt="checkbox button"
        />
      </div>
      {isLabel ? (
        <div
          className={
            'web-service-checkbox-label fs-16' +
            (labelSize === 'big' ? ' fw-500' : ' fw-400')
          }
        >
          {label}
        </div>
      ) : null}
    </div>
  );
};

export default WebServiceCheckbox;
