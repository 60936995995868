/* eslint-disable operator-linebreak */
import React from 'react';
import store from 'redux/store';
import { useNavigate } from 'react-router-dom';

import 'stylesheets/web_service/nav.scss';

const WebServiceBackTextHeader = (props: any) => {
  const navigate = useNavigate();
  const { title, rightButtonText, rightOnClick, backState, setBackState } =
    props;

  const goBack = () => {
    if (backState) {
      setBackState(false);
    } else {
      navigate(-1);
    }
  };

  return (
    <div className="web-service-center-title-header-container">
      <div className="web-service-center-title-header-left" onClick={goBack}>
        <img
          src={
            '/icon/' +
            (store.getState().theme.theme === 'light'
              ? 'lightmode/'
              : 'darkmode/') +
            'arrow_left-t1.png'
          }
          className="web-service-center-title-left-icon"
          alt="back"
        />
      </div>
      <div className="web-service-center-title-header-center">
        <div className="web-service-center-title-header-title">{title}</div>
      </div>
      <div
        className={'web-service-center-title-header-right-submit'}
        onClick={rightOnClick}
      >
        {rightButtonText}
      </div>
    </div>
  );
};

export default WebServiceBackTextHeader;
