/* eslint-disable indent */
import React from 'react';

import WebServiceWrapper from 'components/web_service_wrapper';
import WebServiceNormalBody from 'components/web_service/body/normal_body';
import WebServiceCenterTitleHeader from 'components/web_service/header/center_title';
import WebServiceMoreCard from 'components/more/card';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectInputDevice,
  selectOutputDevice
} from 'redux/more/voice_device_setting';
import WebServiceMoreSubtitle from 'components/more/subtitle';

const WebServiceMoreVoiceDevice = () => {
  const dispatch = useDispatch();
  const { voiceDeviceSetting }: any = useSelector((state) => state);
  return (
    <WebServiceWrapper hideNav={false} isCalling={false} scrollLock={false}>
      <WebServiceCenterTitleHeader title="착신 전환 설정" />
      <WebServiceNormalBody type="more" useOverlay={false} background="">
        <WebServiceMoreSubtitle title="입력 디바이스" />
        {voiceDeviceSetting.inputDevice.map((item: any, idx: number) => (
          <WebServiceMoreCard
            key={idx}
            type={item.type}
            name={item.name}
            bottomBorder={voiceDeviceSetting.inputDevice.length !== idx + 1}
            isRadioOn={item.selected}
            onClick={() => dispatch(selectInputDevice(idx))}
          />
        ))}
        <WebServiceMoreSubtitle title="출력 디바이스" />
        {voiceDeviceSetting.outputDevice.map((item: any, idx: number) => (
          <WebServiceMoreCard
            key={idx}
            type={item.type}
            name={item.name}
            bottomBorder={voiceDeviceSetting.outputDevice.length !== idx + 1}
            isRadioOn={item.selected}
            onClick={() => dispatch(selectOutputDevice(idx))}
          />
        ))}
      </WebServiceNormalBody>
    </WebServiceWrapper>
  );
};

export default WebServiceMoreVoiceDevice;
