/* eslint-disable react/prop-types */
/* eslint-disable operator-linebreak */
import React from 'react';
import store from 'redux/store';
import 'stylesheets/web_service/call.scss';

const WebServiceCallReceiveCallingButton = (props: any) => {
  const { img, name, onClick } = props;

  return (
    <div className="web-service-calling-button" onClick={onClick}>
      <div
        className={
          'web-service-calling-button-img-wrap' +
          (img === 'no_call' ? ' no-call' : '') +
          (img === 'mic_mute' || img === 'volume' ? ' active' : '')
        }
      >
        {img === 'no_call' ? (
          <img
            src="/icon/common/no_call.png"
            className="web-service-calling-button-img"
            alt={img}
          />
        ) : img === 'mic_mute' || img === 'volume' ? (
          <img
            src={`/icon/common/${img}.png`}
            className="web-service-calling-button-img"
            alt={img}
          />
        ) : (
          <img
            src={
              '/icon/' +
              (store.getState().theme.theme === 'light'
                ? 'lightmode/'
                : 'darkmode/') +
              img +
              '-outline-p1.png'
            }
            className="web-service-calling-button-img"
            alt={img}
          />
        )}
      </div>
      <div className="web-service-calling-button-text">{name}</div>
    </div>
  );
};

export default WebServiceCallReceiveCallingButton;
