import React from 'react';

const WebServiceProfileImg = (props: any) => {
  const { size, profile, state } = props;
  // state: online:온라인, offline:오프라인, idle:자리비움, DNdisturb(Do not disturb):다른용무중
  return (
    <div className={'web-service-profile-wrap' + ' ' + size + ' ' + state}>
      <div className="web-service-profile-wrap-relative">
        <img
          src={'/img/profile/' + profile}
          className="web-service-profile-img"
          alt="profile image"
        />
        <div className={'web-service-profile-state' + ' ' + state}>
          {state === 'calling' ? (
            <img src="/icon/common/answer_call.png" />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default WebServiceProfileImg;
