/* eslint-disable operator-linebreak */
import React from 'react';
import store from 'redux/store';
import { bottomNavSlice } from 'redux/bottom_nav_reducer';

import 'stylesheets/web_service/nav.scss';

const WebServiceBottomNav = () => {
  return (
    <div className="web-service-nav-container bottom-nav">
      <div
        className={
          'web-service-nav-btn-set' +
          (store.getState().selectedNavMenu.selectedNavMenu === 'dial'
            ? ' active'
            : '')
        }
        id="web-service-nav-dial"
        onClick={() => {
          store.dispatch(bottomNavSlice.actions.selectDial(''));
        }}
      >
        <div className="web-service-nav-btn-icon-wrap">
          <div className="web-service-nav-btn-icon"></div>
          <div className="web-service-nav-btn-icon active"></div>
        </div>
        <div className="web-service-nav-btn-text-wrap">전화걸기</div>
      </div>
      <div
        className={
          'web-service-nav-btn-set' +
          (store.getState().selectedNavMenu.selectedNavMenu === 'callLog'
            ? ' active'
            : '')
        }
        id="web-service-nav-call-log"
        onClick={() => {
          store.dispatch(bottomNavSlice.actions.selectCallLog(''));
        }}
      >
        <div className="web-service-nav-btn-icon-wrap">
          <div className="web-service-nav-btn-icon"></div>
          <div className="web-service-nav-btn-icon active"></div>
        </div>
        <div className="web-service-nav-btn-text-wrap">최근 통화</div>
      </div>
      <div className="web-service-nav-btn-set" id="web-service-nav-sms">
        <div className="web-service-nav-btn-icon-wrap">
          <div className="web-service-nav-btn-icon"></div>
          <div className="web-service-nav-btn-icon active"></div>
        </div>
        <div className="web-service-nav-btn-text-wrap">문자</div>
      </div>
      <div className="web-service-nav-btn-set" id="web-service-nav-messenger">
        <div className="web-service-nav-btn-icon-wrap">
          <div className="web-service-nav-btn-icon"></div>
          <div className="web-service-nav-btn-icon active"></div>
        </div>
        <div className="web-service-nav-btn-text-wrap">메신저</div>
      </div>
      <div className="web-service-nav-btn-set" id="web-service-nav-contact">
        <div className="web-service-nav-btn-icon-wrap">
          <div className="web-service-nav-btn-icon"></div>
          <div className="web-service-nav-btn-icon active"></div>
        </div>
        <div className="web-service-nav-btn-text-wrap">연락처</div>
      </div>
      <div className="web-service-nav-btn-set" id="web-service-nav-more">
        <div className="web-service-nav-btn-icon-wrap">
          <div className="web-service-nav-btn-icon"></div>
          <div className="web-service-nav-btn-icon active"></div>
        </div>
        <div className="web-service-nav-btn-text-wrap">더보기</div>
      </div>
    </div>
  );
};

export default WebServiceBottomNav;
