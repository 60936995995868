/* eslint-disable operator-linebreak */
import { createSlice } from '@reduxjs/toolkit';

type recordSettingState = {
  useRecord: boolean;
};

const initialState: recordSettingState = {
  useRecord: false
};

export const recordSettingSlice = createSlice({
  name: 'recordSetting',
  initialState,
  reducers: {
    switchUseRecord: (state) => {
      state.useRecord = !state.useRecord;
    }
  }
});

export const { switchUseRecord } = recordSettingSlice.actions;

export default recordSettingSlice.reducer;
