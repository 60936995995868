/* eslint-disable indent */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import routes from 'configs/routes';

import WebServiceWrapper from 'components/web_service_wrapper';
import WebServiceNormalBody from 'components/web_service/body/normal_body';
import WebServiceCenterTitleHeader from 'components/web_service/header/center_title';
// import WebServiceChatBubbleGroup from 'components/web_service/chat/bubble_group';
import { ChatBubbleItems1 } from 'configs/items';
import WebServiceSelectHeader from 'components/web_service/header/select';
import WebServiceChatBubble from 'components/web_service/chat/bubble';
import WebServiceTopSheetMenuContainer from 'components/web_service/fixed_component/top_sheet_menu_container';
import WebServiceAlarmModal from 'components/web_service/modal/alarm_modal';
import WebServiceChatInput from 'components/web_service/fixed_component/chat_input';
import WebServiceBottomFixedPrimaryButton from 'components/web_service/fixed_component/primary_button';

const WebServiceSMSChat = () => {
  const [isDeleteMode, setIsDeleteMode] = useState(false);
  const [openTopSheetMenu, setOpenTopSheetMenu] = useState(false);
  const [useAlarm, setUseAlarm] = useState(false);
  const [alarmShow, setAlarmShow] = useState(false);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [isChecked, setIsChecked] = useState<number[]>([]);
  const [timerId, setTimerId] = useState<ReturnType<typeof setTimeout>>();
  const [isPopover, setIsPopover] = useState<number>();

  const changeDeleteMode = () => {
    setIsDeleteMode(true);
    setOpenTopSheetMenu(false);
  };

  const items = [
    {
      type: 'normal',
      name: '문자메시지 삭제',
      onClick: () => changeDeleteMode()
    },
    {
      type: 'switch',
      name: useAlarm ? '알람 켜짐' : '알람 꺼짐',
      onClick: () => alarmHandleChagne()
    }
  ];

  const checkboxHandleChange = (idx: number) => {
    if (isDeleteMode) {
      if (isChecked.includes(idx)) {
        const activeList = isChecked.filter((i) => idx !== i);
        setIsChecked(activeList);
      } else {
        setIsChecked([...isChecked, idx]);
      }
    }
  };

  const checkboxAllHandleChange = () => {
    if (!isCheckedAll) {
      const activeList = ChatBubbleItems1.map((v, i) => i);
      setIsChecked(activeList);
    } else {
      setIsChecked([]);
    }
    setIsCheckedAll(!isCheckedAll);
  };

  useEffect(() => {
    isChecked.length === ChatBubbleItems1.length
      ? setIsCheckedAll(true)
      : setIsCheckedAll(false);
  }, [isChecked]);

  const alarmHandleChagne = () => {
    if (timerId) {
      clearTimeout(timerId);
    }
    setUseAlarm(!useAlarm);
    setAlarmShow(true);

    const timeoutId = setTimeout(() => {
      setAlarmShow(false);
    }, 2000);
    console.log(timeoutId);

    setTimerId(timeoutId);
  };

  return (
    <WebServiceWrapper
      hideNav={false}
      isCalling={false}
      scrollLock={openTopSheetMenu}
    >
      {isDeleteMode ? (
        <WebServiceSelectHeader
          isChecked={isCheckedAll}
          onClick={checkboxAllHandleChange}
          onCancel={() => setIsDeleteMode(false)}
        />
      ) : (
        <WebServiceCenterTitleHeader
          title="홍길동"
          rightIcon="more-outline-t1"
          rightOnClick={() => setOpenTopSheetMenu(true)}
          onBackClick={routes.web_service_sms}
        />
      )}
      <WebServiceNormalBody
        type="chat"
        useOverlay={openTopSheetMenu}
        background="bg4"
      >
        {ChatBubbleItems1.map((item, idx) => (
          <>
            {ChatBubbleItems1[idx - 1] &&
            item.date === ChatBubbleItems1[idx - 1].date ? null : (
              <div className="web-service-chat-date-wrap">
                <div className="web-service-chat-date">{item.date}</div>
              </div>
            )}
            <WebServiceChatBubble
              key={idx}
              userId={item.userId}
              type={item.type}
              profileImg={item.profileImg}
              name={item.name}
              text={item.text}
              time={item.time}
              fileName={item.fileName}
              fileSize={item.fileSize}
              fileFormat={item.fileFormat}
              fileDownloadPeriod={item.fileDownloadPeriod}
              fileDownloaded={item.fileDownloaded}
              image={item.image}
              url={item.url}
              isDeleteMode={isDeleteMode}
              isFirstBubble={
                ChatBubbleItems1[idx - 1] &&
                item.name === ChatBubbleItems1[idx - 1].name &&
                item.time === ChatBubbleItems1[idx - 1].time
                  ? false
                  : true
              }
              isLastBubble={
                ChatBubbleItems1[idx + 1] &&
                item.name === ChatBubbleItems1[idx + 1].name &&
                item.time === ChatBubbleItems1[idx + 1].time
                  ? false
                  : true
              }
              isChecked={isChecked.includes(idx)}
              onCheckboxClick={() => checkboxHandleChange(idx)}
              isPopover={isPopover === idx ? true : false}
              setIsPopover={() => setIsPopover(idx)}
              unsetIsPopover={() => setIsPopover(undefined)}
            />
          </>
        ))}
        {isDeleteMode ? null : <WebServiceChatInput />}
      </WebServiceNormalBody>
      <WebServiceTopSheetMenuContainer
        openTopSheetMenu={openTopSheetMenu}
        setOpenTopSheetMenu={setOpenTopSheetMenu}
        items={items}
        useAlarm={useAlarm}
      />
      {isDeleteMode && isChecked.length > 0 ? (
        <WebServiceBottomFixedPrimaryButton
          isDisabled={false}
          buttonName="선택한 항목 삭제"
        />
      ) : null}
      <WebServiceAlarmModal useAlarm={useAlarm} alarmShow={alarmShow} />
    </WebServiceWrapper>
  );
};

export default WebServiceSMSChat;
