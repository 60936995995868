/* eslint-disable operator-linebreak */
import React from 'react';
import store from 'redux/store';

import 'stylesheets/web_service/nav.scss';

const WebServiceCallLogHeader = (props: any) => {
  const { items, selectedFilter, onDropdownClick, onButtonClick } = props;

  return (
    <div className="web-service-callLog-header-container">
      <div
        className="web-service-callLog-header-filter"
        onClick={onDropdownClick}
      >
        {items[selectedFilter].name}
        <img
          src={
            '/icon/' +
            (store.getState().theme.theme === 'light'
              ? 'lightmode/'
              : 'darkmode/') +
            'arrow_down-t1.png'
          }
          className="web-service-callLog-header-arrow-bottom"
        />
      </div>
      <div
        className="web-service-icon-button-clickarea"
        onClick={onButtonClick}
      >
        <img
          src={
            '/icon/' +
            (store.getState().theme.theme === 'light'
              ? 'lightmode/'
              : 'darkmode/') +
            'search-outline-t1.png'
          }
          className="web-service-header-icon-button-img"
        />
      </div>
    </div>
  );
};

export default WebServiceCallLogHeader;
