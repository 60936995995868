/* eslint-disable react/prop-types */
/* eslint-disable operator-linebreak */
import React from 'react';
import WebServiceCloseCircleButton from '../button/close_circle_button';

const WebServiceBottomSheetRecord = (props: any) => {
  const { setOpenRecord, openRecord } = props;

  return (
    <div className={'web-service-sheet-area' + (openRecord ? ' active' : '')}>
      <div
        className={'web-service-overlay' + (openRecord ? ' active' : '')}
        onClick={() => {
          setOpenRecord(false);
        }}
      />
      <div
        className={
          'web-service-bottom-sheet-record-container' +
          (openRecord ? ' active' : '')
        }
      >
        <div className="web-service-bottom-sheet-record-close-area">
          <WebServiceCloseCircleButton
            circleWidth="32px"
            clickAreaWidth="44px"
            onClick={() => setOpenRecord(false)}
          />
        </div>
        <div className="web-service-bottom-sheet-record-body-area">
          <div className="web-service-bottom-sheet-record-player-set">
            <div className="web-service-bottom-sheet-record-player-bar">
              <div className="web-service-bottom-sheet-record-player-current" />
              <div className="web-service-bottom-sheet-record-player-handle" />
            </div>
            <div className="web-service-bottom-sheet-record-playtime">
              12:03 / 15:55
            </div>
          </div>
          <div className="web-service-bottom-sheet-record-play-button">
            <img src="/icon/common/play.png" alt="play" />
          </div>
          <div className="web-service-bottom-sheet-record-see-text">
            텍스트 보기
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebServiceBottomSheetRecord;
