/* eslint-disable indent */
/* eslint-disable react/prop-types */
/* eslint-disable operator-linebreak */
import React from 'react';
import 'stylesheets/web_service/chat.scss';

const WebServiceChatBubbleURL = (props: any) => {
  const { userId, url } = props;

  return (
    <div
      className={
        'web-service-chat-bubble-url-group' +
        (userId === 'myId' ? ' myBubble' : '')
      }
      onClick={() => window.open('https://www.youtube.com', '_blank')}
    >
      <div
        className={
          'web-service-chat-bubble-url' + (userId === 'myId' ? ' myBubble' : '')
        }
      >
        {url}
      </div>
      <div
        className={
          'web-service-chat-bubble-url-thumbnail' +
          (userId === 'myId' ? ' myBubble' : '')
        }
      >
        <div className="web-service-chat-bubble-url-thumbnail-image-wrap">
          <img
            src="/img/photo/youtube_thumbnail.png"
            className="web-service-chat-bubble-url-thumbnail-image"
          />
        </div>
        <div
          className={
            'web-service-chat-bubble-url-thumbnail-text-wrap' +
            (userId === 'myId' ? ' myBubble' : '')
          }
        >
          <div className="web-service-chat-bubble-url-thumbnail-name">
            Youtube
          </div>
          <div className="web-service-chat-bubble-url-thumbnail-url">
            www.youtube.com
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebServiceChatBubbleURL;
