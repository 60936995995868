/* eslint-disable indent */
/* eslint-disable react/prop-types */
/* eslint-disable operator-linebreak */
import React from 'react';
import store from 'redux/store';
import 'stylesheets/web_service/more.scss';

const WebServiceMoreCard = (props: any) => {
  const {
    type,
    name,
    rightText,
    isAlarm,
    rightTextColor,
    bottomBorder,
    alarmAmount,
    isRadioOn,
    isChecked,
    isSwitchOn,
    isPlayerOn,
    innerText,
    outerText,
    onClick,
    canHover
  } = props;

  if (type === 'arrowOnly') {
    return (
      <WebServiceMoreCardArrowOnly
        name={name}
        bottomBorder={bottomBorder}
        alarmAmount={alarmAmount}
        innerText={innerText}
        outerText={outerText}
        onClick={onClick}
      />
    );
  } else if (type === 'arrowWithText') {
    return (
      <WebServiceMoreCardArrowWithText
        name={name}
        bottomBorder={bottomBorder}
        rightText={rightText}
        rightTextColor={rightTextColor}
        isAlarm={isAlarm}
        innerText={innerText}
        outerText={outerText}
        onClick={onClick}
      />
    );
  } else if (type === 'radioOnly') {
    return (
      <WebServiceMoreCardRadioOnly
        name={name}
        bottomBorder={bottomBorder}
        isRadioOn={isRadioOn}
        innerText={innerText}
        outerText={outerText}
        onClick={onClick}
      />
    );
  } else if (type === 'checkboxOnly') {
    return (
      <WebServiceMoreCardCheckboxOnly
        name={name}
        bottomBorder={bottomBorder}
        isChecked={isChecked}
        innerText={innerText}
        outerText={outerText}
        onClick={onClick}
      />
    );
  } else if (type === 'switch') {
    return (
      <WebServiceMoreCardSwitch
        name={name}
        bottomBorder={bottomBorder}
        isSwitchOn={isSwitchOn}
        innerText={innerText}
        outerText={outerText}
        onClick={onClick}
      />
    );
  } else if (type === 'player') {
    return (
      <WebServiceMoreCardPlayer
        name={name}
        bottomBorder={bottomBorder}
        isPlayerOn={isPlayerOn}
        innerText={innerText}
        outerText={outerText}
        onClick={onClick}
      />
    );
  } else if (type === 'textOnly') {
    return (
      <WebServiceMoreCardTextOnly
        name={name}
        bottomBorder={bottomBorder}
        rightText={rightText}
        rightTextColor={rightTextColor}
        innerText={innerText}
        outerText={outerText}
        onClick={onClick}
        canHover={canHover}
      />
    );
  } else {
    return <div>error</div>;
  }
};

const WebServiceMoreCardArrowOnly = (props: any) => {
  const { name, bottomBorder, alarmAmount, innerText, outerText, onClick } =
    props;
  return (
    <div className="web-service-more-card-wrap" onClick={onClick}>
      <div className="web-service-more-card">
        <div
          className={
            'web-service-more-card-inner' + (bottomBorder ? ' border' : '')
          }
        >
          <div className="web-service-more-card-body">
            <div className={'web-service-more-card-left'}>
              <div className="web-service-more-card-name">{name}</div>
            </div>
            <div className="web-service-more-card-right-wrap">
              {alarmAmount > 0 ? (
                <div className="web-service-more-card-right-alarm-amount">
                  {alarmAmount}
                </div>
              ) : null}
              <img
                src={
                  '/icon/' +
                  (store.getState().theme.theme === 'light'
                    ? 'lightmode/'
                    : 'darkmode/') +
                  'arrow_right-t3.png'
                }
                className="web-service-more-card-right-arrow"
                alt="arrow right"
              />
            </div>
          </div>
          {innerText ? (
            <div className="web-service-more-card-inner-text">
              <div className="web-service-more-card-inner-text-ellipsis">
                {innerText}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {outerText ? (
        <div className="web-service-more-card-outer-text">{outerText}</div>
      ) : null}
    </div>
  );
};

const WebServiceMoreCardArrowWithText = (props: any) => {
  const {
    name,
    bottomBorder,
    rightText,
    rightTextColor,
    isAlarm,
    innerText,
    outerText,
    onClick
  } = props;
  return (
    <div className="web-service-more-card-wrap" onClick={onClick}>
      <div className="web-service-more-card">
        <div
          className={
            'web-service-more-card-inner' + (bottomBorder ? ' border' : '')
          }
        >
          <div className="web-service-more-card-body">
            <div className={'web-service-more-card-left'}>
              <div className="web-service-more-card-name">{name}</div>
            </div>
            <div className="web-service-more-card-right-wrap">
              <div
                className={
                  'web-service-more-card-right-text' +
                  (rightTextColor === 'inactive' ? ' inactive' : '')
                }
              >
                {rightText}
                {isAlarm ? (
                  <div className={'web-service-more-card-right-text-alarm'} />
                ) : null}
              </div>
              <img
                src={
                  '/icon/' +
                  (store.getState().theme.theme === 'light'
                    ? 'lightmode/'
                    : 'darkmode/') +
                  'arrow_right-t3.png'
                }
                className="web-service-more-card-right-arrow"
                alt="arrow right"
              />
            </div>
          </div>
          {innerText ? (
            <div className="web-service-more-card-inner-text">
              <div className="web-service-more-card-inner-text-ellipsis">
                {innerText}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {outerText ? (
        <div className="web-service-more-card-outer-text">{outerText}</div>
      ) : null}
    </div>
  );
};

const WebServiceMoreCardRadioOnly = (props: any) => {
  const { name, bottomBorder, isRadioOn, innerText, outerText, onClick } =
    props;
  return (
    <div className="web-service-more-card-wrap" onClick={onClick}>
      <div className="web-service-more-card">
        <div
          className={
            'web-service-more-card-inner' + (bottomBorder ? ' border' : '')
          }
        >
          <div className="web-service-more-card-body">
            <div className={'web-service-more-card-left'}>
              <div
                className={'web-service-radio' + (isRadioOn ? ' active' : '')}
              >
                {isRadioOn ? <div className="web-service-radio-inner" /> : null}
              </div>

              <div className="web-service-more-card-name">{name}</div>
            </div>
          </div>
          {innerText ? (
            <div className="web-service-more-card-inner-text">
              <div className="web-service-more-card-inner-text-ellipsis">
                {innerText}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {outerText ? (
        <div className="web-service-more-card-outer-text">{outerText}</div>
      ) : null}
    </div>
  );
};

const WebServiceMoreCardCheckboxOnly = (props: any) => {
  const { name, bottomBorder, isChecked, innerText, outerText, onClick } =
    props;
  return (
    <div className="web-service-more-card-wrap" onClick={onClick}>
      <div className="web-service-more-card">
        <div
          className={
            'web-service-more-card-inner' + (bottomBorder ? ' border' : '')
          }
        >
          <div className="web-service-more-card-body">
            <div className={'web-service-more-card-left'}>
              <div className={`web-service-checkbox-img-wrap ${isChecked}`}>
                <img
                  src={
                    '/icon/' +
                    (store.getState().theme.theme === 'light'
                      ? 'lightmode/'
                      : 'darkmode/') +
                    'checkbox-false.png'
                  }
                  className={'web-service-checkbox-false' + ' hover-effect'}
                  alt="checkbox button"
                />
                <img
                  src={
                    '/icon/' +
                    (store.getState().theme.theme === 'light'
                      ? 'lightmode/'
                      : 'darkmode/') +
                    'checkbox-true.png'
                  }
                  className={'web-service-checkbox-true' + ' hover-effect'}
                  alt="checkbox button"
                />
              </div>
              <div className="web-service-more-card-name">{name}</div>
            </div>
          </div>
          {innerText ? (
            <div className="web-service-more-card-inner-text">
              <div className="web-service-more-card-inner-text-ellipsis">
                {innerText}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {outerText ? (
        <div className="web-service-more-card-outer-text">{outerText}</div>
      ) : null}
    </div>
  );
};

const WebServiceMoreCardSwitch = (props: any) => {
  const { name, bottomBorder, isSwitchOn, innerText, outerText, onClick } =
    props;
  return (
    <div className="web-service-more-card-wrap" onClick={onClick}>
      <div className="web-service-more-card">
        <div
          className={
            'web-service-more-card-inner' + (bottomBorder ? ' border' : '')
          }
        >
          <div className="web-service-more-card-body">
            <div className={'web-service-more-card-left'}>
              <div className="web-service-more-card-name">{name}</div>
            </div>
            <div className="web-service-more-card-right-wrap">
              <div
                className={
                  'web-service-switch-button' + (isSwitchOn ? ' active' : '')
                }
              >
                <div
                  className={
                    'web-service-switch-wheel' + (isSwitchOn ? ' active' : '')
                  }
                />
              </div>
            </div>
          </div>
          {innerText ? (
            <div className="web-service-more-card-inner-text">
              <div className="web-service-more-card-inner-text-ellipsis">
                {innerText}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {outerText ? (
        <div className="web-service-more-card-outer-text">{outerText}</div>
      ) : null}
    </div>
  );
};

const WebServiceMoreCardPlayer = (props: any) => {
  const { name, bottomBorder, isPlayerOn, innerText, outerText, onClick } =
    props;
  return (
    <div className="web-service-more-card-wrap" onClick={onClick}>
      <div className="web-service-more-card">
        <div
          className={
            'web-service-more-card-inner' + (bottomBorder ? ' border' : '')
          }
        >
          <div className="web-service-more-card-body">
            <div className={'web-service-more-card-left'}>
              <div className="web-service-more-card-name">{name}</div>
            </div>
            <div className="web-service-more-card-right-wrap">
              <div className="web-service-more-card-player">
                {isPlayerOn ? (
                  <img
                    src={
                      '/icon/' +
                      (store.getState().theme.theme === 'light'
                        ? 'lightmode/'
                        : 'darkmode/') +
                      'pause-fill-t2.png'
                    }
                    className="web-service-more-card-player-img"
                    alt="stop"
                  />
                ) : (
                  <img
                    src={
                      '/icon/' +
                      (store.getState().theme.theme === 'light'
                        ? 'lightmode/'
                        : 'darkmode/') +
                      'play-fill-t2.png'
                    }
                    className="web-service-more-card-player-img"
                    alt="play"
                  />
                )}
              </div>
            </div>
          </div>
          {innerText ? (
            <div className="web-service-more-card-inner-text">
              <div className="web-service-more-card-inner-text-ellipsis">
                {innerText}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {outerText ? (
        <div className="web-service-more-card-outer-text">{outerText}</div>
      ) : null}
    </div>
  );
};

const WebServiceMoreCardTextOnly = (props: any) => {
  const {
    name,
    bottomBorder,
    innerText,
    outerText,
    onClick,
    rightTextColor,
    rightText,
    canHover
  } = props;
  return (
    <div className={'web-service-more-card-wrap'} onClick={onClick}>
      <div
        className={'web-service-more-card' + (canHover ? '' : ' hoverDisable')}
      >
        <div
          className={
            'web-service-more-card-inner' + (bottomBorder ? ' border' : '')
          }
        >
          <div className="web-service-more-card-body">
            <div className={'web-service-more-card-left'}>
              <div className="web-service-more-card-name">{name}</div>
            </div>
            <div className="web-service-more-card-right-wrap">
              <div
                className={
                  'web-service-more-card-right-text' +
                  (rightTextColor === 'inactive'
                    ? ' inactive'
                    : rightTextColor === 'text1'
                    ? ' text1'
                    : '')
                }
              >
                {rightText}
              </div>
            </div>
          </div>
          {innerText ? (
            <div className="web-service-more-card-inner-text">
              <div className="web-service-more-card-inner-text-ellipsis">
                {innerText}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {outerText ? (
        <div className="web-service-more-card-outer-text">{outerText}</div>
      ) : null}
    </div>
  );
};

export default WebServiceMoreCard;
