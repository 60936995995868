/* eslint-disable indent */
import React, { useState } from 'react';

import WebServiceWrapper from 'components/web_service_wrapper';
import WebServiceNormalBody from 'components/web_service/body/normal_body';
import WebServiceCenterTitleHeader from 'components/web_service/header/center_title';
import WebServiceMoreCard from 'components/more/card';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectRingingTone,
  selectRingingToneVoiceType,
  switchUseRingingTone
} from 'redux/more/ringing_tone_setting';
import WebServiceSubTitle from 'components/web_service/content_title/sub_title';
import WebServiceBackTextHeader from 'components/web_service/header/back_text';
import WebServiceMoreTextarea from 'components/more/textarea';

const WebServiceMoreRingingTone = () => {
  const [useTextInput, setUseTextInput] = useState(false);
  if (useTextInput) {
    return (
      <WebServiceMoreRingingToneType
        useTextInput={useTextInput}
        setUseTextInput={setUseTextInput}
      />
    );
  } else {
    return (
      <WebServiceMoreRingingToneSetting
        useTextInput={useTextInput}
        setUseTextInput={setUseTextInput}
      />
    );
  }
};

const WebServiceMoreRingingToneType = (props: any) => {
  const { useTextInput, setUseTextInput } = props;
  return (
    <WebServiceWrapper hideNav={false} isCalling={false} scrollLock={false}>
      <WebServiceBackTextHeader
        title="연결음 안내문구"
        rightButtonText="완료"
        rightOnClick={() => setUseTextInput(false)}
        backState={useTextInput}
        setBackState={setUseTextInput}
      />
      <WebServiceNormalBody type="normal" useOverlay={false} background="">
        <WebServiceMoreTextarea />
      </WebServiceNormalBody>
    </WebServiceWrapper>
  );
};

const WebServiceMoreRingingToneSetting = (props: any) => {
  const { setUseTextInput } = props;
  const dispatch = useDispatch();
  const { ringingToneSetting }: any = useSelector((state) => state);
  const [isPlayerOn, setIsPlayerOn] = useState(false);
  return (
    <WebServiceWrapper hideNav={false} isCalling={false} scrollLock={false}>
      <WebServiceCenterTitleHeader title="통화 연결음 설정" />
      <WebServiceNormalBody type="more-78" useOverlay={false} background="">
        <WebServiceMoreCard
          type="switch"
          name="통화 연결음 사용"
          bottomBorder={false}
          outerText="상대방에게 들려줄 통화 연결음을 설정합니다."
          isSwitchOn={ringingToneSetting.useRingingTone}
          onClick={() => dispatch(switchUseRingingTone())}
        />
        <div
          className={
            'web-service-more-hide' +
            (ringingToneSetting.useRingingTone ? ' active mh800' : '')
          }
        >
          <WebServiceSubTitle title="배경음악" />
          {ringingToneSetting.ringingTone.map((item: any, idx: number) => (
            <WebServiceMoreCard
              key={idx}
              type={item.type}
              name={item.name}
              bottomBorder={ringingToneSetting.ringingTone.length !== idx + 1}
              isRadioOn={item.selected}
              onClick={() => dispatch(selectRingingTone(idx))}
            />
          ))}
          <WebServiceSubTitle title="안내 목소리" />
          {ringingToneSetting.ringingToneVoiceType.map(
            (item: any, idx: number) => (
              <WebServiceMoreCard
                key={idx}
                type={item.type}
                name={item.name}
                bottomBorder={
                  ringingToneSetting.ringingToneVoiceType.length !== idx + 1
                }
                isRadioOn={item.selected}
                onClick={() => dispatch(selectRingingToneVoiceType(idx))}
              />
            )
          )}
          <WebServiceSubTitle title="안내 문구 설정" />
          <WebServiceMoreCard
            type="arrowOnly"
            name="안내 문구"
            bottomBorder={false}
            innerText="안녕하세요.
믿음과 신뢰를 바탕으로 고객만족에 최선을 다하는 000 입니다.
전화주셔서 감사합니다. 오늘도 좋은하..."
            outerText="통화 연결 시 안내 문구가 음성으로 출력됩니다."
            onClick={() => setUseTextInput(true)}
          />
          <WebServiceSubTitle title="통화연결음 미리 듣기" />
          <WebServiceMoreCard
            type="player"
            name="통화연결음 미리 듣기"
            bottomBorder={false}
            isPlayerOn={isPlayerOn}
            onClick={() => setIsPlayerOn(!isPlayerOn)}
          />
          <div
            className={
              'web-service-more-hide' + ''
              // (setting.selectedAvailableVoice[2] ? ' active mh200' : '')
            }
          >
            <WebServiceSubTitle title="출력할 음성 설정" />
            <WebServiceMoreCard
              type="arrowOnly"
              name="출력할 음성"
              bottomBorder={false}
              innerText="안녕하세요.
믿음과 신뢰를 바탕으로 고객만족에 최선을 다하는 000 입니다.
전화주셔서 감사합니다. 오늘도 좋은하루되시길 기원하면서 오늘의 노래들려드립니다."
              onClick={() => setUseTextInput(true)}
            />
          </div>
        </div>
      </WebServiceNormalBody>
    </WebServiceWrapper>
  );
};

export default WebServiceMoreRingingTone;
