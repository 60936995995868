/* eslint-disable operator-linebreak */
import { createSlice } from '@reduxjs/toolkit';
import { Language } from 'configs/enum';

type langState = {
  lang: string;
};

const initialState: langState = {
  lang: Language.kr
};

export const langSlice = createSlice({
  name: 'lang',
  initialState,
  reducers: {
    useKorean: (state) => {
      state.lang = Language.kr;
    },
    useEnglish: (state) => {
      state.lang = Language.en;
    },
    useJapanese: (state) => {
      state.lang = Language.jp;
    }
  }
});

export const { useKorean, useEnglish, useJapanese } = langSlice.actions;

export default langSlice.reducer;
