import React from 'react';

import MainNav from 'components/main_nav';

import 'stylesheets/main_page_wrapper.scss';

type Props = {
  children: JSX.Element;
};

const MainPageWrapper = ({ children }: Props) => {
  return (
    <div className="main-container">
      <MainNav />
      <div className="main-contents-container">{children}</div>
    </div>
  );
};

export default MainPageWrapper;
