/* eslint-disable operator-linebreak */
import React from 'react';
import store from 'redux/store';

import 'stylesheets/web_service/nav.scss';

const WebServiceSearchHeader = (props: any) => {
  const { onCancel, searchValue, onSearchValueChange, onSearchValueRemove } =
    props;

  return (
    <div className="web-service-search-header-container">
      <input
        className="web-service-search-header-input"
        type="text"
        placeholder="검색어를 입력해 주세요."
        value={searchValue}
        onChange={onSearchValueChange}
      />
      {searchValue ? (
        <div
          className="web-service-icon-button-clickarea"
          onClick={onSearchValueRemove}
        >
          <img
            src={
              '/icon/' +
              (store.getState().theme.theme === 'light'
                ? 'lightmode/'
                : 'darkmode/') +
              'close_circle-t4.png'
            }
            className="web-service-search-header-delete"
            alt="delete input text"
          />
        </div>
      ) : null}

      <div className="web-service-icon-button-clickarea" onClick={onCancel}>
        취소
      </div>
    </div>
  );
};

export default WebServiceSearchHeader;
