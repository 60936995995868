/* eslint-disable object-curly-spacing */
/* eslint-disable indent */
/* eslint-disable operator-linebreak */
import React from 'react';
import { Link } from 'react-router-dom';
import store from 'redux/store';
import 'stylesheets/web_service/search.scss';

const WebServiceSearchHistoryCard = (props: any) => {
  const { keyword, date, link, onDeleteClick, idx } = props;
  return (
    <div className="web-service-search-history-card">
      <Link to={link} className="web-service-search-history-keyword-link">
        <div className="web-service-search-history-keyword">{keyword}</div>
      </Link>
      <div className="web-service-search-history-date">{date}</div>
      <div
        className="web-service-search-history-delete-wrap"
        onClick={() => onDeleteClick(idx)}
      >
        <img
          src={`
            /icon/${
              store.getState().theme.theme === 'light'
                ? 'lightmode/'
                : 'darkmode/'
            }close-t1.png
          `}
          className="web-service-search-history-delete"
          alt="No Result"
        />
      </div>
    </div>
  );
};

export default WebServiceSearchHistoryCard;
