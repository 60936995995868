/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import WebServiceNormalBody from 'components/web_service/body/normal_body';
import WebServiceWrapper from 'components/web_service_wrapper';
import WebServiceSecondaryButton from 'components/web_service/button/secondary_button';
import WebServiceCallReceiveMemo from 'components/web_service/call/call_receive_memo';
// eslint-disable-next-line max-len
import WebServiceCallReceiveCallingButton from 'components/web_service/call/call_reveive_calling_button';
import 'stylesheets/web_service/call.scss';
import WebServiceDialPad from 'components/web_service/dial/dial_pad';
import { Link } from 'react-router-dom';

const WebServiceCall = () => {
  const [mic, setMic] = useState(true);
  const [speaker, setSpeaker] = useState(false);
  const [dial, setDial] = useState(false);

  const [callState, setCallState] = useState('receive');
  // wait, receive, dispatch
  const [time, setTime] = useState(12344);
  const [second, setSecond] = useState(0);
  const [minute, setMinute] = useState(0);
  const [hour, setHour] = useState(0);

  console.log(() => setTime);
  console.log(() => setCallState);

  useEffect(() => {
    setHour(Math.floor(Math.floor(time / 60) / 60));
    setMinute(Math.floor(time / 60) % 60);
    setSecond(time % 60);
  }, [second]);

  return (
    <WebServiceWrapper hideNav={true} isCalling={true} scrollLock={false}>
      <WebServiceNormalBody type="call" useOverlay={false} background="">
        <div className="web-service-receive-call-head">
          <div className="web-service-receive-call-title">
            {callState === 'wait'
              ? '업무전화가 왔습니다.'
              : callState === 'receive'
              ? `${hour}:${minute}:${second}`
              : callState === 'dispatch'
              ? '발신 중'
              : null}
          </div>
          <img
            src="/img/profile/profile.jpg"
            className="web-service-receive-call-profile"
            alt="profile image"
          />
          <div className="web-service-receive-call-info-wrap">
            <div className="web-service-receive-call-number">010-1234-1234</div>
            <div className="web-service-receive-call-name">홍길동</div>
            <div className="web-service-receive-call-subtext-wrap">
              <div className="web-service-receive-call-position">대리</div>
              <div className="web-service-receive-vertical-line"></div>
              <div className="web-service-receive-call-team">경영지원팀</div>
            </div>
          </div>
          {callState === 'wait' ? (
            <div className="web-service-receive-call-btn-wrap">
              <button className="web-service-receive-call-nocall-btn">
                <img src="/icon/common/no_call.png" alt="answer button" />
              </button>
              <button className="web-service-receive-call-answer-btn">
                <img src="/icon/common/answer_call.png" alt="answer button" />
              </button>
            </div>
          ) : null}
          {callState === 'wait' ? (
            <div className="web-service-receive-call-bottom-sheet">
              <div className="web-service-bottom-sheet-bar" />
              <div className="web-service-bottom-sheet-memo-title">메모</div>
              <WebServiceCallReceiveMemo
                date="오전 10:35"
                text="PB 교육 관련 신규 이슈 논의 필요. 1시-2시에 다시 통화 하기로 함.
                통화 전 준비자료 미리 숙지."
              />
              <WebServiceCallReceiveMemo
                date="오전 10:35"
                text="PB 교육 관련 신규 이슈 논의 필요. 1시-2시에 다시 통화 하기로 함.
                통화 전 준비자료 미리 숙지."
              />
              <WebServiceCallReceiveMemo
                date="오전 10:35"
                text="PB 교육 관련 신규 이슈 논의 필요. 1시-2시에 다시 통화 하기로 함.
                통화 전 준비자료 미리 숙지."
              />
              <WebServiceSecondaryButton
                isDisabled={false}
                buttonName="전체 보기"
                size="normal"
                colorAdjust="up"
              />
            </div>
          ) : null}
          {callState === 'receive' || callState === 'dispatch' ? (
            <div className="web-service-calling-btnset">
              <WebServiceCallReceiveCallingButton img="sms" name="문자" />
              <WebServiceCallReceiveCallingButton img="write" name="메모" />
              <WebServiceCallReceiveCallingButton
                img={mic ? 'mic' : 'mic_mute'}
                name={mic ? '소리 켜짐' : '소리 꺼짐'}
                onClick={() => setMic(!mic)}
              />
              <WebServiceCallReceiveCallingButton
                img={speaker ? 'volume' : 'mute'}
                name={speaker ? '스피커 켜짐' : '스피커 꺼짐'}
                onClick={() => setSpeaker(!speaker)}
              />
              <WebServiceCallReceiveCallingButton
                img="dial"
                name="다이얼"
                onClick={() => setDial(true)}
              />
              <WebServiceCallReceiveCallingButton img="pause" name="보류" />
              <WebServiceCallReceiveCallingButton
                img="call_toss"
                name="통화 전달"
              />
              <Link to={'/app/web_service/dial'}>
                <WebServiceCallReceiveCallingButton
                  img="no_call"
                  name="통화 종료"
                />
              </Link>
            </div>
          ) : null}
        </div>
        <div
          className={
            'web-service-calling-dial-wrap' + (dial ? ' active' : ' hide')
          }
        >
          <WebServiceDialPad
            type="calling"
            onHideClick={() => setDial(false)}
          />
        </div>
      </WebServiceNormalBody>
    </WebServiceWrapper>
  );
};

export default WebServiceCall;
