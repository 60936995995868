/* eslint-disable object-curly-spacing */
/* eslint-disable indent */
/* eslint-disable operator-linebreak */
import React, { useState } from 'react';
import store from 'redux/store';
import WebServiceSearchResultCardContact from '../search/result_card_contact';
import WebServiceCloseCircleButton from '../button/close_circle_button';
import { ContactListItems } from 'configs/items';

import 'stylesheets/web_service/sms.scss';

const WebServiceSMSSelectReceiver = (props: any) => {
  const {} = props;
  const [inputValue, setInputValue] = useState('');

  const handleChange = (e: any) => {
    setInputValue(e.target.value);
  };

  const onReset = () => {
    setInputValue('');
  };

  return (
    <div className="web-service-sms-select-receiver">
      <div className="web-service-sms-select-receiver-search-input-wrap">
        <div className="web-service-sms-select-receiver-search-input-label">
          받는 사람
        </div>
        <input
          className="web-service-sms-select-receiver-search-input"
          type="text"
          value={inputValue}
          placeholder="이름 또는 전화번호를 입력하세요"
          onChange={handleChange}
        />
        {inputValue ? (
          <WebServiceCloseCircleButton
            circleWidth="24px"
            clickAreaWidth="44px"
            onClick={onReset}
          />
        ) : null}
      </div>
      <div
        className={
          'web-service-sms-select-unknown-receiver' +
          (inputValue && !isNaN(parseInt(inputValue)) ? ' active' : '')
        }
      >
        <div className="web-service-sms-select-unknown-receiver-wrap">
          <div className="web-service-sms-select-unknown-receiver-icon-wrap">
            <img
              src={
                '/icon/' +
                (store.getState().theme.theme === 'light'
                  ? 'lightmode/'
                  : 'darkmode/') +
                'sms-outline-t2.png'
              }
              className="web-service-sms-select-unknown-receiver-icon"
              alt="해당 번호로 문자 보내기"
            />
          </div>
          <div className="web-service-sms-select-unknown-receiver-text">
            {inputValue.substring(0, 2) === '02'
              ? inputValue.replace(/(\d{2})(\d{3,4})(\d{4})/, '$1-$2-$3')
              : inputValue.replace(/(\d{2,3})(\d{3,4})(\d{4})/, '$1-$2-$3')}
            로 문자보내기
          </div>
        </div>
      </div>
      {ContactListItems.map((item: any, idx: any) => (
        <WebServiceSearchResultCardContact
          equalName={item.equalName}
          name={item.name}
          equalNumber={item.equalNumber}
          number={item.number}
          padding={true}
          profile="profile.jpg"
          key={idx}
        />
      ))}
    </div>
  );
};

export default WebServiceSMSSelectReceiver;
