/* eslint-disable operator-linebreak */
import React from 'react';
import store from 'redux/store';

import 'stylesheets/web_service/nav.scss';

const WebServiceContactHeader = (props: any) => {
  const {
    title,
    onCreateButtonClick,
    onSearchButtonClick,
    onSettingButtonClick
  } = props;

  return (
    <div className="web-service-left-title-header-container">
      <div className="web-service-left-title-header-left">
        <div className="web-service-left-title-header-title">{title}</div>
      </div>
      <div className="web-service-left-title-header-right">
        <div
          className="web-service-icon-button-clickarea"
          onClick={onCreateButtonClick}
        >
          <img
            src={
              '/icon/' +
              (store.getState().theme.theme === 'light'
                ? 'lightmode/'
                : 'darkmode/') +
              'add_circle-outline-t1.png'
            }
            className="web-service-left-title-right-icon"
            alt=""
          />
        </div>
        <div
          className="web-service-icon-button-clickarea"
          onClick={onSearchButtonClick}
        >
          <img
            src={
              '/icon/' +
              (store.getState().theme.theme === 'light'
                ? 'lightmode/'
                : 'darkmode/') +
              'search-outline-t1.png'
            }
            className="web-service-left-title-right-icon"
            alt=""
          />
        </div>
        <div
          className="web-service-icon-button-clickarea"
          onClick={onSettingButtonClick}
        >
          <img
            src={
              '/icon/' +
              (store.getState().theme.theme === 'light'
                ? 'lightmode/'
                : 'darkmode/') +
              'setting-outline-t1.png'
            }
            className="web-service-left-title-right-icon"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default WebServiceContactHeader;
