/* eslint-disable max-len */
/* eslint-disable object-curly-spacing */
/* eslint-disable indent */
/* eslint-disable operator-linebreak */
import React, { useState } from 'react';
import store from 'redux/store';
import 'stylesheets/web_service/search.scss';
import WebServiceSearchResultCardCallHistoryButton from './result_card_call_history_button';

const WebServiceSearchResultCardCallHistory = (props: any) => {
  const { callType, equalName, name, time, onRecordClick, canSpread } = props;

  const [isOpened, setIsOpened] = useState(false);

  const toggleOpen = () => {
    setIsOpened(!isOpened);
  };

  return (
    <div
      className={
        'web-service-search-result-card-call-history-wrap' +
        (isOpened && canSpread ? ' active' : '')
      }
    >
      <div
        className="web-service-search-result-card-call-history"
        onClick={toggleOpen}
      >
        <img
          src={`/icon/${
            store.getState().theme.theme === 'light'
              ? 'lightmode/'
              : 'darkmode/'
          }call_${callType}.png`}
          className="web-service-search-result-card-call-history-icon"
          alt="profile image"
        />
        <div
          className={'web-service-search-result-card-call-history-info-wrap'}
        >
          <div className="web-service-search-result-card-call-history-name-wrap">
            <div className="web-service-search-result-card-call-history-equal-name">
              {equalName}
            </div>
            <div className="web-service-search-result-card-call-history-name">
              {name}
            </div>
          </div>
          <div className="web-service-search-result-card-call-history-log">
            {callType === 'receive' ? '수신전화' : null}
            {callType === 'sent' ? '발신전화' : null}
            {callType === 'absence' ? '부재중' : null}
            {callType === 'block' ? '통화거절' : null} 2분 3초
          </div>
        </div>
        <div className="web-service-search-result-card-call-history-time">
          {time}
        </div>
      </div>
      {canSpread ? (
        <div
          className={
            'web-service-search-result-card-call-history-active-area' +
            (isOpened ? ' active' : '')
          }
        >
          <WebServiceSearchResultCardCallHistoryButton
            imgName="call"
            text="통화"
          />
          <WebServiceSearchResultCardCallHistoryButton
            imgName="sms"
            text="문자"
          />
          <WebServiceSearchResultCardCallHistoryButton
            imgName="messenger"
            text="메신저"
          />
          <WebServiceSearchResultCardCallHistoryButton
            imgName="memo_file"
            text="메모"
            link={'/app/web_service/contact/memo/lists'}
          />
          <WebServiceSearchResultCardCallHistoryButton
            imgName="play"
            text="녹음듣기"
            onButtonClick={onRecordClick}
          />
          <WebServiceSearchResultCardCallHistoryButton
            imgName="person"
            text="프로필"
          />
        </div>
      ) : null}
    </div>
  );
};

export default WebServiceSearchResultCardCallHistory;
