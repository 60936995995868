import routes from 'configs/routes';

const mainNavItems = {
  dial: {
    iconUrl: '/icons/theme/nav-dial.png',
    menuLabel: '다이얼',
    link: routes.main_dial
  },
  call_log: {
    iconUrl: '/icons/theme/nav-call-log.png',
    menuLabel: '최근 통화',
    link: routes.main_call_log
  },
  sms: {
    iconUrl: '/icons/theme/nav-sms.png',
    menuLabel: '문자',
    link: routes.main_sms
  },
  contact: {
    iconUrl: '/icons/theme/nav-contact.png',
    menuLabel: '주소록',
    link: routes.main_contact
  },
  more: {
    iconUrl: '/icons/theme/nav-more.png',
    menuLabel: '더보기',
    link: '/'
  }
};

export default mainNavItems;
