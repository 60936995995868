/* eslint-disable react/prop-types */
/* eslint-disable operator-linebreak */
import React from 'react';
import 'stylesheets/web_service/more.scss';

const WebServiceMoreSubtitle = (props: any) => {
  const { title } = props;

  return <div className="web-service-more-subtitle">{title}</div>;
};

export default WebServiceMoreSubtitle;
