/* eslint-disable operator-linebreak */
import { createSlice } from '@reduxjs/toolkit';
import { BottomNavMenu } from 'configs/enum';

type navState = {
  selectedNavMenu: string;
};

const initialState: navState = {
  selectedNavMenu: BottomNavMenu.callLog
};

export const bottomNavSlice = createSlice({
  name: 'bottomNavSlice',
  initialState,
  reducers: {
    selectDial: (state, action) => {
      state.selectedNavMenu = BottomNavMenu.dial;
      console.log('wwww');
    },
    selectCallLog: (state, action) => {
      state.selectedNavMenu = BottomNavMenu.callLog;
      console.log('wwww');
    },
    selectSms: (state, action) => {
      state.selectedNavMenu = BottomNavMenu.sms;
    },
    selectMessenger: (state, action) => {
      state.selectedNavMenu = BottomNavMenu.messenger;
    },
    selectContact: (state, action) => {
      state.selectedNavMenu = BottomNavMenu.contact;
    },
    selectMore: (state, action) => {
      state.selectedNavMenu = BottomNavMenu.more;
    }
  }
});

export const {
  selectDial,
  selectCallLog,
  selectSms,
  selectMessenger,
  selectContact,
  selectMore
} = bottomNavSlice.actions;

export default bottomNavSlice.reducer;
