/* eslint-disable operator-linebreak */
import { createSlice } from '@reduxjs/toolkit';

type voiceDeviceSettingState = {
  inputDevice: { name: string; type: string; selected: boolean }[];
  outputDevice: { name: string; type: string; selected: boolean }[];
};

const initialState: voiceDeviceSettingState = {
  inputDevice: [
    { name: 'imac-system-speaker', type: 'radioOnly', selected: true },
    { name: 'BigHead-H001', type: 'radioOnly', selected: false },
    { name: 'supertoss-T1', type: 'radioOnly', selected: false }
  ],
  outputDevice: [
    { name: 'imac-system-mic', type: 'radioOnly', selected: true },
    { name: 'kasus-R', type: 'radioOnly', selected: false },
    { name: 'smallhead-h000', type: 'radioOnly', selected: false }
  ]
};

export const voiceDeviceSettingSlice = createSlice({
  name: 'voiceDeviceSetting',
  initialState,
  reducers: {
    selectInputDevice: (state, action) => {
      state.inputDevice.forEach(
        (item: any, idx: number) => (state.inputDevice[idx].selected = false)
      );
      state.inputDevice[action.payload].selected = true;
    },
    selectOutputDevice: (state, action) => {
      state.outputDevice.forEach(
        (item: any, idx: number) => (state.outputDevice[idx].selected = false)
      );
      state.outputDevice[action.payload].selected = true;
    }
  }
});

export const { selectInputDevice, selectOutputDevice } =
  voiceDeviceSettingSlice.actions;

export default voiceDeviceSettingSlice.reducer;
