/* eslint-disable react/prop-types */
/* eslint-disable operator-linebreak */
import React from 'react';
import { Link } from 'react-router-dom';

const WebServiceTopSheetMenuContainer = (props: any) => {
  const {
    items,
    openTopSheetMenu,
    setOpenTopSheetMenu,
    selectedIdx,
    setSelectedIdx,
    useAlarm
  } = props;
  return (
    <div
      className={'web-service-sheet-area' + (openTopSheetMenu ? ' active' : '')}
    >
      <div
        className={'web-service-overlay' + (openTopSheetMenu ? ' active' : '')}
        onClick={() => {
          setOpenTopSheetMenu(false);
        }}
      />
      <div
        className={
          'web-service-top-sheet-menu-container' +
          (openTopSheetMenu ? ' active' : '')
        }
      >
        {items.map((item: any, idx: any) => (
          <Link to={item.link} key={idx}>
            {item.type === 'radio' ? (
              <div
                className={
                  'web-service-top-sheet-menu-item-wrap' +
                  (selectedIdx === idx ? ' selected' : '')
                }
                onClick={() => setSelectedIdx(idx)}
              >
                <div className={'web-service-top-sheet-menu-item'}>
                  {item.name}
                  {selectedIdx === idx ? (
                    <div className="web-service-top-sheet-menu-item-selectText">
                      (선택됨)
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
            {item.type === 'switch' ? (
              <div className={'web-service-top-sheet-menu-item-wrap'} key={idx}>
                <div
                  className="web-service-top-sheet-menu-item"
                  onClick={item.onClick}
                >
                  {item.name}
                  {useAlarm ? (
                    <div className="web-service-switch-button active">
                      <div className="web-service-switch-wheel active" />
                    </div>
                  ) : (
                    <div className="web-service-switch-button">
                      <div className="web-service-switch-wheel" />
                    </div>
                  )}
                </div>
              </div>
            ) : null}
            {item.type === 'normal' ? (
              <div
                className={'web-service-top-sheet-menu-item-wrap'}
                onClick={item.onClick}
                key={idx}
              >
                <div
                  className={
                    'web-service-top-sheet-menu-item' +
                    (idx === 0 ? ' first' : '')
                  }
                >
                  {item.name}
                </div>
              </div>
            ) : null}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default WebServiceTopSheetMenuContainer;
