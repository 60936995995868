/* eslint-disable object-curly-spacing */
/* eslint-disable indent */
/* eslint-disable operator-linebreak */
import React, { MutableRefObject, useRef, useState } from 'react';
import 'stylesheets/web_service/messenger.scss';
import WebServiceCheckbox from '../input/checkbox';

const WebServiceMessengerImgInStorage = (props: any) => {
  const { isVideo, vidioLength, thumbnail, selectMode, onClick, isChecked } =
    props;
  const targetImg = useRef() as MutableRefObject<HTMLImageElement>;
  const [targetImgWidth, setTargetImgWidth] = useState(0);
  setTimeout(() => {
    setTargetImgWidth(targetImg.current.width);
  }, 100);
  return (
    <div className="web-service-messenger-img-in-storage" onClick={onClick}>
      <img
        src={'/img/photo/' + thumbnail}
        className="web-service-messenger-img-in-storage-img"
        alt="img"
        style={{ height: targetImgWidth }}
        ref={targetImg}
      />
      <div className="web-service-messenger-img-in-storage-overlay-hover" />
      <div
        className={
          'web-service-messenger-img-in-storage-overlay' +
          (isVideo ? ' video' : '') +
          (selectMode ? ' selectMode' : '')
        }
      >
        <div className="web-service-messenger-img-in-storage-overlay-checkbox">
          {selectMode ? (
            <WebServiceCheckbox isLabel={false} isChecked={isChecked} />
          ) : (
            ''
          )}
        </div>
        <div className="web-service-messenger-img-in-storage-overlay-video-time">
          {isVideo ? vidioLength : ''}
        </div>
      </div>
    </div>
  );
};

export default WebServiceMessengerImgInStorage;
