/* eslint-disable max-len */
import React from 'react';

import 'stylesheets/landing.scss';
import LandingPageWrapper from 'components/landing_page_wrapper';

const Landing = () => {
  // const [onSec2, setOnSec2] = useState([false, false, false]);
  // eslint-disable-next-line no-unused-vars
  // const [scrollSec2, setScrollSec2] = useState([0, 0, 0]);

  // const getPosition = (node: any) => {
  //   if (node === null) {
  //     return 0;
  //   }
  //   return node.getBoundingClientRect().y;
  // };

  // useEffect(() => {
  //   setTimeout(() => {
  //     setScrollSec2([
  //       getPosition(document.querySelector(`#landing-main-section-2-scroll1`)),
  //       getPosition(document.querySelector(`#landing-main-section-2-scroll2`)),
  //       getPosition(document.querySelector(`#landing-main-section-2-scroll3`))
  //     ]);
  //   }, 10000);

  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  // const handleScroll = () => {
  //   if (onSec2 && window.scrollY >= scrollSec2[0]) {
  //     setOnSec2([true, onSec2[1], onSec2[2]]);
  //   } else if (window.scrollY >= scrollSec2[1]) {
  //     setOnSec2([onSec2[0], true, onSec2[2]]);
  //   } else if (window.scrollY >= scrollSec2[2]) {
  //     setOnSec2([onSec2[0], onSec2[1], true]);
  //   } else if (window.scrollY < 1000) {
  //     setOnSec2([false, false, false]);
  //   }
  // };

  // if (onSec2[0]) {
  //   document.body.className = 'dark';
  // } else {
  //   document.body.className = 'light';
  // }
  return (
    <LandingPageWrapper>
      <div className="landing-main-section-1 fade-in-1 am-running">
        <div className="landing-main-text-wrap ">
          <h1>스마트 오피스 시작! Touch Call</h1>
        </div>
        <button className="btn-prm-web big trs-015">다운로드</button>
        <img
          className="img-insec-01"
          src="img/landing/mobile_test.png"
          alt="touchcall app"
        />
      </div>
      <div className="landing-main-section-2">
        <div className="landing-main-text-wrap">
          <h2 className={'fade-in-1'} id="landing-main-section-2-scroll1">
            내선번호를 모바일과 PC에서
          </h2>
          <p className={'fade-in-1'} id="landing-main-section-2-scroll2">
            동일한 내선번호로 여러 디바이스에서 통화와 문자를 이용해보세요
          </p>
        </div>
        <div className={'landing-main-section-2-img-container fade-in-3'}>
          <div
            className="landing-main-section-2-img-wrap"
            id="landing-main-section-2-scroll3"
          >
            <img
              className="img-insec-01"
              src="img/landing/mobile_test.png"
              alt="touchcall app"
            />
            <img
              className="img-insec-02"
              src="img/landing/desktop_test.png"
              alt="touchcall app"
            />
          </div>
        </div>
      </div>
      <div className="landing-main-section-2">sdf</div>
    </LandingPageWrapper>
  );
};

export default Landing;
