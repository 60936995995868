/* eslint-disable max-len */
import WebServiceNormalBody from 'components/web_service/body/normal_body';
import WebServiceSubTitle from 'components/web_service/content_title/sub_title';
import WebServiceBackMultiTextHeader from 'components/web_service/header/back_multi_text';
import WebServiceInput from 'components/web_service/input/input';
import WebServiceInviteSelectedGroup from 'components/web_service/search/group_call/selected_group';
import WebServiceSearchResultCardContact from 'components/web_service/search/result_card_contact';
import WebServiceWrapper from 'components/web_service_wrapper';
import React, { useState } from 'react';
import { MessengerUserList } from 'configs/items';
import WebServiceBottomFixedPrimaryButton from 'components/web_service/fixed_component/primary_button';

const WebServiceContactAddAll = () => {
  const [inputValue, setInputValue] = useState('');

  const handleChange = (e: any) => {
    setInputValue(e.target.value);
  };

  const onReset = () => {
    setInputValue('');
  };

  return (
    <WebServiceWrapper hideNav={false} isCalling={false} scrollLock={false}>
      <WebServiceBackMultiTextHeader
        isFirstDiabled={true}
        onBackClick="/app/web_servic/contact/add_from_my"
      />
      <WebServiceNormalBody type="contact" useOverlay={false} background="">
        <WebServiceInviteSelectedGroup users={MessengerUserList} />
        <div className="web-service-contact-add-all-input-wrap">
          <WebServiceInput
            hasLabel={false}
            type="withRemove"
            inputType="text"
            placeholder="이름 또는 전화번호 검색"
            inputValue={inputValue}
            onChange={handleChange}
            onReset={onReset}
          />
        </div>
        {inputValue ? (
          <div>
            <WebServiceSearchResultCardContact
              equalName=""
              name="홍길동"
              equalNumber=""
              number="010-1234-1234"
              profile="profile.jpg"
              padding={true}
              canSpread={false}
              isFirst={false}
              hoverEffect={true}
              type="withCheckbox"
            />
            <WebServiceSearchResultCardContact
              equalName=""
              name="홍길동"
              equalNumber=""
              number="010-1234-1234"
              profile="profile.jpg"
              padding={true}
              canSpread={false}
              isFirst={false}
              hoverEffect={true}
              type="withCheckbox"
            />
            <WebServiceSearchResultCardContact
              equalName=""
              name="홍길동"
              equalNumber=""
              number="010-1234-1234"
              profile="profile.jpg"
              padding={true}
              canSpread={false}
              isFirst={false}
              hoverEffect={true}
              type="withCheckbox"
            />
            <WebServiceSearchResultCardContact
              equalName=""
              name="홍길동"
              equalNumber=""
              number="010-1234-1234"
              profile="profile.jpg"
              padding={true}
              canSpread={false}
              isFirst={false}
              hoverEffect={true}
              type="withCheckbox"
            />
            <WebServiceSearchResultCardContact
              equalName=""
              name="홍길동"
              equalNumber=""
              number="010-1234-1234"
              profile="profile.jpg"
              padding={true}
              canSpread={false}
              isFirst={false}
              hoverEffect={true}
              type="withCheckbox"
            />
            <WebServiceSearchResultCardContact
              equalName=""
              name="홍길동"
              equalNumber=""
              number="010-1234-1234"
              profile="profile.jpg"
              padding={true}
              canSpread={false}
              isFirst={false}
              hoverEffect={true}
              type="withCheckbox"
            />
          </div>
        ) : (
          <div>
            <WebServiceSubTitle title="ㄱ" />
            <WebServiceSearchResultCardContact
              equalName=""
              name="홍길동"
              equalNumber=""
              number="010-1234-1234"
              profile="profile.jpg"
              padding={true}
              canSpread={false}
              isFirst={true}
              hoverEffect={true}
              type="withCheckbox"
            />
            <WebServiceSearchResultCardContact
              equalName=""
              name="홍길동"
              equalNumber=""
              number="010-1234-1234"
              profile="profile.jpg"
              padding={true}
              canSpread={false}
              isFirst={false}
              hoverEffect={true}
              type="withCheckbox"
            />
            <WebServiceSearchResultCardContact
              equalName=""
              name="홍길동"
              equalNumber=""
              number="010-1234-1234"
              profile="profile.jpg"
              padding={true}
              canSpread={false}
              isFirst={false}
              hoverEffect={true}
              type="withCheckbox"
            />
            <WebServiceSubTitle title="ㄴ" />
            <WebServiceSearchResultCardContact
              equalName=""
              name="홍길동"
              equalNumber=""
              number="010-1234-1234"
              profile="profile.jpg"
              padding={true}
              canSpread={false}
              isFirst={true}
              hoverEffect={true}
              type="withCheckbox"
            />
            <WebServiceSearchResultCardContact
              equalName=""
              name="홍길동"
              equalNumber=""
              number="010-1234-1234"
              profile="profile.jpg"
              padding={true}
              canSpread={false}
              isFirst={false}
              hoverEffect={true}
              type="withCheckbox"
            />
            <WebServiceSearchResultCardContact
              equalName=""
              name="홍길동"
              equalNumber=""
              number="010-1234-1234"
              profile="profile.jpg"
              padding={true}
              canSpread={false}
              isFirst={false}
              hoverEffect={true}
              type="withCheckbox"
            />
            <WebServiceSubTitle title="ㄷ" />
            <WebServiceSearchResultCardContact
              equalName=""
              name="홍길동"
              equalNumber=""
              number="010-1234-1234"
              profile="profile.jpg"
              padding={true}
              canSpread={false}
              isFirst={true}
              hoverEffect={true}
              type="withCheckbox"
            />
            <WebServiceSearchResultCardContact
              equalName=""
              name="홍길동"
              equalNumber=""
              number="010-1234-1234"
              profile="profile.jpg"
              padding={true}
              canSpread={false}
              isFirst={false}
              hoverEffect={true}
              type="withCheckbox"
            />
            <WebServiceSearchResultCardContact
              equalName=""
              name="홍길동"
              equalNumber=""
              number="010-1234-1234"
              profile="profile.jpg"
              padding={true}
              canSpread={false}
              isFirst={false}
              hoverEffect={true}
              type="withCheckbox"
            />
          </div>
        )}
      </WebServiceNormalBody>
      <WebServiceBottomFixedPrimaryButton
        buttonName={'10개 연락처 추가'}
        isDisabled={true}
      />
    </WebServiceWrapper>
  );
};

export default WebServiceContactAddAll;
