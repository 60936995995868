/* eslint-disable react/prop-types */
/* eslint-disable operator-linebreak */
import React from 'react';
import store from 'redux/store';

const WebServiceBookmarkModal = (props: any) => {
  const { useBookmark, bookmarkShow } = props;

  return (
    <div
      className={'web-service-bookmark-modal' + (bookmarkShow ? ' active' : '')}
    >
      <img
        src={
          '/icon/' +
          (store.getState().theme.theme === 'light'
            ? 'lightmode/'
            : 'darkmode/') +
          (useBookmark ? 'bookmark-fill-yellow.png' : 'bookmark-outline-t1.png')
        }
        className="web-service-bookmark-modal-icon"
        alt="bookmark on/off"
      />
      <div className="web-service-bookmark-modal-text">
        {useBookmark
          ? '즐겨찾기에 등록되었습니다.'
          : '즐겨찾기가 해제되었습니다.'}
      </div>
    </div>
  );
};

export default WebServiceBookmarkModal;
