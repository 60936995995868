const routes = {
  landing: '/landing',
  // app
  main_dial: '/app/dial',
  main_call_log: '/app/call/log',
  main_sms: '/app/sms/list',
  main_contact: '/app/contact/list',
  web_service_login: '/app/web_service/login',
  web_service_auth_permission: '/app/web_service/auth_permission',
  web_service_dial: '/app/web_service/dial',
  web_service_group_call_search: '/app/web_service/group_call_search',
  web_service_call: '/app/web_service/call',
  web_service_call_log: '/app/web_service/call_log',
  web_service_sms: '/app/web_service/sms',
  web_service_sms_chat: '/app/web_service/sms/chat',
  web_service_messenger: '/app/web_service/messenger',
  web_service_messenger_chat: '/app/web_service/messenger/chat',
  web_service_contact: '/app/web_service/contact',
  web_service_contact_add_from_my: '/app/web_service/contact/add_from_my',
  web_service_add_contact: '/app/web_service/add_contact',
  web_service_contact_add_all: '/app/web_service/contact/add_all',
  web_service_contact_camera: '/app/web_service/contact/camera',
  web_service_contact_profile: '/app/web_service/contact/profile',
  web_service_more: '/app/web_service/more',
  web_service_more_absence: '/app/web_service/more/absence',
  web_service_more_available: '/app/web_service/more/available',
  web_service_more_ringing_tone: '/app/web_service/more/ringing_tone',
  web_service_more_forwarding: '/app/web_service/more/forwarding',
  web_service_more_call_type: '/app/web_service/more/call_type',
  web_service_more_record: '/app/web_service/more/record',
  web_service_more_voice_device: '/app/web_service/more/voice_device',
  web_service_more_theme: '/app/web_service/more/theme',
  web_service_my_profile: '/app/web_service/more/my_profile',
  web_service_change_password: '/app/web_service/more/change_password',
  web_service_more_notice_list: '/app/web_service/more/notice_list',
  web_service_more_notice_read: '/app/web_service/more/notice_read',
  web_service_more_version: '/app/web_service/more/version',
  web_service_more_language: '/app/web_service/more/language',
  web_service_network: '/app/web_service/network',
  web_service_memo_lists: '/app/web_service/contact/memo/lists'
};

export default routes;
