/* eslint-disable operator-linebreak */
import React from 'react';
import store from 'redux/store';
import { useNavigate } from 'react-router-dom';

import 'stylesheets/web_service/nav.scss';

const WebServiceBackMultiTextHeader = (props: any) => {
  const navigate = useNavigate();
  const {
    onBackClick,
    firstButtonClick,
    secondButtonClick,
    isFirstDiabled,
    isSecondDiabled
  } = props;

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="web-service-multi-right-header-container">
      <div className="web-service-multi-right-header-left" onClick={goBack}>
        <img
          src={
            '/icon/' +
            (store.getState().theme.theme === 'light'
              ? 'lightmode/'
              : 'darkmode/') +
            'arrow_left-t1.png'
          }
          className="web-service-multi-right-left-icon"
          alt="back"
        />
      </div>
      <div
        className="web-service-multi-right-title-header-right"
        onClick={onBackClick}
      >
        <div
          className={
            'web-service-multi-right-header-right-button-wrap' +
            (isFirstDiabled ? ' disabled' : '')
          }
          onClick={firstButtonClick}
        >
          전체해제
        </div>
        <div
          className={
            'web-service-multi-right-header-right-button-wrap' +
            (isSecondDiabled ? ' disabled' : '')
          }
          onClick={secondButtonClick}
        >
          전체선택
        </div>
      </div>
    </div>
  );
};

export default WebServiceBackMultiTextHeader;
