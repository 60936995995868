/* eslint-disable max-len */
import WebServiceNormalBody from 'components/web_service/body/normal_body';
import WebServiceCenterTitleHeader from 'components/web_service/header/center_title';
import WebServiceWrapper from 'components/web_service_wrapper';
import React from 'react';

const WebServiceContactCamera = () => {
  return (
    <WebServiceWrapper hideNav={false} isCalling={false} scrollLock={false}>
      <WebServiceCenterTitleHeader title="명함 촬영" />
      <WebServiceNormalBody type="normal" useOverlay={false} background="">
        <div className="web-service-contact-camera-view" />
        <div className="web-service-contact-camera-button-area">
          <div className="web-service-contact-camera-button-outline">
            <div className="web-service-contact-camera-button" />
          </div>
        </div>
      </WebServiceNormalBody>
    </WebServiceWrapper>
  );
};

export default WebServiceContactCamera;
