/* eslint-disable operator-linebreak */
import React from 'react';
import store from 'redux/store';
import { useNavigate } from 'react-router-dom';

import 'stylesheets/web_service/nav.scss';

const WebServiceProfileHeader = (props: any) => {
  const navigate = useNavigate();
  const { firstButtonClick, secondButtonClick, thirdButtonClick, useBookmark } =
    props;

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="web-service-profile-header-container">
      <div className="web-service-profile-header-left" onClick={goBack}>
        <img
          src={
            '/icon/' +
            (store.getState().theme.theme === 'light'
              ? 'lightmode/'
              : 'darkmode/') +
            'arrow_left-t1.png'
          }
          className="web-service-profile-left-icon"
          alt="back"
        />
      </div>
      <div className="web-service-profile-header-right">
        <div
          className={'web-service-profile-header-right-button-wrap'}
          onClick={firstButtonClick}
        >
          <img
            src={
              '/icon/' +
              (store.getState().theme.theme === 'light'
                ? 'lightmode/'
                : 'darkmode/') +
              'add_person-outline-t1.png'
            }
            className="web-service-profile-left-icon"
            alt="back"
          />
        </div>
        <div
          className={'web-service-profile-header-right-button-wrap'}
          onClick={secondButtonClick}
        >
          {useBookmark ? (
            <img
              src={
                '/icon/' +
                (store.getState().theme.theme === 'light'
                  ? 'lightmode/'
                  : 'darkmode/') +
                'bookmark-fill-yellow.png'
              }
              className="web-service-profile-left-icon"
              alt="back"
            />
          ) : (
            <img
              src={
                '/icon/' +
                (store.getState().theme.theme === 'light'
                  ? 'lightmode/'
                  : 'darkmode/') +
                'bookmark-outline-t1.png'
              }
              className="web-service-profile-left-icon"
              alt="back"
            />
          )}
        </div>
        <div
          className={'web-service-profile-header-right-button-wrap'}
          onClick={thirdButtonClick}
        >
          <img
            src={
              '/icon/' +
              (store.getState().theme.theme === 'light'
                ? 'lightmode/'
                : 'darkmode/') +
              'setting-outline-t1.png'
            }
            className="web-service-profile-left-icon"
            alt="back"
          />
        </div>
      </div>
    </div>
  );
};

export default WebServiceProfileHeader;
