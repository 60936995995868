export const ContactListItems = [
  {
    name: '한길동 대리 가나다주식회사',
    number: '010-0000-0001',
    state: 'online',
    profile: 'profile.jpg'
  },
  {
    name: '두길동 대리 가나다주식회사',
    number: '010-0000-0002',
    state: 'offline',
    profile: 'profile.jpg'
  },
  {
    name: '세길동 대리 가나다주식회사',
    number: '010-0000-0003',
    state: 'idle',
    profile: 'profile.jpg'
  },
  {
    name: '네길동 대리 가나다주식회사',
    number: '010-0000-0004',
    state: 'DNdisturb',
    profile: 'profile.jpg'
  },
  {
    name: '오길동 대리 가나다주식회사',
    number: '010-0000-0005',
    state: 'online',
    profile: 'profile.jpg'
  },
  {
    name: '육길동 대리 가나다주식회사',
    number: '010-0000-0006',
    state: 'online',
    profile: 'profile.jpg'
  },
  {
    name: '최길동 대리 가나다주식회사',
    number: '010-0000-0007',
    state: 'online',
    profile: 'profile.jpg'
  },
  {
    name: '팔길동 대리 가나다주식회사',
    number: '010-0000-0008',
    state: 'idle',
    profile: 'profile.jpg'
  },
  {
    name: '구길동 대리 가나다주식회사',
    number: '010-0000-0009',
    state: 'idle',
    profile: 'profile.jpg'
  },
  {
    name: '십길동 대리 가나다주식회사',
    number: '010-0000-0010',
    state: 'online',
    profile: 'profile.jpg'
  },
  {
    name: '시빌길동 대리 가나다주식회사',
    number: '010-0000-0011',
    state: 'online',
    profile: 'profile.jpg'
  },
  {
    name: '시비홍길동 대리 가나다주식회사',
    number: '010-0000-0012',
    state: 'online',
    profile: 'profile.jpg'
  },
  {
    name: '십삼홍길동 대리 가나다주식회사',
    number: '010-0000-0013',
    state: 'offline',
    profile: 'profile.jpg'
  },
  {
    name: '시사길동 대리 가나다주식회사',
    number: '010-0000-0014',
    state: 'online',
    profile: 'profile.jpg'
  },
  {
    name: '시오길동 대리 가나다주식회사',
    number: '010-0000-0015',
    state: 'offline',
    profile: 'profile.jpg'
  },
  {
    name: '시육길동 대리 가나다주식회사',
    number: '010-0000-0016',
    state: 'online',
    profile: 'profile.jpg'
  },
  {
    name: '시치길동 대리 가나다주식회사',
    number: '010-0000-0017',
    state: 'online',
    profile: 'profile.jpg'
  },
  {
    name: '시파길동 대리 가나다주식회사',
    number: '010-0000-0018',
    state: 'online',
    profile: 'profile.jpg'
  },
  {
    name: '시구길동 대리 가나다주식회사',
    number: '010-0000-0019',
    state: 'online',
    profile: 'profile.jpg'
  }
];
export const SMSListItems = [
  {
    name: '홍길동',
    text: '말씀하신 자료 메일로 전달 드렸습니다. 피드백 사항 있으시면 편하신 방법으로 전달주세요.',
    date: '3분 전',
    alarmAmount: '3',
    profile: 'profile.jpg',
    state: 'online'
  },
  {
    name: '김길동',
    text: '핫도그먹자',
    date: '5분 전',
    alarmAmount: '7',
    profile: 'profile.jpg',
    state: 'offline'
  },
  {
    name: '둘리',
    text: '둘리입니다',
    date: '3분 전',
    alarmAmount: '3',
    profile: 'profile.jpg',
    state: 'idle'
  },
  {
    name: '홍길동',
    text: '말씀하신 자료 메일로 전달 드렸습니다. 피드백 사항 있으시면 편하신 방법으로 전달주세요.',
    date: '3분 전',
    alarmAmount: '3',
    profile: 'profile.jpg',
    state: 'DNdisturb'
  },
  {
    name: '홍길동',
    text: '말씀하신 자료 메일로 전달 드렸습니다. 피드백 사항 있으시면 편하신 방법으로 전달주세요.',
    date: '3분 전',
    alarmAmount: '0',
    profile: 'profile.jpg',
    state: 'online'
  },
  {
    name: '홍길동',
    text: '말씀하신 자료 메일로 전달 드렸습니다. 피드백 사항 있으시면 편하신 방법으로 전달주세요.',
    date: '3분 전',
    alarmAmount: '3',
    isChecked: false,
    profile: 'profile.jpg',
    state: 'online'
  },
  {
    name: '홍길동',
    text: '말씀하신 자료 메일로 전달 드렸습니다. 피드백 사항 있으시면 편하신 방법으로 전달주세요.',
    date: '3분 전',
    alarmAmount: '3',
    profile: 'profile.jpg',
    state: 'online'
  }
];
export const CallLogListItems = [
  {
    callType: 'receive',
    equalName: '홍길',
    name: '동',
    equalNumber: '010-1',
    number: '234-1234',
    date: '2022/09/15',
    time: '오후 10:35'
  },
  {
    callType: 'sent',
    equalName: '홍길',
    name: '동',
    equalNumber: '010-1',
    number: '234-3644',
    date: '2022/09/15',
    time: '오후 10:35'
  },
  {
    callType: 'absence',
    equalName: '홍길',
    name: '동',
    equalNumber: '010-1',
    number: '234-1234',
    date: '2022/09/15',
    time: '오후 10:35'
  },
  {
    callType: 'absence',
    equalName: '홍길',
    name: '동',
    equalNumber: '010-1',
    number: '234-1234',
    date: '2022/09/16',
    time: '오후 10:35'
  },
  {
    callType: 'block',
    equalName: '홍길',
    name: '동',
    equalNumber: '010-1',
    number: '234-1234',
    date: '2022/09/16',
    time: '오후 10:35'
  },
  {
    callType: 'receive',
    equalName: '홍길',
    name: '동',
    equalNumber: '010-1',
    number: '234-1234',
    date: '2022/09/16',
    time: '오후 10:35'
  },
  {
    callType: 'receive',
    equalName: '홍길',
    name: '동',
    equalNumber: '010-1',
    number: '234-1234',
    date: '2022/09/15',
    time: '오후 10:35'
  },
  {
    callType: 'receive',
    equalName: '홍길',
    name: '동',
    equalNumber: '010-1',
    number: '234-1234',
    date: '2022/09/15',
    time: '오후 10:35'
  },
  {
    callType: 'block',
    equalName: '홍길',
    name: '동',
    equalNumber: '010-1',
    number: '234-1234',
    date: '2022/09/17',
    time: '오후 10:35'
  },
  {
    callType: 'block',
    equalName: '홍길',
    name: '동',
    equalNumber: '010-1',
    number: '234-1234',
    date: '2022/09/17',
    time: '오후 10:35'
  },
  {
    callType: 'block',
    equalName: '홍길',
    name: '동',
    equalNumber: '010-1',
    number: '234-1234',
    date: '2022/09/17',
    time: '오후 10:35'
  },
  {
    callType: 'receive',
    equalName: '홍길',
    name: '동',
    equalNumber: '010-1',
    number: '234-1234',
    date: '2022/09/17',
    time: '오후 10:35'
  },
  {
    callType: 'sent',
    equalName: '홍길',
    name: '동',
    equalNumber: '010-1',
    number: '234-1234',
    date: '2022/09/17',
    time: '오후 10:35'
  },
  {
    callType: 'receive',
    equalName: '홍길',
    name: '동',
    equalNumber: '010-1',
    number: '234-1234',
    date: '2022/09/18',
    time: '오후 10:35'
  },
  {
    callType: 'absence',
    equalName: '홍길',
    name: '동',
    equalNumber: '010-1',
    number: '234-1234',
    date: '2022/09/18',
    time: '오후 10:35'
  },
  {
    callType: 'sent',
    equalName: '홍길',
    name: '동',
    equalNumber: '010-1',
    number: '234-1234',
    date: '2022/09/18',
    time: '오후 10:35'
  },
  {
    callType: 'receive',
    equalName: '홍길',
    name: '동',
    equalNumber: '010-1',
    number: '234-1234',
    date: '2022/09/15',
    time: '오후 10:35'
  }
];

export const ChatBubbleItems1 = [
  {
    userId: '0000',
    type: 'text',
    profileImg: '/img/profile/profile.jpg',
    name: '홍길동',
    text: '안녕하십니까 백성여러분! 이게 나라입니까!!!',
    date: '2022년 12월 27일 화요일',
    time: '오후 11:42'
  },
  {
    userId: '0000',
    type: 'text',
    profileImg: '/img/profile/profile.jpg',
    name: '홍길동',
    text: '말이 안됩니다. 이 탐관오리놈들',
    date: '2022년 12월 27일 화요일',
    time: '오후 11:42'
  },
  {
    userId: '0000',
    type: 'text',
    profileImg: '/img/profile/profile.jpg',
    name: '홍길동',
    text: '오늘 우리는 되찾을 것입니다!!!',
    date: '2022년 12월 27일 화요일',
    time: '오후 11:42'
  },
  {
    userId: '0001',
    type: 'text',
    profileImg: '/img/profile/profile.jpg',
    name: '홍길동 부하',
    text: '홍길동을 조정으로!!!!',
    date: '2022년 12월 27일 화요일',
    time: '오후 11:58'
  },
  {
    userId: '0001',
    type: 'text',
    profileImg: '/img/profile/profile.jpg',
    name: '홍길동 부하',
    text: '홍길동! 홍길동! 홍길동!!',
    date: '2022년 12월 27일 화요일',
    time: '오후 11:58'
  },
  {
    userId: '0001',
    type: 'text',
    profileImg: '/img/profile/profile.jpg',
    name: '홍길동 부하',
    text: '갓길동을 조정으로!!!!~~',
    date: '2022년 12월 27일 화요일',
    time: '오후 11:58'
  },
  {
    userId: '0001',
    type: 'file',
    profileImg: '/img/profile/profile.jpg',
    name: '홍길동 부하',
    date: '2022년 12월 27일 화요일',
    time: '오후 11:58',
    fileName: 'GodGildong.docx',
    fileSize: '425KB',
    fileFormat: 'compressedFile',
    fileDownloadPeriod: '~2023.12.04',
    fileDownloaded: false
  },
  {
    userId: '0001',
    type: 'file',
    profileImg: '/img/profile/profile.jpg',
    name: '홍길동 부하',
    date: '2022년 12월 28일 수요일',
    time: '오전 12:01',
    fileName: 'GodGildong.docx',
    fileSize: '425KB',
    fileFormat: 'compressedFile',
    fileDownloadPeriod: '~2023.12.04',
    fileDownloaded: true
  },
  {
    type: 'file',
    profileImg: '/img/profile/profile.jpg',
    name: '홍길동 부하',
    date: '2022년 12월 28일 수요일',
    time: '오전 12:01',
    fileName: 'GodGildong.docx',
    fileSize: '425KB',
    fileFormat: 'document',
    fileDownloadPeriod: '~2023.12.04',
    fileDownloaded: true
  },
  {
    userId: '0000',
    type: 'text',
    profileImg: '/img/profile/profile.jpg',
    name: '홍길동',
    text: '안녕하십니까 백성여러분! 이게 나라입니까!!!',
    date: '2022년 12월 28일 수요일',
    time: '오전 12:01'
  },
  {
    userId: '0000',
    type: 'text',
    profileImg: '/img/profile/profile.jpg',
    name: '홍길동',
    text: '말이 안됩니다. 이 탐관오리놈들',
    date: '2022년 12월 28일 수요일',
    time: '오전 12:01'
  },
  {
    userId: '0000',
    type: 'url',
    profileImg: '/img/profile/profile.jpg',
    name: '홍길동',
    url: 'www.youtube.com',
    date: '2022년 12월 28일 수요일',
    time: '오전 12:01'
  },
  {
    userId: '0000',
    type: 'image',
    profileImg: '/img/profile/profile.jpg',
    name: '홍길동',
    text: '오늘 우리는 되찾을 것입니다!!!',
    date: '2022년 12월 28일 수요일',
    time: '오전 12:01',
    image: ['photo1.jpg']
  },
  {
    userId: '0000',
    type: 'image',
    profileImg: '/img/profile/profile.jpg',
    name: '홍길동',
    text: '오늘 우리는 되찾을 것입니다!!!',
    date: '2022년 12월 28일 수요일',
    time: '오전 12:01',
    image: ['photo1.jpg', 'photo2.jpg']
  },

  {
    userId: '0000',
    type: 'image',
    profileImg: '/img/profile/profile.jpg',
    name: '홍길동',
    text: '오늘 우리는 되찾을 것입니다!!!',
    date: '2022년 12월 28일 수요일',
    time: '오전 12:01',
    image: ['photo1.jpg', 'photo2.jpg', 'photo3.jpg']
  },
  {
    userId: '0000',
    type: 'text',
    profileImg: '/img/profile/profile.jpg',
    name: '홍길동',
    text: '안녕하십니까 백성여러분! 이게 나라입니까!!!',
    date: '2022년 12월 28일 수요일',
    time: '오전 12:02'
  },
  {
    userId: '0000',
    type: 'image',
    profileImg: '/img/profile/profile.jpg',
    name: '홍길동',
    text: '오늘 우리는 되찾을 것입니다!!!',
    date: '2022년 12월 28일 수요일',
    time: '오전 12:02',
    image: ['photo1.jpg', 'photo2.jpg', 'photo3.jpg', 'photo4.jpg']
  },
  {
    userId: '0000',
    type: 'image',
    profileImg: '/img/profile/profile.jpg',
    name: '홍길동',
    text: '오늘 우리는 되찾을 것입니다!!!',
    date: '2022년 12월 28일 수요일',
    time: '오전 12:02',
    image: [
      'photo1.jpg',
      'photo2.jpg',
      'photo3.jpg',
      'photo4.jpg',
      'photo2.jpg',
      'photo1.jpg',
      'photo1.jpg'
    ]
  },
  {
    userId: 'myId',
    type: 'text',
    profileImg: '/img/profile/profile.jpg',
    name: '탐관오리',
    text: '도적놈들이다!!!',
    date: '2022년 12월 28일 수요일',
    time: '오전 12:02'
  },
  {
    userId: 'myId',
    type: 'text',
    profileImg: '/img/profile/profile.jpg',
    name: '탐관오리',
    text: '저 도적놈들을 잡아라!!',
    date: '2022년 12월 28일 수요일',
    time: '오전 12:05'
  },
  {
    userId: 'myId',
    type: 'image',
    profileImg: '/img/profile/profile.jpg',
    name: '홍탐관오리길동',
    text: '오늘 우리는 되찾을 것입니다!!!',
    date: '2022년 12월 28일 수요일',
    time: '오전 12:05',
    image: [
      'photo1.jpg',
      'photo2.jpg',
      'photo3.jpg',
      'photo4.jpg',
      'photo2.jpg',
      'photo1.jpg',
      'photo1.jpg'
    ]
  },
  {
    userId: 'myId',
    type: 'text',
    profileImg: '/img/profile/profile.jpg',
    name: '탐관오리',
    text: '도적놈들이다!!!',
    date: '2022년 12월 28일 수요일',
    time: '오전 12:13'
  },
  {
    userId: 'myId',
    type: 'file',
    profileImg: '/img/profile/profile.jpg',
    name: '탐관오리',
    date: '2022년 12월 28일 수요일',
    time: '오전 12:13',
    fileName: 'GodGildongsCrime.docx',
    fileSize: '425KB',
    fileFormat: 'compressedFile',
    fileDownloadPeriod: '~2023.12.04',
    fileDownloaded: false
  },
  {
    userId: 'myId',
    type: 'file',
    profileImg: '/img/profile/profile.jpg',
    name: '탐관오리',
    date: '2022년 12월 28일 수요일',
    time: '오전 12:13',
    fileName: 'GodGildongsCrime.docx',
    fileSize: '425KB',
    fileFormat: 'compressedFile',
    fileDownloadPeriod: '~2023.12.04',
    fileDownloaded: true
  },
  {
    userId: 'myId',
    type: 'file',
    profileImg: '/img/profile/profile.jpg',
    name: '탐관오리',
    date: '2022년 12월 28일 수요일',
    time: '오전 12:13',
    fileName: 'GodGildongsCrime.docx',
    fileSize: '425KB',
    fileFormat: 'document',
    fileDownloadPeriod: '~2023.12.04',
    fileDownloaded: true
  },
  {
    userId: 'myId',
    type: 'text',
    profileImg: '/img/profile/profile.jpg',
    name: '탐관오리',
    text: '도적놈들이다!!!',
    date: '2022년 12월 28일 수요일',
    time: '오전 12:15'
  },
  {
    userId: 'myId',
    type: 'url',
    profileImg: '/img/profile/profile.jpg',
    name: '탐관오리',
    date: '2022년 12월 28일 수요일',
    time: '오전 12:15',
    url: 'www.youtube.com'
  }
];

export const MessengerUserList = [
  { name: '홍길동', telNumb: '010-1234-1234', profileImg: 'profile.jpg' },
  { name: '김길동', telNumb: '010-1234-1234', profileImg: 'profile.jpg' },
  { name: '박길동', telNumb: '010-1234-1234', profileImg: 'profile.jpg' },
  { name: '안길동', telNumb: '010-1234-1234', profileImg: 'profile.jpg' },
  { name: '차길동', telNumb: '010-1234-1234', profileImg: 'profile.jpg' },
  { name: '진길동', telNumb: '010-1234-1234', profileImg: 'profile.jpg' },
  { name: '최길동', telNumb: '010-1234-1234', profileImg: 'profile.jpg' },
  { name: '나길동', telNumb: '010-1234-1234', profileImg: 'profile.jpg' },
  { name: '이길동', telNumb: '010-1234-1234', profileImg: 'profile.jpg' },
  { name: '윤길동', telNumb: '010-1234-1234', profileImg: 'profile.jpg' }
];
