/* eslint-disable max-len */
/* eslint-disable indent */
import WebServiceNormalBody from 'components/web_service/body/normal_body';
import WebServiceSubTitle from 'components/web_service/content_title/sub_title';
import WebServiceBottomFixedPrimaryButton from 'components/web_service/fixed_component/primary_button';
import WebServiceCenterTitleHeader from 'components/web_service/header/center_title';
import WebServiceInput from 'components/web_service/input/input';
import WebServiceInviteSelectedGroup from 'components/web_service/search/group_call/selected_group';
import WebServiceSearchResultCardContact from 'components/web_service/search/result_card_contact';
import WebServiceWrapper from 'components/web_service_wrapper';
import React, { useState } from 'react';
import routes from 'configs/routes';

const WebServiceMessengerChatInvite = (props: any) => {
  const { openInvite, setOpenInvite } = props;
  const [inputValue, setInputValue] = useState('');
  const [isChecked, setIsChecked] = useState<number[]>([]);
  const contactData = [
    {
      type: 'withCheckbox',
      name: '홍길동',
      number: '010-1234-1234',
      profile: 'profile.jpg'
    },
    {
      type: 'withCheckbox',
      name: '홍길동',
      number: '010-1234-1234',
      profile: 'profile.jpg'
    },
    {
      type: 'withCheckbox',
      name: '홍길동',
      number: '010-1234-1234',
      profile: 'profile.jpg'
    },
    {
      type: 'withCheckbox',
      name: '홍길동',
      number: '010-1234-1234',
      profile: 'profile.jpg'
    },
    {
      type: 'withCheckbox',
      name: '홍길동',
      number: '010-1234-1234',
      profile: 'profile.jpg'
    },
    {
      type: 'withCheckbox',
      name: '홍길동',
      number: '010-1234-1234',
      profile: 'profile.jpg'
    },
    {
      type: 'withCheckbox',
      name: '홍길동',
      number: '010-1234-1234',
      profile: 'profile.jpg'
    },
    {
      type: 'withCheckbox',
      name: '홍길동',
      number: '010-1234-1234',
      profile: 'profile.jpg'
    },
    {
      type: 'withCheckbox',
      name: '홍길동',
      number: '010-1234-1234',
      profile: 'profile.jpg'
    },
    {
      type: 'withCheckbox',
      name: '홍길동',
      number: '010-1234-1234',
      profile: 'profile.jpg'
    }
  ];

  const handleChange = (e: any) => {
    setInputValue(e.target.value);
  };

  const onReset = () => {
    setInputValue('');
  };

  const checkboxHandleChange = (idx: number) => {
    if (isChecked.includes(idx)) {
      const activeList = isChecked.filter((i) => idx !== i);
      setIsChecked(activeList);
    } else {
      setIsChecked([...isChecked, idx]);
    }
  };

  return (
    <WebServiceWrapper hideNav={false} isCalling={false} scrollLock={''}>
      <WebServiceCenterTitleHeader
        title="채팅방 초대"
        onBackClick={routes.web_service_dial}
        backState={openInvite}
        setBackState={setOpenInvite}
      />
      <WebServiceNormalBody
        type="bottom-button"
        useOverlay={false}
        background=""
      >
        <WebServiceInviteSelectedGroup
          users={
            isChecked.length > 0
              ? isChecked.map((item: any, idx: number) => contactData[item])
              : []
          }
        />
        <div className="web-service-invite-search-input-wrap">
          <WebServiceInput
            hasLabel={false}
            type="withRemove"
            inputType="text"
            placeholder="이름 또는 전화번호 검색"
            inputValue={inputValue}
            onChange={handleChange}
            onReset={onReset}
          />
        </div>
        {inputValue ? (
          <>
            <WebServiceSearchResultCardContact
              type="withCheckbox"
              equalName=""
              name="홍길동"
              equalNumber=""
              number="010-1234-1234"
              padding={true}
              profile="profile.jpg"
              canSpread={false}
            />
            <WebServiceBottomFixedPrimaryButton
              isDisabled={true}
              buttonName="초대할 대상을 선택하세요"
            />
          </>
        ) : (
          <>
            <WebServiceSubTitle title="ㄱ" underline />
            {contactData.map((item: any, idx: any) => (
              <WebServiceSearchResultCardContact
                key={idx}
                type={item.type}
                equalName=""
                name={item.name}
                equalNumber=""
                number={item.number}
                padding={true}
                profile={item.profile}
                canSpread={false}
                onClick={() => checkboxHandleChange(idx)}
                isChecked={isChecked.includes(idx)}
              />
            ))}
            <WebServiceBottomFixedPrimaryButton
              buttonName={
                isChecked.length > 0
                  ? isChecked.length + '명 초대'
                  : '초대할 대상을 선택하세요'
              }
              isDisabled={isChecked.length > 0 ? false : true}
            />
          </>
        )}
      </WebServiceNormalBody>
    </WebServiceWrapper>
  );
};
export default WebServiceMessengerChatInvite;
