/* eslint-disable object-curly-spacing */
/* eslint-disable indent */
/* eslint-disable operator-linebreak */
import React from 'react';
import store from 'redux/store';
import 'stylesheets/web_service/search.scss';

const WebServiceInviteSelectedUser = (props: any) => {
  const { name } = props;
  return (
    <div className="web-service-invite-selected-user">
      <div className="web-service-invite-selected-user-profile-group">
        <div className="web-service-invite-selected-user-profile-wrap">
          <img
            src="/img/profile/profile.jpg"
            className="web-service-invite-selected-user-profile"
            alt="profile image"
          />
        </div>
        <div className="web-service-invite-selected-user-name">{name}</div>
      </div>
      <div className="web-service-invite-selected-user-delete-wrap">
        <img
          src={
            '/icon/' +
            (store.getState().theme.theme === 'light'
              ? 'lightmode/'
              : 'darkmode/') +
            'close_circle-t4.png'
          }
          className="web-service-invite-selected-user-delete default"
          alt="delete"
        />
        <img
          src={
            '/icon/' +
            (store.getState().theme.theme === 'light'
              ? 'lightmode/'
              : 'darkmode/') +
            'close_circle-t2.png'
          }
          className="web-service-invite-selected-user-delete hover-effect"
          alt="delete"
        />
      </div>
    </div>
  );
};

export default WebServiceInviteSelectedUser;
