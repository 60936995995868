/* eslint-disable indent */
/* eslint-disable react/prop-types */
/* eslint-disable operator-linebreak */
import React from 'react';
import 'stylesheets/web_service/chat.scss';

const WebServiceChatBubbleImage = (props: any) => {
  const { image } = props;

  return (
    <>
      {image.length === 1 ? (
        <div className="web-service-chat-bubble-image-wrap">
          <img
            src={`/img/photo/${image[0]}`}
            className="web-service-chat-bubble-image-alone"
          />
          <div className="web-service-chat-bubble-image-group-wrap-overlay"></div>
        </div>
      ) : null}

      {image.length > 1 && image.length < 5 ? (
        <div className="web-service-chat-bubble-image-wrap">
          {image.map((item: any, idx: any) => (
            <div
              key={idx}
              className={`web-service-chat-bubble-image-group-wrap-${image.length}`}
            >
              <img
                src={`/img/photo/${image[idx]}`}
                className={`web-service-chat-bubble-image-group-${image.length}`}
              />
              <div className="web-service-chat-bubble-image-group-wrap-overlay"></div>
            </div>
          ))}
        </div>
      ) : null}

      {image.length > 4 ? (
        <div className="web-service-chat-bubble-image-wrap">
          {image.slice(0, 4).map((item: any, idx: any) => (
            <>
              {idx !== 3 ? (
                <div
                  key={idx}
                  className={`web-service-chat-bubble-image-group-wrap-over`}
                >
                  <img
                    src={`/img/photo/${image[idx]}`}
                    className="web-service-chat-bubble-image-group-over"
                  />
                  <div className="web-service-chat-bubble-image-group-wrap-overlay"></div>
                </div>
              ) : (
                <div
                  key="3"
                  className="web-service-chat-bubble-image-group-over-4th-wrap"
                >
                  <div className="web-service-chat-bubble-image-group-over-4th-overlay">
                    {`+${image.length - 4}`}
                  </div>
                  <img
                    src={`/img/photo/${image[3]}`}
                    className="web-service-chat-bubble-image-group-over-4th"
                  />
                </div>
              )}
            </>
          ))}
        </div>
      ) : null}
    </>
  );
};

export default WebServiceChatBubbleImage;
