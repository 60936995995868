/* eslint-disable indent */
/* eslint-disable react/prop-types */
/* eslint-disable operator-linebreak */
import React, { useEffect, useState } from 'react';
import store from 'redux/store';
import WebServiceCheckbox from '../input/checkbox';
import WebServiceChatBubbleFile from './bubble_file';
import WebServiceChatBubbleImage from './bubble_image';
import WebServiceChatBubbleText from './bubble_text';
import WebServiceChatBubbleURL from './bubble_url';
import 'stylesheets/web_service/chat.scss';

const WebServiceChatBubble = (props: any) => {
  const {
    userId,
    type,
    profileImg,
    name,
    text,
    time,
    fileName,
    fileSize,
    fileFormat,
    fileDownloadPeriod,
    fileDownloaded,
    image,
    url,
    isDeleteMode,
    isFirstBubble,
    isLastBubble,
    isChecked,
    onCheckboxClick,
    isPopover,
    setIsPopover,
    unsetIsPopover,
    onReplyClick
  } = props;

  const [isMobile, setIsMobile] = useState(false);
  // type = text, link, file(fileDownload, fileFormat), img(imgAmount), video
  // text = string
  // time = string
  // isLastBubble(this minute)? = show time
  // isFirstBubble(this minute, not me)? = show profile, name
  // isDeleteMode? = show checkbox
  // (checkbox(profile(time(text))))
  // isMyBubble ? = mystyle...
  // 직전 작성된 bubble과 작성자 && 시간이 같나 ? 같은 그룹(bubble.group.tsx) : 다른 그룹

  useEffect(() => {
    /Mobi/i.test(navigator.userAgent) ? setIsMobile(true) : setIsMobile(false);
  }, []);

  return (
    <div
      className={
        'web-service-chat-bubble-set' +
        (userId === 'myId' ? ' myBubble' : ' othersBubble') +
        (isDeleteMode ? ' active' : '') +
        (isLastBubble ? ' lastBubble' : '')
      }
      onClick={onCheckboxClick}
    >
      <div
        className={
          'web-service-chat-bubble-checkbox-wrap' +
          (isDeleteMode ? ' active' : '')
        }
      >
        <WebServiceCheckbox isLabel={false} isChecked={isChecked} />
      </div>
      <div className="web-service-chat-bubble-img-cover">
        {userId !== 'myId' ? (
          <div
            className={
              'web-service-chat-bubble-profile-img-wrap' +
              (isFirstBubble ? ' show' : ' hide')
            }
          >
            {isFirstBubble ? (
              <img
                src={profileImg}
                className="web-service-chat-bubble-profile-img"
                alt="profile"
              />
            ) : (
              ''
            )}
            <div className="web-service-chat-bubble-profile-overlay" />
          </div>
        ) : null}

        <div className="web-service-chat-bubble-profile-name-cover">
          {isFirstBubble && userId !== 'myId' ? (
            <div className="web-service-chat-bubble-profile-name">{name}</div>
          ) : (
            ''
          )}
          <div
            className={
              'web-service-chat-bubble-text-cover' +
              (userId === 'myId' ? ' myBubble' : ' othersBubble')
            }
            onMouseOver={setIsPopover}
            onMouseLeave={unsetIsPopover}
          >
            {type === 'text' ? (
              <WebServiceChatBubbleText userId={userId} text={text} />
            ) : null}
            {type === 'file' ? (
              <WebServiceChatBubbleFile
                userId={userId}
                fileName={fileName}
                fileSize={fileSize}
                fileFormat={fileFormat}
                fileDownloadPeriod={fileDownloadPeriod}
                fileDownloaded={fileDownloaded}
              />
            ) : null}
            {type === 'image' ? (
              <WebServiceChatBubbleImage userId={userId} image={image} />
            ) : null}
            {type === 'url' ? (
              <WebServiceChatBubbleURL userId={userId} url={url} />
            ) : null}
            <div className="web-service-chat-bubble-time-area">
              {isLastBubble ? (
                <div
                  className={
                    'web-service-chat-bubble-time' + (isPopover ? ' hide' : '')
                  }
                >
                  {time}
                </div>
              ) : (
                ''
              )}
              {isMobile ? null : (
                <div
                  className={
                    'web-service-chat-bubble-click-pop' +
                    (isPopover ? ' active' : '')
                  }
                  onClick={onReplyClick}
                >
                  <div className="web-service-chat-bubble-click-pop-icon-wrap">
                    {userId === 'myId' ? (
                      <img
                        src={'/icon/common/reply.png'}
                        className="web-service-chat-bubble-click-pop-icon"
                        alt="copy"
                      />
                    ) : (
                      <img
                        src={
                          '/icon/' +
                          (store.getState().theme.theme === 'light'
                            ? 'lightmode/'
                            : 'darkmode/') +
                          'reply-outline-t1.png'
                        }
                        className="web-service-chat-bubble-click-pop-icon"
                        alt="copy"
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebServiceChatBubble;
