/* eslint-disable object-curly-spacing */
/* eslint-disable indent */
/* eslint-disable operator-linebreak */
import React from 'react';
import 'stylesheets/web_service/search.scss';
import WebServiceSubTitle from '../content_title/sub_title';
import WebServiceSearchHistoryCard from './search_history_card';

const WebServiceSearchHistory = (props: any) => {
  const { items, onDeleteClick } = props;
  return (
    <div className="web-service-search-history-wrap">
      <WebServiceSubTitle title="최근 검색기록" />
      {items &&
        items.map((item: any, idx: any) => (
          <WebServiceSearchHistoryCard
            key={idx}
            keyword={item.keyword}
            date={item.date}
            link={item.link}
            onDeleteClick={onDeleteClick}
            idx={idx}
          />
        ))}
    </div>
  );
};

export default WebServiceSearchHistory;
