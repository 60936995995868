import React from 'react';

const WebserviceToast = (props: any) => {
  const { showToast } = props;
  return (
    <div className="web-service-messenger-chat-toast-container">
      <div className="web-service-messenger-chat-toast">{showToast.text}</div>
    </div>
  );
};

export default WebserviceToast;
