import React from 'react';
import WebServiceWrapper from 'components/web_service_wrapper';
import WebServiceCenterTitleHeader from 'components/web_service/header/center_title';
import WebServiceNormalBody from 'components/web_service/body/normal_body';
// eslint-disable-next-line max-len
import WebServiceBottonSheetPrimaryButton from 'components/web_service/fixed_component/primary_button';

import store from 'redux/store';

import 'stylesheets/web_service/auth_permission.scss';

const WebServiceAuthPermission = () => {
  return (
    <WebServiceWrapper hideNav={false} isCalling={false} scrollLock={false}>
      <WebServiceCenterTitleHeader title="접근권한 설정" />
      <WebServiceNormalBody
        type="bottom-button"
        useOverlay={false}
        background=""
      >
        <div className="web-service-auth-permission-title">
          서비스 이용을 위해
          <br /> 다음 권한의 허용이 필요합니다.
        </div>
        <div className="web-service-auth-permission-itemset">
          <div className="web-service-auth-permission-item">
            <div className="web-service-auth-permission-icon-wrap">
              <img
                src={
                  '/icon/' +
                  (store.getState().theme.theme === 'light'
                    ? 'lightmode/'
                    : 'darkmode/') +
                  'folder-outline-p1.png'
                }
                className="web-service-auth-permission-icon"
                alt="저장공간 아이콘"
              />
            </div>
            <div className="web-service-auth-permission-item-text-wrap">
              <div className="web-service-auth-permission-item-name">
                저장공간
              </div>
              <div className="web-service-auth-permission-item-info">
                앱 버전 및 OS 위/변조 확인, 파일접근
              </div>
            </div>
          </div>
          <div className="web-service-auth-permission-item">
            <div className="web-service-auth-permission-icon-wrap">
              <img
                src={
                  '/icon/' +
                  (store.getState().theme.theme === 'light'
                    ? 'lightmode/'
                    : 'darkmode/') +
                  'contact-outline-p1.png'
                }
                className="web-service-auth-permission-icon"
                alt="내 연락처 액세스 허용 아이콘"
              />
            </div>
            <div className="web-service-auth-permission-item-text-wrap">
              <div className="web-service-auth-permission-item-name">
                내 연락처 액세스 허용
              </div>
              <div className="web-service-auth-permission-item-info">
                개인주소록을 어플리케이션에서 볼 수 있도록 해주는 권한입니다.
              </div>
            </div>
          </div>
          <div className="web-service-auth-permission-item">
            <div className="web-service-auth-permission-icon-wrap">
              <img
                src={
                  '/icon/' +
                  (store.getState().theme.theme === 'light'
                    ? 'lightmode/'
                    : 'darkmode/') +
                  'camera-outline-p1.png'
                }
                className="web-service-auth-permission-icon"
                alt="사진/동영상 촬영 권한 아이콘"
              />
            </div>
            <div className="web-service-auth-permission-item-text-wrap">
              <div className="web-service-auth-permission-item-name">
                사진/동영상 촬영 권한
              </div>
              <div className="web-service-auth-permission-item-info">
                프로필 사진을 촬영할 때 필요한 권한입니다.
              </div>
            </div>
          </div>
          <div className="web-service-auth-permission-item">
            <div className="web-service-auth-permission-icon-wrap">
              <img
                src={
                  '/icon/' +
                  (store.getState().theme.theme === 'light'
                    ? 'lightmode/'
                    : 'darkmode/') +
                  'sms-outline-p1.png'
                }
                className="web-service-auth-permission-icon"
                alt="SMS 메시지 전송 및 보기 아이콘"
              />
            </div>
            <div className="web-service-auth-permission-item-text-wrap">
              <div className="web-service-auth-permission-item-name">
                SMS 메시지 전송 및 보기
              </div>
              <div className="web-service-auth-permission-item-info">
                문자 메시지 관련 기능을 위한 권한입니다.
              </div>
            </div>
          </div>
          <div className="web-service-auth-permission-item">
            <div className="web-service-auth-permission-icon-wrap">
              <img
                src={
                  '/icon/' +
                  (store.getState().theme.theme === 'light'
                    ? 'lightmode/'
                    : 'darkmode/') +
                  'copy-outline-p1.png'
                }
                className="web-service-auth-permission-icon"
                alt="다른 앱 위에 표시 아이콘"
              />
            </div>
            <div className="web-service-auth-permission-item-text-wrap">
              <div className="web-service-auth-permission-item-name">
                다른 앱 위에 표시
              </div>
              <div className="web-service-auth-permission-item-info">
                허용을 해주시고 좌측 상단의 뒤로 가기 버튼을 클릭하여 화면을
                빠져나가세요.
              </div>
            </div>
          </div>
          <div className="web-service-auth-permission-item">
            <div className="web-service-auth-permission-icon-wrap">
              <img
                src={
                  '/icon/' +
                  (store.getState().theme.theme === 'light'
                    ? 'lightmode/'
                    : 'darkmode/') +
                  'copy-outline-p1.png'
                }
                className="web-service-auth-permission-icon"
                alt="다른 앱 위에 표시 허용 아이콘"
              />
            </div>
            <div className="web-service-auth-permission-item-text-wrap">
              <div className="web-service-auth-permission-item-name">
                다른 앱 위에 표시 허용
              </div>
              <div className="web-service-auth-permission-item-info">
                화면이 절전모드로 잠겼을 때에도 발신자정보 팝업을 출력하는
                권한입니다.
              </div>
            </div>
          </div>
          <div className="web-service-auth-permission-item">
            <div className="web-service-auth-permission-icon-wrap">
              <img
                src={
                  '/icon/' +
                  (store.getState().theme.theme === 'light'
                    ? 'lightmode/'
                    : 'darkmode/') +
                  'call-outline-p1.png'
                }
                className="web-service-auth-permission-icon"
                alt="전화 관리 허용 아이콘"
              />
            </div>
            <div className="web-service-auth-permission-item-text-wrap">
              <div className="web-service-auth-permission-item-name">
                전화 관리 허용
              </div>
              <div className="web-service-auth-permission-item-info">
                어플리케이션이 전화를 걸고 받을 수 있게 해주는 권한입니다.
              </div>
            </div>
          </div>
          <div className="web-service-auth-permission-item">
            <div className="web-service-auth-permission-icon-wrap">
              <img
                src={
                  '/icon/' +
                  (store.getState().theme.theme === 'light'
                    ? 'lightmode/'
                    : 'darkmode/') +
                  'call_interactive-outline-p1.png'
                }
                className="web-service-auth-permission-icon"
                alt="통화기록 액세스 아이콘"
              />
            </div>
            <div className="web-service-auth-permission-item-text-wrap">
              <div className="web-service-auth-permission-item-name">
                통화기록 액세스
              </div>
              <div className="web-service-auth-permission-item-info">
                통화내역을 보고 어플리케이션에 반영하는 기능을 사용하기 위한
                권한입니다.
              </div>
            </div>
          </div>
          <div className="web-service-auth-permission-item">
            <div className="web-service-auth-permission-icon-wrap">
              <img
                src={
                  '/icon/' +
                  (store.getState().theme.theme === 'light'
                    ? 'lightmode/'
                    : 'darkmode/') +
                  'image-outline-p1.png'
                }
                className="web-service-auth-permission-icon"
                alt="파일 가져오기 권한 아이콘"
              />
            </div>
            <div className="web-service-auth-permission-item-text-wrap">
              <div className="web-service-auth-permission-item-name">
                파일 가져오기 권한
              </div>
              <div className="web-service-auth-permission-item-info">
                프로필 사진을 가져올 때 필요하며 그 외 기타 설정을 가져오기 위한
                권한입니다.
              </div>
            </div>
          </div>
        </div>
        <WebServiceBottonSheetPrimaryButton
          isDisabled={false}
          buttonName="접근권한 허용"
        />
      </WebServiceNormalBody>
    </WebServiceWrapper>
  );
};

export default WebServiceAuthPermission;
