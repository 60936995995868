/* eslint-disable operator-linebreak */
import React, { useEffect, useState } from 'react';

import 'stylesheets/web_service/nav.scss';
import { Link, useLocation } from 'react-router-dom';

import routes from 'configs/routes';

const WebServiceSideNav = () => {
  const { pathname } = useLocation();

  const [isDial, setIsDial] = useState(false);
  const [isCallLog, setIsCallLog] = useState(false);
  const [isSMS, setIsSMS] = useState(false);
  const [isMessenger, setIsMessenger] = useState(false);
  const [isContact, setIsContact] = useState(false);
  const [isMore, setIsMore] = useState(false);

  useEffect(() => {
    setIsDial(pathname === routes.web_service_dial);
    setIsCallLog(pathname === routes.web_service_call_log);
    setIsSMS(pathname === routes.web_service_sms);
    setIsMessenger(pathname === routes.web_service_messenger);
    setIsContact(pathname === routes.web_service_contact);
    setIsMore(pathname === routes.web_service_more);
  }, [pathname]);

  const isNavActive = (isActive: boolean) => (isActive ? 'active' : '');

  return (
    <div className="web-service-nav-container side-nav">
      <Link to={routes.web_service_dial}>
        <div
          className={`web-service-nav-btn-set ${isNavActive(isDial)}`}
          id="web-service-nav-dial"
        >
          <div className="web-service-nav-btn-icon-wrap">
            <div className="web-service-nav-btn-icon"></div>
            <div className="web-service-nav-btn-icon active"></div>
          </div>
          <div className="web-service-nav-btn-text-wrap">전화걸기</div>
        </div>
      </Link>
      <Link to={routes.web_service_call_log}>
        <div
          className={`web-service-nav-btn-set ${isNavActive(isCallLog)}`}
          id="web-service-nav-call-log"
        >
          <div className="web-service-nav-btn-icon-wrap">
            <div className="web-service-nav-btn-icon"></div>
            <div className="web-service-nav-btn-icon active"></div>
          </div>
          <div className="web-service-nav-btn-text-wrap">최근 통화</div>
        </div>
      </Link>
      <Link to={routes.web_service_sms}>
        <div
          className={`web-service-nav-btn-set ${isNavActive(isSMS)}`}
          id="web-service-nav-sms"
        >
          <div className="web-service-nav-btn-icon-wrap">
            <div className="web-service-nav-btn-icon"></div>
            <div className="web-service-nav-btn-icon active"></div>
          </div>
          <div className="web-service-nav-btn-text-wrap">문자</div>
        </div>
      </Link>
      <Link to={routes.web_service_messenger}>
        <div
          className={`web-service-nav-btn-set ${isNavActive(isMessenger)}`}
          id="web-service-nav-messenger"
        >
          <div className="web-service-nav-btn-icon-wrap">
            <div className="web-service-nav-btn-icon"></div>
            <div className="web-service-nav-btn-icon active"></div>
          </div>
          <div className="web-service-nav-btn-text-wrap">메신저</div>
        </div>
      </Link>
      <Link to={routes.web_service_contact}>
        <div
          className={`web-service-nav-btn-set ${isNavActive(isContact)}`}
          id="web-service-nav-contact"
        >
          <div className="web-service-nav-btn-icon-wrap">
            <div className="web-service-nav-btn-icon"></div>
            <div className="web-service-nav-btn-icon active"></div>
          </div>
          <div className="web-service-nav-btn-text-wrap">연락처</div>
        </div>
      </Link>
      <Link to={routes.web_service_more}>
        <div
          className={`web-service-nav-btn-set ${isNavActive(isMore)}`}
          id="web-service-nav-more"
        >
          <div className="web-service-nav-btn-icon-wrap">
            <div className="web-service-nav-btn-icon"></div>
            <div className="web-service-nav-btn-icon active"></div>
          </div>
          <div className="web-service-nav-btn-text-wrap fs-14 fw-500">
            더보기
          </div>
        </div>
      </Link>
    </div>
  );
};

export default WebServiceSideNav;
