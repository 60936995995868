import React from 'react';

import getMainNavItems from 'utils/customs/get_main_nav_items';

import 'stylesheets/main_nav.scss';

import MainNavItem from './item';

const MainNav = () => {
  return (
    <div className="main-nav-container">
      {getMainNavItems().map((v) => (
        <MainNavItem
          key={v.menuLabel}
          menuLabel={v.menuLabel}
          iconUrl={v.iconUrl.replace('theme', 'dark')}
          link={v.link}
        />
      ))}
    </div>
  );
};

export default MainNav;
