/* eslint-disable react/prop-types */
/* eslint-disable operator-linebreak */
import React from 'react';
import 'stylesheets/web_service/contact.scss';
import WebServiceCheckbox from '../input/checkbox';

const WebServiceContactMemo = (props: any) => {
  const { text, date, checkMode, isChecked, onClick } = props;
  return (
    <div className="web-service-memo-card" onClick={onClick}>
      {checkMode ? <WebServiceCheckbox isChecked={isChecked} /> : ''}
      <div className="web-service-memo-card-text-wrap">
        <div className="web-service-memo-card-text">{text}</div>
        <div className="web-service-memo-card-time">{date}</div>
      </div>
    </div>
  );
};

export default WebServiceContactMemo;
