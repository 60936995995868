/* eslint-disable max-len */
import WebServiceNormalBody from 'components/web_service/body/normal_body';
import WebServiceBackTextHeader from 'components/web_service/header/back_text';
import WebServiceInput from 'components/web_service/input/input';
import WebServiceWrapper from 'components/web_service_wrapper';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const WebServiceMyProfile = () => {
  const navigate = useNavigate();
  const name = '홍길동';

  return (
    <WebServiceWrapper hideNav={false} isCalling={false} scrollLock={false}>
      <WebServiceBackTextHeader
        title="내 프로필"
        rightButtonText="저장"
        rightOnClick={() => navigate(-1)}
      />
      <WebServiceNormalBody type="normal" background="" useOverlay={false}>
        <div className="web-service-contact-profile-head">
          <img
            src="/img/profile/profile.jpg"
            className="web-service-contact-profile-img"
            alt="profile image"
          />
          <div className="web-service-contact-profile-name-wrap">
            <div className="web-service-contact-profile-name">{name}</div>
            <div className="web-service-contact-profile-subinfo">
              <div className="web-service-contact-profile-position">대리</div>
              <div className="web-service-contact-profile-subinfo-dot">·</div>
              <div className="web-service-contact-profile-company">
                가나다주식회사 경영지원팀
              </div>
            </div>
          </div>
        </div>
        <div className="web-service-my-profile-input-wrap">
          <WebServiceInput
            hasLabel={true}
            label="휴대전화 번호"
            inputType="text"
            placeholder="휴대전화 번호"
          />
          <WebServiceInput
            hasLabel={true}
            label="유선전화 번호"
            inputType="text"
            placeholder="유선전화 번호"
          />
        </div>
      </WebServiceNormalBody>
    </WebServiceWrapper>
  );
};

export default WebServiceMyProfile;
