/* eslint-disable indent */
import React from 'react';

import WebServiceWrapper from 'components/web_service_wrapper';
import WebServiceNormalBody from 'components/web_service/body/normal_body';
import WebServiceCenterTitleHeader from 'components/web_service/header/center_title';
import WebServiceMoreCard from 'components/more/card';
import { useDispatch, useSelector } from 'react-redux';
import { useChangeTheme } from 'redux/theme_reducer';
import { Theme } from 'configs/enum';

const WebServiceMoreTheme = () => {
  const dispatch = useDispatch();
  const { theme }: any = useSelector((state) => state);
  return (
    <WebServiceWrapper hideNav={false} isCalling={false} scrollLock={false}>
      <WebServiceCenterTitleHeader title="착신 전환 설정" />
      <WebServiceNormalBody type="more-78" useOverlay={false} background="">
        <WebServiceMoreCard
          type="radioOnly"
          name="라이트모드"
          bottomBorder={true}
          isRadioOn={theme.theme === 'light'}
          onClick={() => dispatch(useChangeTheme(Theme.light))}
        />
        <WebServiceMoreCard
          type="radioOnly"
          name="다크모드"
          bottomBorder={false}
          isRadioOn={theme.theme === 'dark'}
          onClick={() => dispatch(useChangeTheme(Theme.dark))}
        />
      </WebServiceNormalBody>
    </WebServiceWrapper>
  );
};

export default WebServiceMoreTheme;
