/* eslint-disable max-len */
import WebServiceNormalBody from 'components/web_service/body/normal_body';
import WebServicePrimaryButton from 'components/web_service/button/primary_button';
import WebServiceCenterTitleHeader from 'components/web_service/header/center_title';
import WebServiceInput from 'components/web_service/input/input';
import WebServiceOkModal from 'components/web_service/modal/ok_modal';
import WebServiceWrapper from 'components/web_service_wrapper';
import React, { useState } from 'react';
import store from 'redux/store';

const WebServiceChangePassword = () => {
  const [certificationComplete, setCertificationComplete] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);

  if (certificationComplete && !passwordChanged) {
    return (
      <WebServiceChangePasswordNewPassword
        setPasswordChanged={setPasswordChanged}
      />
    );
  } else if (certificationComplete && passwordChanged) {
    return <WebServiceChangePasswordComplete />;
  } else {
    return (
      <WebServiceChangePasswordCertification
        setCertificationComplete={setCertificationComplete}
      />
    );
  }
};

const WebServiceChangePasswordCertification = (props: any) => {
  const { setCertificationComplete } = props;
  const [mobileNumber, setMobileNumber] = useState('');
  const [sendCode, setSendCode] = useState(false);
  const [certificationNumber, setCertificationNumber] = useState('');

  const certificationNumberSubmit = () => {
    if (certificationNumber.length > 7) {
      setCertificationComplete(true);
    }
  };

  const codeSendHandleChange = () => {
    if (mobileNumber.length > 9) {
      setSendCode(true);
    }
  };

  const inputMobileHandleChange = (e: any) => {
    setMobileNumber(e.target.value);
  };

  const inputCertificateHandleChange = (e: any) => {
    setCertificationNumber(e.target.value);
  };

  return (
    <WebServiceWrapper hideNav={false} isCalling={false} scrollLock={false}>
      <WebServiceCenterTitleHeader title="비밀번호 변경" />
      <WebServiceNormalBody type="normal" useOverlay={false} background="">
        <div className="web-service-script-wrap">
          <div className="web-service-script-title">
            휴대전화 번호를 입력해주세요.
          </div>
          <div className="web-service-script-text">
            본인 인증을 위해 해당 번호로 인증번호가 발송됩니다. 인증번호가 받지
            못한 경우 &apos;재발송&apos; 버튼을 터치해 주세요.
          </div>
        </div>
        <div className="web-service-change-password-input-wrap">
          <WebServiceInput
            hasLabel={true}
            label="휴대전화번호"
            placeholder="휴대전화번호"
            type="innerButton"
            inputType="tel"
            inputValue={mobileNumber}
            onChange={inputMobileHandleChange}
            onInnerButtonClick={codeSendHandleChange}
            innerButtonName="인증코드 전송"
          />
          {sendCode ? (
            <WebServiceInput
              hasLabel={true}
              label="인증번호"
              placeholder="인증번호"
              type="timer"
              inputType="tel"
              inputValue={certificationNumber}
              onChange={inputCertificateHandleChange}
            />
          ) : null}
          {sendCode ? (
            <div className="web-service-change-password-btn-wrap">
              <WebServicePrimaryButton
                buttonName={
                  certificationNumber.length < 8
                    ? '인증번호를 입력하세요'
                    : '확인'
                }
                isDisabled={certificationNumber.length < 8}
                onClick={certificationNumberSubmit}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      </WebServiceNormalBody>
    </WebServiceWrapper>
  );
};

const WebServiceChangePasswordNewPassword = (props: any) => {
  const { setPasswordChanged } = props;
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [openModal, setOpenModal] = useState(false);

  const passwordSubmit = () => {
    if (
      password === passwordRepeat &&
      password.length > 7 &&
      passwordRepeat.length > 7
    ) {
      setPasswordChanged(true);
    } else if (
      password !== passwordRepeat &&
      password.length > 7 &&
      passwordRepeat.length > 7
    ) {
      setOpenModal(true);
    }
  };

  const passwordHandleChange = (e: any) => {
    setPassword(e.target.value);
  };

  const passwordRepeatHandleChange = (e: any) => {
    setPasswordRepeat(e.target.value);
  };

  return (
    <WebServiceWrapper hideNav={false} isCalling={false} scrollLock={false}>
      <WebServiceCenterTitleHeader title="비밀번호 변경" />
      <WebServiceNormalBody type="normal" useOverlay={false} background="">
        <div className="web-service-script-wrap">
          <div className="web-service-script-title">
            재설정할 비밀번호를 입력해주세요
          </div>
          <div className="web-service-script-text">
            비밀번호는 8자이상의 영어, 숫자, 특수문자로 설정가능합니다.
          </div>
        </div>
        <div className="web-service-change-password-input-wrap">
          <WebServiceInput
            hasLabel={true}
            label="변경할 비밀번호"
            placeholder="변경할 비밀번호"
            inputType="password"
            inputValue={password}
            onChange={passwordHandleChange}
          />
          <WebServiceInput
            hasLabel={true}
            label="비밀번호 재입력"
            placeholder="비밀번호 재입력"
            inputType="password"
            inputValue={passwordRepeat}
            onChange={passwordRepeatHandleChange}
          />

          <div className="web-service-change-password-btn-wrap">
            <WebServicePrimaryButton
              buttonName={
                password.length < 8 || passwordRepeat.length < 8
                  ? '변경할 비밀번호를 입력하세요'
                  : '변경'
              }
              isDisabled={password.length < 8 || passwordRepeat.length < 8}
              onClick={passwordSubmit}
            />
          </div>
        </div>
      </WebServiceNormalBody>
      <WebServiceOkModal
        title="입력 오류"
        text="비밀번호와 비밀번호 재입력이 일치하지 않습니다."
        buttonName="확인"
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
    </WebServiceWrapper>
  );
};

const WebServiceChangePasswordComplete = () => {
  return (
    <WebServiceWrapper hideNav={false} isCalling={false} scrollLock={false}>
      <WebServiceNormalBody type="normal" useOverlay={false} background="">
        <div className="web-service-change-password-complete-wrap">
          <img
            src={
              '/icon/' +
              (store.getState().theme.theme === 'light'
                ? 'lightmode/'
                : 'darkmode/') +
              'check_circle-outline-p1.png'
            }
            className="web-service-change-password-complete-img"
            alt="password changed"
          />
          <div className="web-service-change-password-complete-script-wrap">
            <div className="web-service-script-title">
              비밀번호가 재설정되었습니다.
            </div>
            <div className="web-service-script-text">
              변경된 비밀번호로 다시 로그인해주세요.
            </div>
          </div>
        </div>
        <div className="web-service-change-password-complete-btn-wrap">
          <WebServicePrimaryButton
            buttonName="로그인"
            link="/app/web_service/login"
          />
        </div>
      </WebServiceNormalBody>
    </WebServiceWrapper>
  );
};
export default WebServiceChangePassword;
