import React from 'react';

import MainPageWrapper from 'components/main_page_wrapper';

const MainContact = () => {
  return (
    <MainPageWrapper>
      <div></div>
    </MainPageWrapper>
  );
};

export default MainContact;
