/* eslint-disable object-curly-spacing */
/* eslint-disable indent */
/* eslint-disable operator-linebreak */
import React from 'react';
import store from 'redux/store';
import 'stylesheets/web_service/no_result.scss';
import WebServicePrimaryButton from '../button/primary_button';

const WebServiceNoResult = (props: any) => {
  const { icon, text, isButton, buttonName } = props;
  return (
    <div className="web-service-no-result-wrap">
      <div className="web-service-no-result-body">
        <div className="web-service-no-result-img-wrap">
          <img
            src={`
            /icon/${
              store.getState().theme.theme === 'light'
                ? 'lightmode/'
                : 'darkmode/'
            }${icon}
          `}
            className="web-service-no-result-img"
            alt="No Result"
          />
        </div>
        <div className="web-service-no-result-text">{text}</div>
      </div>
      {isButton ? (
        <div className="web-service-no-result-btn-wrap">
          <WebServicePrimaryButton buttonName={buttonName} isDisabled={false} />
        </div>
      ) : null}
    </div>
  );
};

export default WebServiceNoResult;
