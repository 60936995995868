import mainNavItems from 'configs/main_nav_items';

const getMainNavItems = () => {
  return [
    mainNavItems.dial,
    mainNavItems.call_log,
    mainNavItems.sms,
    mainNavItems.contact,
    mainNavItems.more
  ];
};

export default getMainNavItems;
