import React, { MutableRefObject, useEffect, useRef, useState } from 'react';

const WebServiceImgShow = (props: any) => {
  const { setShowImg, imgData, showImgIdx, setShowImgIdx } = props;
  const targetImg = useRef() as MutableRefObject<HTMLImageElement>;
  const [loaded, setLoaded] = useState(false);

  const changeIndex = (idx: number) => {
    if (imgData.length > idx + 1 && idx > -1) {
      setShowImgIdx(idx);
    }
  };

  useEffect(() => {
    targetImg.current.className = 'web-service-img';
    setTimeout(() => {
      if (targetImg.current.width > targetImg.current.height) {
        targetImg.current.className = 'web-service-img width';
        setLoaded(true);
      } else if (targetImg.current.width < targetImg.current.height) {
        targetImg.current.className = 'web-service-img height';
        setLoaded(true);
      }
    }, 100);
  }, [targetImg, showImgIdx]);

  useEffect(() => {
    console.log('loaded');
    if (targetImg.current.className === 'web-service-img width') {
      targetImg.current.className = 'web-service-img width display';
    } else if (targetImg.current.className === 'web-service-img height') {
      targetImg.current.className = 'web-service-img height display';
    }
    setLoaded(false);
  }, [loaded]);

  return (
    <div className="web-service-show-img-wrap-fixed">
      <div className="web-service-show-img-wrap-relative">
        <div className="web-service-show-img-container">
          <div className="web-service-show-img-menu-container">
            <div className="web-service-show-img-button-set-wrap">
              <div className="web-service-show-img-button-wrap">
                <img
                  src={'/icon/common/close.png'}
                  className="web-service-show-img-icon"
                  alt="close"
                  onClick={() => setShowImg(false)}
                />
              </div>
              <div className="web-service-show-img-button-area" />
            </div>
            <div className="web-service-show-img-info-wrap">
              <div className="web-service-show-img-title">
                {imgData[showImgIdx].writer}
              </div>
              <div className="web-service-show-img-info">
                {imgData[showImgIdx].info}
              </div>
            </div>
            <div className="web-service-show-img-button-set-wrap">
              <div className="web-service-show-img-button-wrap top">
                <img
                  src={'/icon/common/download.png'}
                  className="web-service-show-img-icon"
                  alt="download"
                />
              </div>
              <div className="web-service-show-img-button-wrap delete top">
                <img
                  src={'/icon/common/delete.png'}
                  className="web-service-show-img-icon"
                  alt="delete"
                />
              </div>
            </div>
          </div>
          <div className="web-service-show-img-wrap">
            <img
              src={'/img/photo/' + imgData[showImgIdx].thumbnail}
              className="web-service-img"
              alt="photo"
              ref={targetImg}
            />
          </div>
          <div className="web-service-show-img-bottom">
            <div className="web-service-show-img-button-wrap bottom">
              <img
                src={'/icon/common/download.png'}
                className="web-service-show-img-icon"
                alt="download"
              />
            </div>
            <div className="web-service-show-img-button-wrap delete bottom">
              <img
                src={'/icon/common/delete.png'}
                className="web-service-show-img-icon"
                alt="delete"
              />
            </div>
            <div
              className="web-service-show-img-arrow-wrap"
              onClick={() => changeIndex(showImgIdx - 1)}
            >
              <img
                src={'/icon/common/arrow-left.png'}
                className="web-service-show-img-arrow left"
                alt="show before image"
              />
            </div>
            <div className="web-service-show-img-index">
              {showImgIdx + 1}/{imgData.length}
            </div>
            <div
              className="web-service-show-img-arrow-wrap"
              onClick={() => changeIndex(showImgIdx + 1)}
            >
              <img
                src={'/icon/common/arrow-right.png'}
                className="web-service-show-img-arrow right"
                alt="show next image"
              />
            </div>
          </div>
        </div>
        <div className="web-service-show-img-wrap-overlay" />
      </div>
    </div>
  );
};

export default WebServiceImgShow;
