/* eslint-disable max-len */
/* eslint-disable object-curly-spacing */
/* eslint-disable indent */
/* eslint-disable operator-linebreak */
import React, { useState } from 'react';
import 'stylesheets/web_service/search.scss';
import WebServiceCheckbox from '../input/checkbox';
import WebServiceSearchResultCardCallHistoryButton from './result_card_call_history_button';
import store from 'redux/store';
import WebServiceProfileImg from '../profile/profile_img';

const WebServiceSearchResultCardContact = (props: any) => {
  const [isSpread, setIsSpread] = useState(false);
  const {
    type,
    name,
    number,
    padding,
    profile,
    canSpread,
    hoverEffect,
    isFirst,
    onClick,
    isChecked,
    state,
    inputValue,
    equalNameStart,
    equalNumberStart,
    hypen
  } = props;

  const handleChange = () => {
    if (canSpread) {
      setIsSpread(!isSpread);
    }
  };

  const calcHypen = (
    equalNumberStart: number,
    inputValue: string,
    idx: number
  ) => {
    // 1. '-'이 2개 포함된 경우
    // 1. 첫번째 '-' 이전에 일치하는 검색어의 첫 숫자가 있을 때
    if (hypen?.length === 2 && equalNumberStart < hypen[0]) {
      if (
        equalNumberStart + inputValue.length <= hypen[0] &&
        idx < hypen[0] &&
        idx < inputValue.length + equalNumberStart &&
        idx >= equalNumberStart
      ) {
        return ' equal';
      } else if (
        equalNumberStart + inputValue.length > hypen[0] &&
        equalNumberStart + inputValue.length <= hypen[1] &&
        idx <= hypen[1] + 1 &&
        idx < inputValue.length + equalNumberStart + 1 &&
        idx >= equalNumberStart
      ) {
        return ' equal';
      } else if (
        equalNumberStart + inputValue.length > hypen[1] &&
        idx < inputValue.length + equalNumberStart + 2 &&
        idx >= equalNumberStart
      ) {
        return ' equal';
      } else return '';
      // 1. 첫번째 '-' 과 두번째 '-' 사이에 일치하는 첫 숫자가 있을 때
    } else if (
      hypen?.length === 2 &&
      equalNumberStart >= hypen[0] &&
      equalNumberStart < hypen[1]
    ) {
      if (
        equalNumberStart + inputValue.length > hypen[0] &&
        equalNumberStart + inputValue.length <= hypen[1] &&
        idx <= hypen[1] + 1 &&
        idx < inputValue.length + equalNumberStart + 1 &&
        idx > equalNumberStart
      ) {
        return ' equal';
      } else if (
        equalNumberStart + inputValue.length > hypen[1] &&
        idx < inputValue.length + equalNumberStart + 2 &&
        idx > equalNumberStart
      ) {
        return ' equal';
      } else return '';
      // 1. 두번째 '-' 이후에 일치하는 첫 숫자가 있을 때
    } else if (hypen?.length === 2 && equalNumberStart >= hypen[1]) {
      if (
        equalNumberStart + inputValue.length > hypen[1] &&
        idx < inputValue.length + equalNumberStart + 2 &&
        idx > equalNumberStart + 1
      ) {
        return ' equal';
      } else return '';
      // 2. '-'이 1개 포함된 경우
      // 2. 첫번째 '-' 이전에 일치하는 검색어의 첫 숫자가 있을 때
    } else if (hypen?.length === 1 && equalNumberStart < hypen[0]) {
      if (
        equalNumberStart + inputValue.length <= hypen[0] &&
        idx < hypen[0] &&
        idx < inputValue.length + equalNumberStart &&
        idx >= equalNumberStart
      ) {
        return ' equal';
      } else if (
        equalNumberStart + inputValue.length > hypen[0] &&
        idx < inputValue.length + equalNumberStart + 1 &&
        idx >= equalNumberStart
      ) {
        return ' equal';
      } else return '';
      // 2. '-'이 1개 포함된 경우
      // 2. 첫번째 '-' 이후에 일치하는 검색어의 첫 숫자가 있을 때
    } else if (hypen?.length === 1 && equalNumberStart >= hypen[0]) {
      if (
        equalNumberStart + inputValue.length > hypen[0] &&
        idx < inputValue.length + equalNumberStart + 1 &&
        idx >= equalNumberStart + 1
      ) {
        return ' equal';
      } else return '';
    } else return '';
  };

  return (
    <div
      className={
        'web-service-search-result-card-contact-wrap' +
        (padding ? ' padding' : ' noPadding') +
        (canSpread && !isSpread ? ' fold' : '') +
        (hoverEffect ? ' hover' : '')
      }
    >
      <div
        className={
          'web-service-search-result-card-contact' + (isFirst ? ' first' : '')
        }
        onClick={onClick}
      >
        <div
          className="web-service-search-result-card-contact-body"
          onClick={handleChange}
        >
          <WebServiceProfileImg profile={profile} state={state} size="small" />
          <div className="web-service-search-result-card-contact-info-wrap">
            <div className="web-service-search-result-card-contact-name-wrap">
              {name.split('').map((item: any, idx: number) => (
                <div
                  key={idx}
                  className={
                    'web-service-search-result-card-contact-name' +
                    (idx >= equalNameStart &&
                    idx < equalNameStart + inputValue.length
                      ? ' equal'
                      : '')
                  }
                >
                  {item}
                </div>
              ))}
            </div>
            <div className="web-service-search-result-card-contact-number-wrap">
              {number.split('').map((item: any, idx: number) => (
                <div
                  key={idx}
                  className={
                    'web-service-search-result-card-contact-number' +
                    calcHypen(equalNumberStart, inputValue, idx)
                  }
                  // (idx >= equalNumberStart &&
                  // idx < equalNumberStart + inputValue.length
                  //   ? ' equal'
                  //   : '')
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
          {type === 'withCheckbox' ? (
            <WebServiceCheckbox isChecked={isChecked} />
          ) : null}
          {type === 'withAddButton' ? (
            <div className="web-service-search-result-card-contact-add-icon">
              <img
                src={
                  '/icon/' +
                  (store.getState().theme.theme === 'light'
                    ? 'lightmode/'
                    : 'darkmode/') +
                  'plus-t2.png'
                }
                alt="add"
              />
              추가
            </div>
          ) : null}
        </div>
        <div
          className={
            'web-service-search-result-card-contact-spreadarea' +
            (isSpread ? ' spread' : '')
          }
        >
          <WebServiceSearchResultCardCallHistoryButton
            imgName="call"
            text="통화"
          />
          <WebServiceSearchResultCardCallHistoryButton
            imgName="sms"
            text="문자"
          />
          <WebServiceSearchResultCardCallHistoryButton
            imgName="memo_file"
            text="메모"
          />
          <WebServiceSearchResultCardCallHistoryButton
            imgName="person"
            text="프로필"
            link="/app/web_service/contact/profile"
          />
        </div>
      </div>
    </div>
  );
};

export default WebServiceSearchResultCardContact;
