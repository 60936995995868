/* eslint-disable max-len */
import WebServiceMoreCard from 'components/more/card';
import WebServiceNormalBody from 'components/web_service/body/normal_body';
import WebServiceCenterTitleHeader from 'components/web_service/header/center_title';
import WebServiceWrapper from 'components/web_service_wrapper';
import React from 'react';
import { useKorean, useJapanese, useEnglish } from 'redux/lang_reducer';
import { Language } from 'configs/enum';
import { useDispatch, useSelector } from 'react-redux';

const WebServiceMoreLanguage = () => {
  const dispatch = useDispatch();
  const { lang }: any = useSelector((state) => state);
  return (
    <WebServiceWrapper hideNav={false} isCalling={false} scrollLock={false}>
      <WebServiceCenterTitleHeader title="언어 설정" />
      <WebServiceNormalBody type="more-78" background="" useOverlay={false}>
        <WebServiceMoreCard
          type="radioOnly"
          name="한국어"
          isRadioOn={lang.lang === Language.kr}
          onClick={() => dispatch(useKorean())}
        />
        <WebServiceMoreCard
          type="radioOnly"
          name="日本語"
          isRadioOn={lang.lang === Language.jp}
          onClick={() => dispatch(useJapanese())}
        />
        <WebServiceMoreCard
          type="radioOnly"
          name="English"
          isRadioOn={lang.lang === Language.en}
          onClick={() => dispatch(useEnglish())}
        />
      </WebServiceNormalBody>
    </WebServiceWrapper>
  );
};

export default WebServiceMoreLanguage;
