/* eslint-disable object-curly-spacing */
/* eslint-disable indent */
/* eslint-disable operator-linebreak */
import React from 'react';
import 'stylesheets/web_service/sms.scss';
import WebServiceCheckbox from '../input/checkbox';
import WebServiceProfileImg from '../profile/profile_img';

const WebServiceMessengerListCard = (props: any) => {
  const {
    name,
    text,
    date,
    alarmAmount,
    editMode,
    isChecked,
    onClick,
    underline,
    profile,
    state
  } = props;

  return (
    <div
      className={'web-service-sms-list-card' + (underline ? ' underline' : '')}
      onClick={onClick}
    >
      {editMode ? (
        <WebServiceCheckbox
          isLabel={false}
          isChecked={isChecked}
          hoverEffect={false}
        />
      ) : null}
      <WebServiceProfileImg size="medium" profile={profile} state={state} />
      <div className="web-service-sms-list-main-field">
        <div className="web-service-sms-list-name">{name}</div>
        <div className="web-service-sms-list-text">{text}</div>
      </div>
      <div className="web-service-sms-list-card-sub-field">
        <div className="web-service-sms-list-date">{date}</div>
        {alarmAmount > 0 ? (
          <div className="web-service-sms-list-alarm">{alarmAmount}</div>
        ) : null}
      </div>
    </div>
  );
};

export default WebServiceMessengerListCard;
