/* eslint-disable max-len */
import WebServiceNormalBody from 'components/web_service/body/normal_body';
import WebServiceWrapper from 'components/web_service_wrapper';
import WebServiceDialPad from 'components/web_service/dial/dial_pad';
import WebServiceBottomNav from 'components/web_service/nav/bottom';
import WebServiceSearchHeader from 'components/web_service/header/search';
import React, { useEffect, useState } from 'react';
import WebServiceSearchTabGroup from 'components/web_service/search/dial/search_tab_group';
import { ContactListItems, CallLogListItems } from 'configs/items';
import WebServiceDialHeader from 'components/web_service/header/dial';

const WebServiceDial = () => {
  const [searchMod, setSearchMod] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [receiverName, setReceiverName] = useState<string | undefined>('');
  const [equalList, setEqualList] = useState<any[]>([]);

  const handleChange = (e: any) => {
    setSearchValue(e.target.value);
  };

  const onReset = () => {
    setSearchValue('');
  };

  const onCardClick = (n: string) => {
    setSearchMod(false);
    setInputValue(n);
  };

  const inputValueChange = (e: any) => {
    const regex = /^[0-9\b -]{0,50}$/;

    if (regex.test(e.target.value)) {
      setInputValue(e.target.value);
    }
  };

  useEffect(() => {
    setEqualList([]);
    let checkEqual: any = [];
    ContactListItems.forEach((item: any, idx: number) => {
      if (
        item.name.indexOf(searchValue) > -1 &&
        item.number.indexOf(searchValue) === -1
      ) {
        checkEqual = [...checkEqual, Object.assign(item, { seq: idx })];
        for (let i = 0; i < searchValue.length; i++) {
          item.equalNameStart = item.name.indexOf(searchValue);
        }
      } else if (
        item.name.indexOf(searchValue) === -1 &&
        item.number.indexOf(searchValue) > -1
      ) {
        checkEqual = [...checkEqual, item];
        for (let i = 0; i < searchValue.length; i++) {
          item.equalNumberStart = item.number.indexOf(searchValue);
        }
      }
    });
    setEqualList(checkEqual);
  }, [searchValue]);

  useEffect(() => {
    const text = inputValue.replace(/-/g, '');
    setInputValue(text);

    if (text.length > 8) {
      if (text.length === 9) {
        setInputValue(text.replace(/(\d{2})(\d{3})(\d{4})/, '$1-$2-$3'));
      } else if (text.length === 10 && text.substring(0, 2) === '02') {
        setInputValue(text.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3'));
      } else if (text.length === 10 && text.substring(0, 2) !== '02') {
        setInputValue(text.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'));
      } else if (text.length === 11) {
        setInputValue(text.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'));
      }
    }
    setReceiverName(
      ContactListItems.find(({ number }) => number === inputValue)?.name
    );
  }, [inputValue]);

  return (
    <WebServiceWrapper hideNav={false} isCalling={false} scrollLock={false}>
      {searchMod ? (
        <WebServiceSearchHeader
          onCancel={() => setSearchMod(false)}
          onSearchValueChange={handleChange}
          onSearchValueRemove={onReset}
          searchValue={searchValue}
        />
      ) : (
        <WebServiceDialHeader
          title="전화걸기"
          onButtonClick={() => setSearchMod(true)}
        />
      )}
      <WebServiceNormalBody
        type={searchMod ? 'search' : 'normal'}
        useOverlay={false}
        background=""
      >
        {searchMod ? (
          <WebServiceSearchTabGroup
            searchValue={searchValue}
            from="dial"
            dataFirst={ContactListItems}
            dataSecond={CallLogListItems}
            tabName1="연락처"
            tabName2="통화기록"
            onCardClick={onCardClick}
            equalList={equalList}
          />
        ) : (
          <WebServiceDialPad
            inputValue={inputValue}
            setInputValue={setInputValue}
            inputValueChange={inputValueChange}
            receiverName={receiverName}
          />
        )}
      </WebServiceNormalBody>
      <WebServiceBottomNav />
    </WebServiceWrapper>
  );
};

export default WebServiceDial;
