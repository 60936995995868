// import React from "react";
// import ReactDOM from "react-dom";
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en';
import jp from './locales/jp';
import kr from './locales/kr';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: en,
      kr: kr,
      jp: jp
    },
    lng: 'en',
    // if you're using a language detector, do not define the lng option
    fallbackLng: 'en',
    ns: ['page'],
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
