/* eslint-disable operator-linebreak */
import React from 'react';

import 'stylesheets/web_service/nav.scss';

const WebServiceMoreHeader = (props: any) => {
  const { title } = props;

  return (
    <div className="web-service-left-title-header-container">
      <div className="web-service-left-title-header-left">
        <div className="web-service-left-title-header-title">{title}</div>
      </div>
    </div>
  );
};

export default WebServiceMoreHeader;
