/* eslint-disable max-len */
/* eslint-disable indent */
import WebServiceImgShow from 'components/img_show/img_show';
import WebServiceNormalBody from 'components/web_service/body/normal_body';
import WebServiceSubTitle from 'components/web_service/content_title/sub_title';
import WebServiceBottomFixedPrimaryButton from 'components/web_service/fixed_component/primary_button';
import WebServiceCenterTitleHeader from 'components/web_service/header/center_title';
import WebServiceMessengerImgInStorage from 'components/web_service/messenger/img_in_storage';
import WebServiceWrapper from 'components/web_service_wrapper';
import React, { useState } from 'react';

const WebServiceMessengerChatImgStorage = (props: any) => {
  const { showImgStorage, setShowImgStorage } = props;
  const [selectMode, setSelectMode] = useState(false);
  const [isChecked, setIsChecked] = useState<number[]>([]);
  const [showImg, setShowImg] = useState(false);
  const [showImgIdx, setShowImgIdx] = useState<number>();
  const imgData = [
    {
      writer: '홍길동',
      info: '2022. 12. 05.  오후 05:44',
      thumbnail: 'photo2.jpg'
    },
    {
      writer: '홍길동',
      info: '2022. 12. 05.  오후 05:44',
      thumbnail: 'photo2.jpg'
    },
    {
      writer: '홍길동',
      info: '2022. 12. 05.  오후 05:44',
      thumbnail: 'photo3.jpg'
    },
    {
      writer: '홍길동',
      info: '2022. 12. 05.  오후 05:44',
      thumbnail: 'photo4.jpg'
    },
    {
      writer: '홍길동',
      info: '2022. 12. 05.  오후 05:44',
      thumbnail: 'photo1.jpg'
    },
    {
      writer: '홍길동',
      info: '2022. 12. 05.  오후 05:44',
      thumbnail: 'photo2.jpg'
    },
    {
      writer: '홍길동',
      info: '2022. 12. 05.  오후 05:44',
      thumbnail: 'photo3.jpg'
    },
    {
      writer: '홍길동',
      info: '2022. 12. 05.  오후 05:44',
      thumbnail: 'photo4.jpg'
    },
    {
      writer: '홍길동',
      info: '2022. 12. 05.  오후 05:44',
      thumbnail: 'photo1.jpg'
    },
    {
      writer: '홍길동',
      info: '2022. 12. 05.  오후 05:44',
      thumbnail: 'photo2.jpg'
    },
    {
      writer: '홍길동',
      info: '2022. 12. 05.  오후 05:44',
      thumbnail: 'photo3.jpg'
    },
    {
      writer: '홍길동',
      info: '2022. 12. 05.  오후 05:44',
      thumbnail: 'photo4.jpg'
    }
  ];

  const showImgChange = (idx: number) => {
    setShowImg(true);
    setShowImgIdx(idx);
  };

  const checkboxHandleChange = (idx: number) => {
    if (selectMode) {
      if (isChecked.includes(idx)) {
        const activeList = isChecked.filter((i) => idx !== i);
        setIsChecked(activeList);
      } else {
        setIsChecked([...isChecked, idx]);
      }
    }
  };

  return (
    <WebServiceWrapper hideNav={false} isCalling={false} scrollLock={''}>
      <WebServiceCenterTitleHeader
        title="이미지 전체보기"
        rightIcon={!selectMode ? 'delete-outline-t1' : null}
        rightText={selectMode ? '취소' : null}
        rightOnClick={() => setSelectMode(!selectMode)}
        backState={showImgStorage}
        setBackState={setShowImgStorage}
        leftIcon="close"
      />
      <WebServiceNormalBody
        type="normal"
        useOverlay={showImg ? true : false}
        background=""
      >
        <WebServiceSubTitle title="2022. 12. 04" />
        <div className="web-service-messenger-img-storage-img-wrap">
          {imgData.map((item: any, idx: number) => (
            <WebServiceMessengerImgInStorage
              key={idx}
              thumbnail={item.thumbnail}
              selectMode={selectMode}
              onClick={
                selectMode
                  ? () => checkboxHandleChange(idx)
                  : () => showImgChange(idx)
              }
              isChecked={isChecked.includes(idx)}
              length={imgData.length}
              index={idx}
            />
          ))}
        </div>

        {showImg ? (
          <WebServiceImgShow
            imgData={imgData}
            showImg={showImg}
            setShowImg={setShowImg}
            showImgIdx={showImgIdx}
            setShowImgIdx={setShowImgIdx}
          />
        ) : null}
      </WebServiceNormalBody>

      <WebServiceBottomFixedPrimaryButton
        buttonName={
          isChecked
            ? isChecked.length + '개 항목 삭제'
            : '삭제할 항목을 선택하세요'
        }
      />
    </WebServiceWrapper>
  );
};

export default WebServiceMessengerChatImgStorage;
