/* eslint-disable max-len */
import WebServiceNormalBody from 'components/web_service/body/normal_body';
import WebServiceCenterTitleHeader from 'components/web_service/header/center_title';
import WebServiceWrapper from 'components/web_service_wrapper';
import React from 'react';
import store from 'redux/store';

const WebServiceNoticeRead = () => {
  const item = {
    title: '안전한 새해의 개발을 위한 시스템 정기점검 (예정)',
    date: '2023.01.06',
    editor: `안녕하세요?(주)유엔아이서비스 입니다.비즈콜 콜센터를 운영하오니 신규 가입 절차 등 문의사항은 아래 번호로 연락바랍니다. 밝기는 말 그대로 화면의 밝기를 수치로 표현한 것인데, 주로 cd/㎡ (줄여서 cd로 표기하기도 함) 를 사용합니다. cd(candela: 칸델라)는 광도의 국제 단위로, 점광원에서 방출되는 특정방향의 입체각당 광속을 의미합니다. 칸델라는 양초를 의미하며 1칸델라는 양초가 방출하는 광도를 의미하는 것입니다.
[출처] 게이밍 모니터, 어떤게 좋은 걸까? -2(밝기, 명암비, 색재현율, HDR 및 화면동기화)|작성자 bluemobis`
  };
  const files = [
    {
      name: 'UI디자인_최종_진짜최종_마지막(3)_sdfsdfs_ dfsdd.zip sdf sdf sdf sdf '
    },
    { name: '첨부파일.zip' },
    { name: '첨부파일.hwp' }
  ];
  return (
    <WebServiceWrapper hideNav={false} isCalling={false} scrollLock={false}>
      <WebServiceCenterTitleHeader title="공지사항" />
      <WebServiceNormalBody type="normal" background="" useOverlay={false}>
        <div className="web-service-notice-read-wrap">
          <div className="web-service-notice-read-head">
            <div className="web-service-notice-read-title">{item.title}</div>
            <div className="web-service-notice-read-date">{item.date}</div>
          </div>
          <div className="web-service-notice-read-body">
            <div className="web-service-notice-read-content">{item.editor}</div>
          </div>
          <div className="web-service-notice-read-foot">
            <div className="web-service-notice-read-foot-title">
              <img
                src={
                  '/icon/' +
                  (store.getState().theme.theme === 'light'
                    ? 'lightmode/'
                    : 'darkmode/') +
                  'attached_file-outline-t1.png'
                }
                className="web-service-notice-read-foot-img"
                alt="files"
              />
              첨부파일
            </div>
            <div className="web-service-notice-read-file-container">
              {files.map((item: any, idx: number) => (
                <div key={idx} className="web-service-notice-read-file">
                  <div
                    className={
                      'web-service-notice-read-file-bottom-border' +
                      (files.length !== idx + 1 ? ' active' : '')
                    }
                  >
                    {item.name}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </WebServiceNormalBody>
    </WebServiceWrapper>
  );
};

export default WebServiceNoticeRead;
