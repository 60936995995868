/* eslint-disable react/prop-types */
/* eslint-disable operator-linebreak */
import React from 'react';
import store from 'redux/store';
import 'stylesheets/web_service/contact.scss';

const WebServiceContactGroup = (props: any) => {
  const {
    icon,
    fullName,
    equalName,
    name,
    isFirst,
    useIcon,
    onClick,
    useArrow
  } = props;
  return (
    <div className="web-service-contact-group-container" onClick={onClick}>
      <div className={'web-service-contact-group' + (isFirst ? ' first' : '')}>
        <div
          className={
            'web-service-contact-group' +
            (useIcon ? '-icon-wrap' : '-icon-text-wrap')
          }
        >
          {useIcon ? (
            <img
              src={
                '/icon/' +
                (store.getState().theme.theme === 'light'
                  ? 'lightmode/'
                  : 'darkmode/') +
                `${icon}`
              }
              className="web-service-contact-group-icon"
              alt="contact group icon"
            />
          ) : (
            fullName.substr(0, 1)
          )}
        </div>
        <div className="web-service-contact-group-name-wrap">
          <div className="web-service-contact-group-equal-name">
            {equalName}
          </div>
          <div className="web-service-contact-group-name">{name}</div>
        </div>
        {useArrow ? (
          <img
            src={
              '/icon/' +
              (store.getState().theme.theme === 'light'
                ? 'lightmode/'
                : 'darkmode/') +
              'arrow_right-t3.png'
            }
            className="web-service-contact-group-arrow"
            alt="enter contact group"
          />
        ) : null}
      </div>
    </div>
  );
};

export default WebServiceContactGroup;
