/* eslint-disable operator-linebreak */
import { createSlice } from '@reduxjs/toolkit';

type absenceSettingState = {
  useAbsenceVoice: boolean;
  absenceVoice: {
    name: string;
    type: string;
    stateType: string;
    selected: boolean;
  }[];
  ringDuration: {
    time: number;
    type: string;
    selected: boolean;
  }[];
};

const initialState: absenceSettingState = {
  useAbsenceVoice: false,
  absenceVoice: [
    {
      name: '지금은 전화를 받을 수 없습니다. 다시 연락해주세요.',
      type: 'radioOnly',
      stateType: 'normal',
      selected: true
    },
    {
      name: '통화를 할 수 없습니다.',
      type: 'radioOnly',
      stateType: 'normal',
      selected: false
    },
    {
      name: '직접입력',
      type: 'radioOnly',
      stateType: 'custom',
      selected: false
    }
  ],
  ringDuration: [
    { time: 30, type: 'radioOnly', selected: true },
    { time: 60, type: 'radioOnly', selected: false },
    { time: 90, type: 'radioOnly', selected: false }
  ]
};

export const absenceSettingSlice = createSlice({
  name: 'absenceSetting',
  initialState,
  reducers: {
    switchUseAbsenceVoice: (state) => {
      state.useAbsenceVoice = !state.useAbsenceVoice;
    },
    selectAbsenceVoice: (state, action) => {
      state.absenceVoice.forEach(
        (item: any, idx: number) => (state.absenceVoice[idx].selected = false)
      );
      state.absenceVoice[action.payload].selected = true;
    },
    selectRingDuration: (state, action) => {
      state.ringDuration.forEach(
        (item: any, idx: number) => (state.ringDuration[idx].selected = false)
      );
      state.ringDuration[action.payload].selected = true;
    }
  }
});

export const { switchUseAbsenceVoice, selectAbsenceVoice, selectRingDuration } =
  absenceSettingSlice.actions;

export default absenceSettingSlice.reducer;
