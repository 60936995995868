/* eslint-disable operator-linebreak */
import { createSlice } from '@reduxjs/toolkit';

type callTypeSettingState = {
  callType: {
    name: string;
    type: string;
    innerText: string;
    selected: boolean;
  }[];
};

const initialState: callTypeSettingState = {
  callType: [
    {
      name: '다이렉트 콜 통화',
      type: 'radioOnly',
      innerText: `스마트폰 통화 수준의 품질과 안정성을 제공합니다. \n (음성통화 무한 요금 사용자)`,
      selected: true
    },
    {
      name: '클릭 투 콜 통화',
      type: 'radioOnly',
      innerText: `유성 통화 수준의 품질과 안정성을 제공합니다. \n (회사 통화 요금 사용자)`,
      selected: false
    },
    {
      name: 'Touch 콜 통화',
      type: 'radioOnly',
      innerText: `유선 통화 수준의 품질과 안정성을 제공합니다. \n (음성통화 무한 요금 사용자)`,
      selected: false
    }
  ]
};

export const callTypeSettingSlice = createSlice({
  name: 'callTypeSetting',
  initialState,
  reducers: {
    selectCallType: (state, action) => {
      state.callType.forEach(
        (item: any, idx: number) => (state.callType[idx].selected = false)
      );
      state.callType[action.payload].selected = true;
    }
  }
});

export const { selectCallType } = callTypeSettingSlice.actions;

export default callTypeSettingSlice.reducer;
