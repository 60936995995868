/* eslint-disable operator-linebreak */
import { createSlice } from '@reduxjs/toolkit';
import { Theme } from 'configs/enum';

type themeState = {
  theme: string;
};

const initialState: themeState = {
  theme: Theme.light
};

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    useDarkTheme: (state, action) => {
      state.theme = Theme.dark;
    },
    useLightTheme: (state, action) => {
      state.theme = Theme.light;
    },
    useChangeTheme: (state, action) => {
      state.theme = action.payload;
    }
  }
});

export const { useDarkTheme, useLightTheme, useChangeTheme } =
  themeSlice.actions;

export default themeSlice.reducer;
