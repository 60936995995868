/* eslint-disable object-curly-spacing */
/* eslint-disable react/prop-types */
import { useEffect } from 'react';
import routes from 'configs/routes';
import { useNavigate } from 'react-router-dom';

const Init = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if ((globalThis as any).isElectron) {
      navigate(routes.web_service_group_call_search, { replace: true });
    } else {
      navigate(routes.web_service_group_call_search, { replace: true });
    }
  }, []);

  return null;
};

export default Init;
