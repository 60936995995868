/* eslint-disable indent */
/* eslint-disable react/jsx-key */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import routes from 'configs/routes';

import WebServiceWrapper from 'components/web_service_wrapper';
import WebServiceSearchHeader from 'components/web_service/header/search';
import WebServiceNormalBody from 'components/web_service/body/normal_body';
import WebServiceNoResult from 'components/web_service/no_result/no_result';
import WebServiceSelectHeader from 'components/web_service/header/select';
import WebServiceSearchTabGroup from 'components/web_service/search/dial/search_tab_group';
import { ContactListItems, SMSListItems } from 'configs/items';
import WebServiceCenterTitleHeader from 'components/web_service/header/center_title';
import WebServiceMessengerHeader from 'components/web_service/header/messenger';
import WebServiceMessengerInvite from './messenger_invite';

import { MessengerUserList } from 'configs/items';
import WebServiceMessengerListCard from 'components/web_service/search/result_card_messenger';

const WebServiceMessenger = () => {
  const [searchValue, setSearchValue] = useState('');
  const [searchMode, setSearchMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [createMode, setCreateMode] = useState(false);
  const [isChecked, setIsChecked] = useState<number[]>([]);
  const [isCheckedAll, setIsCheckedAll] = useState(false);

  const handleChange = (e: any) => {
    setSearchValue(e.target.value);
  };

  const onReset = () => {
    setSearchValue('');
  };

  const checkboxHandleChange = (idx: number) => {
    if (isChecked.includes(idx)) {
      const activeList = isChecked.filter((i) => idx !== i);
      setIsChecked(activeList);
    } else {
      setIsChecked([...isChecked, idx]);
    }
  };

  const checkboxAllHandleChange = () => {
    if (!isCheckedAll) {
      const activeList = SMSListItems.map((v, i) => i);
      setIsChecked(activeList);
    } else {
      setIsChecked([]);
    }
    setIsCheckedAll(!isCheckedAll);
  };

  useEffect(() => {
    isChecked.length === SMSListItems.length
      ? setIsCheckedAll(true)
      : setIsCheckedAll(false);
  }, [isChecked]);

  // const checkboxHandleChange = (idx: any) => {
  //   const arr = smsData.map((value: any, index: any) => {
  //     if (index === idx) {
  //       return {
  //         ...value,
  //         isChecked: !value.isChecked
  //       };
  //     }
  //     return value;
  //   });
  //   setSmsData(arr);
  // };

  return (
    <WebServiceWrapper hideNav={false} isCalling={false} scrollLock={false}>
      {searchMode && !editMode && !createMode ? (
        <WebServiceSearchHeader
          onCancel={() => setSearchMode(false)}
          onSearchValueChange={handleChange}
          onSearchValueRemove={onReset}
          searchValue={searchValue}
        />
      ) : null}
      {!searchMode && editMode && !createMode ? (
        <WebServiceSelectHeader
          isChecked={isCheckedAll}
          onClick={checkboxAllHandleChange}
          onCancel={() => setEditMode(false)}
        />
      ) : null}
      {!searchMode && !editMode && createMode ? (
        <WebServiceCenterTitleHeader
          title={'채팅방 생성'}
          backState={createMode}
          setBackState={setCreateMode}
        />
      ) : null}
      {!searchMode && !editMode && !createMode ? (
        <WebServiceMessengerHeader
          title="메신저"
          onCreateButtonClick={() => setCreateMode(true)}
          onSearchButtonClick={() => setSearchMode(true)}
          onSettingButtonClick={() => setEditMode(!editMode)}
        />
      ) : null}
      <WebServiceNormalBody type="sms" useOverlay={false} background="">
        {searchMode && !editMode && !createMode ? (
          <WebServiceSearchTabGroup
            searchValue={searchValue}
            from="messenger"
            dataFirst={ContactListItems}
            dataSecond={SMSListItems}
            tabName1={'연락처'}
            tabName2={'채팅'}
          />
        ) : null}
        {!searchMode && !editMode && createMode ? (
          <WebServiceMessengerInvite users={MessengerUserList} />
        ) : null}
        {!searchMode && editMode && !createMode
          ? SMSListItems.map((item: any, idx: number) => (
              <WebServiceMessengerListCard
                name={item.name}
                text={item.text}
                date={item.date}
                alarmAmount={item.alarmAmount}
                isChecked={isChecked.includes(idx)}
                onClick={
                  editMode
                    ? () => checkboxHandleChange(idx)
                    : () => console.log('enter sms chat')
                }
                editMode={editMode}
                profile={item.profile}
                state={item.state}
              />
            ))
          : null}
        {!searchMode && !editMode && !createMode
          ? SMSListItems.map((item: any, idx: number) => (
              <Link to={routes.web_service_messenger_chat} key={idx}>
                <WebServiceMessengerListCard
                  name={item.name}
                  text={item.text}
                  date={item.date}
                  alarmAmount={item.alarmAmount}
                  isChecked={isChecked.includes(idx)}
                  onClick={
                    editMode
                      ? () => checkboxHandleChange(idx)
                      : () => console.log('enter sms chat')
                  }
                  editMode={editMode}
                  profile={item.profile}
                  state={item.state}
                />
              </Link>
            ))
          : null}
        {SMSListItems.length === 0 ? (
          <WebServiceNoResult
            type="sms"
            text={
              '문자 메시지를 보낸 기록이 없습니다.\n첫 메시지를 작성해보세요.'
            }
          />
        ) : null}
      </WebServiceNormalBody>
    </WebServiceWrapper>
  );
};

export default WebServiceMessenger;
