/* eslint-disable max-len */
import WebServiceNormalBody from 'components/web_service/body/normal_body';
import WebServiceBackTextHeader from 'components/web_service/header/back_text';
import WebServiceInput from 'components/web_service/input/input';
import WebServiceWrapper from 'components/web_service_wrapper';
import React from 'react';
import store from 'redux/store';

const WebServiceAddContact = () => {
  return (
    <WebServiceWrapper hideNav={false} isCalling={false} scrollLock={false}>
      <WebServiceBackTextHeader
        title="업무 주소록 추가"
        rightButtonText="저장"
      />
      <WebServiceNormalBody type="contact" useOverlay={false} background="">
        <div className="web-service-add-contact-profile-wrap">
          <div className="web-service-add-contact-profile-img-wrap">
            <img
              src={
                '/img/profile/' +
                (store.getState().theme.theme === 'light'
                  ? 'light_default_profile.png'
                  : 'dark_default_profile.png/')
              }
              className="web-service-add-contact-profile-img"
              alt="profile image"
            />
            <div className="web-service-add-contact-edit-wrap">
              <img
                src="/icon/common/camera.png"
                className="web-service-add-contact-edit-icon"
                alt="profile edit"
              />
            </div>
          </div>
        </div>
        <div className="web-service-add-contact-body-wrap">
          <WebServiceInput
            hasLabel={true}
            label="이름"
            inputType="text"
            placeholder="이름"
          />
          <WebServiceInput
            hasLabel={true}
            label="직위"
            inputType="text"
            placeholder="직위"
          />
          <WebServiceInput
            hasLabel={true}
            label="회사명"
            inputType="text"
            placeholder="회사명"
          />
          <WebServiceInput
            hasLabel={true}
            label="휴대전화 번호"
            inputType="tel"
            placeholder="휴대전화 번호"
          />
          <WebServiceInput
            hasLabel={true}
            label="유선전화 번호"
            inputType="tel"
            placeholder="유선전화 번호"
          />
        </div>
      </WebServiceNormalBody>
    </WebServiceWrapper>
  );
};

export default WebServiceAddContact;
