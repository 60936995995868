/* eslint-disable indent */
import React from 'react';

import WebServiceWrapper from 'components/web_service_wrapper';
import WebServiceNormalBody from 'components/web_service/body/normal_body';
import WebServiceMoreSubtitle from 'components/more/subtitle';
import WebServiceMoreCard from 'components/more/card';
import WebServiceMoreHeader from 'components/web_service/header/more';
import { useSelector } from 'react-redux';
import routes from 'configs/routes';
import { Link } from 'react-router-dom';

const WebServiceMore = () => {
  const { absenceSetting }: any = useSelector((state) => state);
  const { availableSetting }: any = useSelector((state) => state);
  const { ringingToneSetting }: any = useSelector((state) => state);
  const { forwardingSetting }: any = useSelector((state) => state);
  const { callTypeSetting }: any = useSelector((state) => state);
  const { recordSetting }: any = useSelector((state) => state);
  const { voiceDeviceSetting }: any = useSelector((state) => state);
  const { theme }: any = useSelector((state) => state);
  const { lang }: any = useSelector((state) => state);

  return (
    <WebServiceWrapper hideNav={false} isCalling={false} scrollLock={false}>
      <WebServiceMoreHeader title={'더보기'} />
      <WebServiceNormalBody type="more" useOverlay={false} background="">
        <WebServiceMoreSubtitle title="내 정보" />
        <Link to={routes.web_service_my_profile}>
          <WebServiceMoreCard
            type="arrowOnly"
            name="내 프로필"
            bottomBorder={true}
          />
        </Link>
        <Link to={routes.web_service_change_password}>
          <WebServiceMoreCard
            type="arrowOnly"
            name="비밀번호 재설정"
            bottomBorder={false}
          />
        </Link>
        <WebServiceMoreSubtitle title="회사 정보" />
        <Link to={routes.web_service_more_notice_list}>
          <WebServiceMoreCard
            type="arrowOnly"
            name="회사 공지사항"
            bottomBorder={false}
            alarmAmount="22"
          />
        </Link>
        <WebServiceMoreSubtitle title="시스템 정보" />
        <Link to={routes.web_service_more_version}>
          <WebServiceMoreCard
            type="arrowWithText"
            name="버전 정보"
            bottomBorder={true}
            rightText="1.0.0"
            isAlarm={true}
          />
        </Link>
        <Link to={routes.web_service_network}>
          <WebServiceMoreCard
            type="arrowWithText"
            name="네트워크 상태"
            bottomBorder={true}
            rightText="정상"
          />
        </Link>
        <Link to={routes.web_service_more_language}>
          <WebServiceMoreCard
            type="arrowWithText"
            name="언어 설정"
            bottomBorder={true}
            rightText={
              lang.lang === 'korean'
                ? '한국어'
                : lang.lang === 'japanese'
                ? '日本語'
                : lang.lang === 'english'
                ? 'English'
                : ''
            }
          />
        </Link>
        <Link to={routes.web_service_more_notice_list}>
          <WebServiceMoreCard
            type="arrowOnly"
            name="시스템 공지사항"
            bottomBorder={false}
            alarmAmount="22"
          />
        </Link>
        <WebServiceMoreSubtitle title="설정" />
        <Link to={routes.web_service_more_absence}>
          <WebServiceMoreCard
            type="arrowWithText"
            name="부재중 설정"
            bottomBorder={true}
            rightText={absenceSetting.useAbsenceVoice ? '사용' : '미사용'}
            rightTextColor={absenceSetting.useAbsenceVoice ? '' : 'inactive'}
          />
        </Link>
        <Link to={routes.web_service_more_available}>
          <WebServiceMoreCard
            type="arrowWithText"
            name="통화 가능 시간대 설정"
            bottomBorder={true}
            rightText={availableSetting.useAvailableTime ? '사용' : '미사용'}
            rightTextColor={availableSetting.useAvailableTime ? '' : 'inactive'}
            innerText={
              availableSetting.useAvailableTime
                ? (availableSetting.availableDate[0].selected ? '월 ' : '') +
                  (availableSetting.availableDate[1].selected ? '화 ' : '') +
                  (availableSetting.availableDate[2].selected ? '수 ' : '') +
                  (availableSetting.availableDate[3].selected ? '목 ' : '') +
                  (availableSetting.availableDate[4].selected ? '금 ' : '') +
                  (availableSetting.availableDate[5].selected ? '토 ' : '') +
                  (availableSetting.availableDate[6].selected ? '일 ' : '') +
                  ' (' +
                  availableSetting.availableStartTime +
                  ' ~ ' +
                  availableSetting.availableEndTime +
                  ')'
                : null
            }
          />
        </Link>
        <Link to={routes.web_service_more_ringing_tone}>
          <WebServiceMoreCard
            type="arrowWithText"
            name="통화 연결음 설정"
            bottomBorder={true}
            rightText={ringingToneSetting.useRingingTone ? '사용' : '미사용'}
            rightTextColor={ringingToneSetting.useRingingTone ? '' : 'inactive'}
          />
        </Link>
        <Link to={routes.web_service_more_forwarding}>
          <WebServiceMoreCard
            type="arrowWithText"
            name="착신 전환 설정"
            bottomBorder={true}
            rightText={forwardingSetting.useForwarding ? '사용' : '미사용'}
            rightTextColor={forwardingSetting.useForwarding ? '' : 'inactive'}
          />
        </Link>
        <Link to={routes.web_service_more_call_type}>
          <WebServiceMoreCard
            type="arrowWithText"
            name="통화방식 설정"
            bottomBorder={true}
            rightText={
              callTypeSetting.callType.find(
                (item: any) => item.selected === true
              ).name
            }
          />
        </Link>
        <Link to={routes.web_service_more_record}>
          <WebServiceMoreCard
            type="arrowWithText"
            name="녹취 설정"
            bottomBorder={true}
            rightText={recordSetting.useRecord ? '사용' : '미사용'}
            rightTextColor={recordSetting.useRecord ? '' : 'inactive'}
          />
        </Link>
        <Link to={routes.web_service_more_voice_device}>
          <WebServiceMoreCard
            type="arrowWithText"
            name="음성 입출력 설정"
            bottomBorder={true}
            rightText="사용중"
            innerText={`입력장치: ${
              voiceDeviceSetting.inputDevice.find(
                (item: any) => item.selected === true
              ).name
            } \n 출력장치: ${
              voiceDeviceSetting.outputDevice.find(
                (item: any) => item.selected === true
              ).name
            }`}
          />
        </Link>
        <Link to={routes.web_service_more_theme}>
          <WebServiceMoreCard
            type="arrowWithText"
            name="테마 설정"
            bottomBorder={false}
            rightText={theme.theme === 'light' ? '라이트모드' : '다크모드'}
          />
        </Link>
      </WebServiceNormalBody>
    </WebServiceWrapper>
  );
};

export default WebServiceMore;
