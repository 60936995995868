/* eslint-disable indent */
import WebServiceMoreCard from 'components/more/card';
import WebServiceNormalBody from 'components/web_service/body/normal_body';
import WebServiceCenterTitleHeader from 'components/web_service/header/center_title';
import WebServiceWrapper from 'components/web_service_wrapper';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCallType } from 'redux/more/call_type_setting';

const WebServiceMoreCallType = () => {
  const dispatch = useDispatch();
  const { callTypeSetting }: any = useSelector((state) => state);

  return (
    <WebServiceWrapper hideNav={false} isCalling={false} scrollLock={false}>
      <WebServiceCenterTitleHeader title="통화방식 설정" />
      <WebServiceNormalBody type="more-78" useOverlay={false} background="">
        {callTypeSetting.callType.map((item: any, idx: number) => (
          <WebServiceMoreCard
            key={idx}
            type={item.type}
            name={item.name}
            bottomBorder={callTypeSetting.callType.length !== idx + 1}
            innerText={item.innerText}
            isRadioOn={item.selected}
            onClick={() => dispatch(selectCallType(idx))}
          />
        ))}
      </WebServiceNormalBody>
    </WebServiceWrapper>
  );
};
export default WebServiceMoreCallType;
