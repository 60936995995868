import React from 'react';

import 'stylesheets/web_common.scss';
import 'stylesheets/web_service/common.scss';
import WebServiceSideNav from './web_service/nav/side';

type Props = {
  children: any;
  hideNav: any;
  isCalling: any;
  scrollLock: any;
};

const WebServiceWrapper = ({ children, hideNav, isCalling }: Props) => {
  return (
    <div className={'web-service-container' + (isCalling ? ' calling' : '')}>
      <div
        className={'web-service-container-row' + (hideNav ? ' nav-hide' : '')}
      >
        {hideNav === true ? null : <WebServiceSideNav />}
        <div
          className={
            'web-service-contents-container' + (hideNav ? ' nav-hide' : '')
          }
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default WebServiceWrapper;
