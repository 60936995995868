/* eslint-disable object-curly-spacing */
/* eslint-disable indent */
/* eslint-disable operator-linebreak */
import React from 'react';
import 'stylesheets/web_service/search.scss';

const WebServiceSearchTab = (props: any) => {
  const { items } = props;
  const itemList = items.map((item: any, idx: any) => (
    <div
      className={`web-service-search-tab-btn ${
        item.selected === true ? 'active' : 'inActive'
      }`}
      key={idx}
      onClick={item.tabOnClick}
    >
      {item.name}
    </div>
  ));
  return <div className="web-service-searh-tab-wrap">{itemList}</div>;
};

export default WebServiceSearchTab;
