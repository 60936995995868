/* eslint-disable react/prop-types */
/* eslint-disable operator-linebreak */
import React from 'react';
import 'stylesheets/web_service/call.scss';

const WebServiceCallReceiveMemo = (props: any) => {
  const { date, text } = props;

  return (
    <div className="web-service-bottom-sheet-memo-text-wrap">
      <div className="web-service-bottom-sheet-memo-date">{date}</div>
      <div className="web-service-bottom-sheet-memo-text">{text}</div>
    </div>
  );
};

export default WebServiceCallReceiveMemo;
