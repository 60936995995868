/* eslint-disable react/prop-types */
/* eslint-disable operator-linebreak */
import React from 'react';
import store from 'redux/store';

const WebServiceCloseCircleButton = (props: any) => {
  const { circleWidth, clickAreaWidth, onClick } = props;

  return (
    <div
      className={'web-service-close-circle-clickarea'}
      style={{ width: clickAreaWidth, height: clickAreaWidth }}
      onClick={onClick}
    >
      <img
        src={
          '/icon/' +
          (store.getState().theme.theme === 'light'
            ? 'lightmode/'
            : 'darkmode/') +
          'close_circle-t4.png'
        }
        className="web-service-close-circle"
        alt="close"
        style={{ width: circleWidth }}
      />
      <img
        src={
          '/icon/' +
          (store.getState().theme.theme === 'light'
            ? 'lightmode/'
            : 'darkmode/') +
          'close_circle-t2.png'
        }
        className="web-service-close-circle hover-effect"
        alt="close"
        style={{ width: circleWidth }}
      />
    </div>
  );
};

export default WebServiceCloseCircleButton;
