/* eslint-disable operator-linebreak */
import React from 'react';
import WebServiceCheckbox from '../input/checkbox';
import 'stylesheets/web_service/header.scss';

const WebServiceSelectHeader = (props: any) => {
  const { isChecked, onClick, onCancel } = props;

  return (
    <div className="web-service-select-header-container">
      <div className="web-service-select-header-checkbox-wrap">
        <div onClick={onClick}>
          <WebServiceCheckbox
            isLabel={true}
            label="전체선택"
            labelSize="big"
            isChecked={isChecked}
            hoverEffect={true}
          />
        </div>
      </div>
      <div className="web-service-header-text-button" onClick={onCancel}>
        취소
      </div>
    </div>
  );
};

export default WebServiceSelectHeader;
