/* eslint-disable max-len */
import WebServiceNormalBody from 'components/web_service/body/normal_body';
import WebServiceContactGroup from 'components/web_service/contact/contact_group';
import WebServiceSubTitle from 'components/web_service/content_title/sub_title';
import WebServiceCenterTitleHeader from 'components/web_service/header/center_title';
import WebServiceSearchHeader from 'components/web_service/header/search';
import WebServiceSearchResultCardContact from 'components/web_service/search/result_card_contact';
import WebServiceWrapper from 'components/web_service_wrapper';
import routes from 'configs/routes';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const WebServiceContactAddFromMy = () => {
  const [searchMode, setSearchMode] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const handleChange = (e: any) => {
    setSearchValue(e.target.value);
  };

  const onReset = () => {
    setSearchValue('');
  };

  return (
    <WebServiceWrapper hideNav={false} isCalling={false} scrollLock={false}>
      {!searchMode ? (
        <WebServiceCenterTitleHeader
          title="불러온 연락처"
          rightIcon="search-outline-t1"
          rightOnClick={() => setSearchMode(true)}
        />
      ) : null}
      {searchMode ? (
        <WebServiceSearchHeader
          onCancel={() => setSearchMode(false)}
          onSearchValueChange={handleChange}
          onSearchValueRemove={onReset}
          searchValue={searchValue}
        />
      ) : null}
      <WebServiceNormalBody type="contact" useOverlay={false} background="">
        {!searchMode ? (
          <>
            <Link to={routes.web_service_contact_add_all}>
              <WebServiceContactGroup
                useIcon={true}
                icon="check_circle-outline-p1.png"
                fullName=""
                equalName=""
                name="여러 항목 선택"
                isFirst={true}
                useArrow={false}
              />
            </Link>
            <WebServiceSubTitle title="ㄱ" />
            <Link to={routes.web_service_add_contact}>
              <WebServiceSearchResultCardContact
                type="withAddButton"
                equalName=""
                name="홍길동"
                equalNumber=""
                number="010-1234-1234"
                padding={true}
                profile="profile.jpg"
                hoverEffect={false}
              />
            </Link>
            <Link to={routes.web_service_add_contact}>
              <WebServiceSearchResultCardContact
                type="withAddButton"
                equalName=""
                name="홍길동"
                equalNumber=""
                number="010-1234-1234"
                padding={true}
                profile="profile.jpg"
                hoverEffect={false}
              />
            </Link>
            <Link to={routes.web_service_add_contact}>
              <WebServiceSearchResultCardContact
                type="withAddButton"
                equalName=""
                name="홍길동"
                equalNumber=""
                number="010-1234-1234"
                padding={true}
                profile="profile.jpg"
                hoverEffect={false}
              />
            </Link>
            <Link to={routes.web_service_add_contact}>
              <WebServiceSearchResultCardContact
                type="withAddButton"
                equalName=""
                name="홍길동"
                equalNumber=""
                number="010-1234-1234"
                padding={true}
                profile="profile.jpg"
                hoverEffect={false}
              />
            </Link>
            <Link to={routes.web_service_add_contact}>
              <WebServiceSearchResultCardContact
                type="withAddButton"
                equalName=""
                name="홍길동"
                equalNumber=""
                number="010-1234-1234"
                padding={true}
                profile="profile.jpg"
                hoverEffect={false}
              />
            </Link>
            <Link to={routes.web_service_add_contact}>
              <WebServiceSearchResultCardContact
                type="withAddButton"
                equalName=""
                name="홍길동"
                equalNumber=""
                number="010-1234-1234"
                padding={true}
                profile="profile.jpg"
                hoverEffect={false}
              />
            </Link>
            <Link to={routes.web_service_add_contact}>
              <WebServiceSearchResultCardContact
                type="withAddButton"
                equalName=""
                name="홍길동"
                equalNumber=""
                number="010-1234-1234"
                padding={true}
                profile="profile.jpg"
                hoverEffect={false}
              />
            </Link>
            <Link to={routes.web_service_add_contact}>
              <WebServiceSearchResultCardContact
                type="withAddButton"
                equalName=""
                name="홍길동"
                equalNumber=""
                number="010-1234-1234"
                padding={true}
                profile="profile.jpg"
                hoverEffect={false}
              />
            </Link>
            <Link to={routes.web_service_add_contact}>
              <WebServiceSearchResultCardContact
                type="withAddButton"
                equalName=""
                name="홍길동"
                equalNumber=""
                number="010-1234-1234"
                padding={true}
                profile="profile.jpg"
                hoverEffect={false}
              />
            </Link>
            <Link to={routes.web_service_add_contact}>
              <WebServiceSearchResultCardContact
                type="withAddButton"
                equalName=""
                name="홍길동"
                equalNumber=""
                number="010-1234-1234"
                padding={true}
                profile="profile.jpg"
                hoverEffect={false}
              />
            </Link>
          </>
        ) : null}
        {searchMode ? (
          <>
            <Link to={routes.web_service_add_contact}>
              <WebServiceSearchResultCardContact
                type="withAddButton"
                equalName=""
                name="홍길동"
                equalNumber=""
                number="010-1234-1234"
                padding={true}
                profile="profile.jpg"
                hoverEffect={false}
              />
            </Link>
            <Link to={routes.web_service_add_contact}>
              <WebServiceSearchResultCardContact
                type="withAddButton"
                equalName=""
                name="홍길동"
                equalNumber=""
                number="010-1234-1234"
                padding={true}
                profile="profile.jpg"
                hoverEffect={false}
              />
            </Link>
            <Link to={routes.web_service_add_contact}>
              <WebServiceSearchResultCardContact
                type="withAddButton"
                equalName=""
                name="홍길동"
                equalNumber=""
                number="010-1234-1234"
                padding={true}
                profile="profile.jpg"
                hoverEffect={false}
              />
            </Link>
          </>
        ) : null}
      </WebServiceNormalBody>
    </WebServiceWrapper>
  );
};

export default WebServiceContactAddFromMy;
