/* eslint-disable require-jsdoc */
import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import routes from 'configs/routes';

import MainCallLog from 'pages/app/call_log/call_log';
import MainSMS from 'pages/app/sms/sms';
import MainContact from 'pages/app/contact/main';
import WebServiceLogIn from 'pages/app/auth/login';

import { useAppSelector } from 'redux/hooks';
import { RootState } from 'redux/store';
import Init from 'pages';
import Landing from 'pages/landing';

import 'stylesheets/theme.scss';
import WebServiceAuthPermission from 'pages/app/auth/auth_permission';
import WebServiceDial from 'pages/app/call/dial';
import WebServiceGroupCallSearch from 'pages/app/call/group_call_search';
import WebServiceCallReceive from 'pages/app/call/call';
import WebServiceCallLog from 'pages/app/call_log/call_log';
import WebServiceSMS from 'pages/app/sms/sms';
import WebServiceSMSChat from 'pages/app/sms/sms_chat';
import WebServiceMessenger from 'pages/app/messenger/messenger';
import WebServiceContact from 'pages/app/contact/contact';
import WebServiceMore from 'pages/app/more/more';
import WebServiceContactAddFromMy from 'pages/app/contact/add_from_my';
import WebServiceAddContact from 'pages/app/contact/add_contact';
import WebServiceContactAddAll from 'pages/app/contact/add_all';
import WebServiceContactCamera from 'pages/app/contact/camera';
import WebServiceContactProfile from 'pages/app/contact/profile';
import WebServiceMoreAbsence from 'pages/app/more/settings/absence';
import WebServiceMoreAvailable from 'pages/app/more/settings/available';
import WebServiceMoreRingingTone from 'pages/app/more/settings/ringing_tone';
import WebServiceMoreForwarding from 'pages/app/more/settings/forwarding';
import WebServiceMoreCallType from 'pages/app/more/settings/call_type';
import WebServiceMoreRecord from 'pages/app/more/settings/record';
import WebServiceMoreVoiceDevice from 'pages/app/more/settings/voice_device';
import WebServiceMoreTheme from 'pages/app/more/settings/theme';
import WebServiceMyProfile from 'pages/app/more/my_info/my_profile';
import WebServiceChangePassword from 'pages/app/more/my_info/change_password';
import WebServiceNoticeList from 'pages/app/more/notice/lists';
import WebServiceNoticeRead from 'pages/app/more/notice/read';
import WebServiceVersion from 'pages/app/more/system_info/version';
import WebServiceMoreLanguage from 'pages/app/more/system_info/language';
import WebServiceNetwork from 'pages/app/more/system_info/network';
import WebServiceMessengerControl from 'pages/app/messenger/messenger_chat_control';
import WebServiceMemoLists from 'pages/app/contact/memo/lists';

const App = () => {
  const { theme } = useAppSelector((state: RootState) => state.theme);

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Init />} />
        {/* landing */}
        <Route path={routes.landing} element={<Landing />} />
        {/* app */}
        <Route path={routes.main_call_log} element={<MainCallLog />} />
        <Route path={routes.main_sms} element={<MainSMS />} />
        <Route path={routes.main_contact} element={<MainContact />} />
        {/* web */}
        <Route path={routes.web_service_login} element={<WebServiceLogIn />} />
        <Route
          path={routes.web_service_auth_permission}
          element={<WebServiceAuthPermission />}
        />
        <Route path={routes.web_service_dial} element={<WebServiceDial />} />
        <Route
          path={routes.web_service_group_call_search}
          element={<WebServiceGroupCallSearch />}
        />
        <Route
          path={routes.web_service_call}
          element={<WebServiceCallReceive />}
        />
        <Route
          path={routes.web_service_call_log}
          element={<WebServiceCallLog />}
        />
        <Route path={routes.web_service_sms} element={<WebServiceSMS />} />
        <Route
          path={routes.web_service_sms_chat}
          element={<WebServiceSMSChat />}
        />{' '}
        <Route
          path={routes.web_service_messenger}
          element={<WebServiceMessenger />}
        />
        <Route
          path={routes.web_service_messenger_chat}
          element={<WebServiceMessengerControl />}
        />
        <Route
          path={routes.web_service_contact}
          element={<WebServiceContact />}
        />
        <Route
          path={routes.web_service_contact_add_from_my}
          element={<WebServiceContactAddFromMy />}
        />
        <Route
          path={routes.web_service_add_contact}
          element={<WebServiceAddContact />}
        />
        <Route
          path={routes.web_service_contact_add_all}
          element={<WebServiceContactAddAll />}
        />
        <Route
          path={routes.web_service_contact_camera}
          element={<WebServiceContactCamera />}
        />
        <Route
          path={routes.web_service_contact_profile}
          element={<WebServiceContactProfile />}
        />
        <Route path={routes.web_service_more} element={<WebServiceMore />} />
        <Route
          path={routes.web_service_more_absence}
          element={<WebServiceMoreAbsence />}
        />
        <Route
          path={routes.web_service_more_available}
          element={<WebServiceMoreAvailable />}
        />
        <Route
          path={routes.web_service_more_ringing_tone}
          element={<WebServiceMoreRingingTone />}
        />
        <Route
          path={routes.web_service_more_forwarding}
          element={<WebServiceMoreForwarding />}
        />
        <Route
          path={routes.web_service_more_call_type}
          element={<WebServiceMoreCallType />}
        />
        <Route
          path={routes.web_service_more_record}
          element={<WebServiceMoreRecord />}
        />
        <Route
          path={routes.web_service_more_voice_device}
          element={<WebServiceMoreVoiceDevice />}
        />
        <Route
          path={routes.web_service_more_theme}
          element={<WebServiceMoreTheme />}
        />
        <Route
          path={routes.web_service_my_profile}
          element={<WebServiceMyProfile />}
        />
        <Route
          path={routes.web_service_change_password}
          element={<WebServiceChangePassword />}
        />
        <Route
          path={routes.web_service_more_notice_list}
          element={<WebServiceNoticeList />}
        />
        <Route
          path={routes.web_service_more_notice_read}
          element={<WebServiceNoticeRead />}
        />
        <Route
          path={routes.web_service_more_version}
          element={<WebServiceVersion />}
        />
        <Route
          path={routes.web_service_more_language}
          element={<WebServiceMoreLanguage />}
        />
        <Route
          path={routes.web_service_network}
          element={<WebServiceNetwork />}
        />
        <Route
          path={routes.web_service_memo_lists}
          element={<WebServiceMemoLists />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
