import { configureStore } from '@reduxjs/toolkit';
import themeReducer from 'redux/theme_reducer';
import langReducer from 'redux/lang_reducer';
import bottomNavReducer from './bottom_nav_reducer';
import absenceSettingReducer from './more/absence_setting';
import availableSettingReducer from './more/available_setting';
import ringingToneSettingReducer from './more/ringing_tone_setting';
import forwardingSettingReducer from './more/forwarding_setting';
import callTypeSettingReducer from './more/call_type_setting';
import recordSettingReducer from './more/record_setting';
import voiceDeviceSettingReducer from './more/voice_device_setting';

const store = configureStore({
  reducer: {
    theme: themeReducer,
    lang: langReducer,
    selectedNavMenu: bottomNavReducer,
    absenceSetting: absenceSettingReducer,
    availableSetting: availableSettingReducer,
    ringingToneSetting: ringingToneSettingReducer,
    forwardingSetting: forwardingSettingReducer,
    callTypeSetting: callTypeSettingReducer,
    recordSetting: recordSettingReducer,
    voiceDeviceSetting: voiceDeviceSettingReducer
  }
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
