/* eslint-disable max-len */
import WebServiceNormalBody from 'components/web_service/body/normal_body';
import WebServicePrimaryButton from 'components/web_service/button/primary_button';
import WebServiceCenterTitleHeader from 'components/web_service/header/center_title';
import WebServiceWrapper from 'components/web_service_wrapper';
import React from 'react';

const WebServiceVersion = () => {
  const currentVersion = { version: 'v 1.0.1' };
  const latestVersion = { version: 'v 1.0.2' };
  return (
    <WebServiceWrapper hideNav={false} isCalling={false} scrollLock={false}>
      <WebServiceCenterTitleHeader title="버전 정보" />
      <WebServiceNormalBody type="normal" background="" useOverlay={false}>
        <div className="web-service-version-wrap">
          <img
            src="/img/logo/dial_circular_logo.png"
            className="web-service-version-img"
            alt="logo"
          />
          <div className="web-service-version-ver">
            {currentVersion.version}
          </div>
          <div className="web-service-version-text">
            {currentVersion.version === latestVersion.version ? (
              '현재 최신버전을 사용중입니다.'
            ) : (
              <>
                <div className="web-service-version-alert">!</div>최신버전으로
                업데이트가 필요합니다.
              </>
            )}
          </div>
          {currentVersion.version !== latestVersion.version ? (
            <div className="web-service-version-btn-wrap">
              <WebServicePrimaryButton buttonName="업데이트" size="small" />
            </div>
          ) : (
            ''
          )}
        </div>
      </WebServiceNormalBody>
    </WebServiceWrapper>
  );
};

export default WebServiceVersion;
