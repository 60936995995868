/* eslint-disable indent */
/* eslint-disable react/prop-types */
/* eslint-disable operator-linebreak */
import React from 'react';
import 'stylesheets/web_service/chat.scss';

const WebServiceChatBubbleText = (props: any) => {
  const { userId, text } = props;

  return (
    <div
      className={
        'web-service-chat-bubble-text' + (userId === 'myId' ? ' myBubble' : '')
      }
    >
      {text}
    </div>
  );
};

export default WebServiceChatBubbleText;
