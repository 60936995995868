import React from 'react';

import 'stylesheets/main_page_wrapper.scss';
import 'stylesheets/web_common.scss';
import 'stylesheets/landing.scss';
import LandingNav from './landing_nav';

type Props = {
  children: any;
};

const LandingPageWrapper = ({ children }: Props) => {
  return (
    <div className="landing-container">
      <LandingNav />
      <div className="landing-contents-container">{children}</div>
    </div>
  );
};

export default LandingPageWrapper;
