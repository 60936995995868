/* eslint-disable indent */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';

import WebServiceInput from 'components/web_service/input/input';
import WebServiceSearchResultCardContact from 'components/web_service/search/result_card_contact';
import WebServiceSubTitle from 'components/web_service/content_title/sub_title';
import WebServiceBottonSheetPrimaryButton from 'components/web_service/fixed_component/primary_button';

import 'stylesheets/web_service/search.scss';
import 'stylesheets/web_service/messenger.scss';
import WebServiceInviteSelectedUser from 'components/web_service/search/group_call/selected_user';

const WebServiceMessengerInvite = (props: any) => {
  const { users } = props;
  const [inputValue, setInputValue] = useState('');
  const [userList, setUserList] =
    useState<Array<{ name: string; telNumb: string; profileImg: string }>>();
  const userSort = users.sort((a: any, b: any) =>
    a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
  );

  useEffect(() => {
    setUserList(userSort);
  }, []);

  const handleChange = (e: any) => {
    setInputValue(e.target.value);
  };

  const onReset = () => {
    setInputValue('');
  };

  return (
    <>
      <div className="web-service-messenger-selected-user-group">
        {userList?.map((user: any, idx: any) => (
          <WebServiceInviteSelectedUser key={idx} name={user.name} />
        ))}
      </div>
      <div className="web-service-invite-search-input-wrap">
        <WebServiceInput
          hasLabel={false}
          type="withRemove"
          inputType="text"
          placeholder="이름 또는 전화번호 검색"
          inputValue={inputValue}
          onChange={handleChange}
          onReset={onReset}
        />
      </div>
      {inputValue ? (
        <>
          <WebServiceSearchResultCardContact
            type="withCheckbox"
            equalName=""
            name="홍길동"
            equalNumber=""
            number="010-1234-1234"
            padding={true}
            profile={'profile.jpg'}
          />
          <WebServiceBottonSheetPrimaryButton
            isDisabled={true}
            buttonName="채팅방 생성"
          />
        </>
      ) : (
        <>
          <WebServiceSubTitle title="ㄱ" underline />
          {userList?.map((user: any, idx: any) => (
            <WebServiceSearchResultCardContact
              key={idx}
              type="withCheckbox"
              equalName=""
              name={user.name}
              equalNumber=""
              number={user.telNumb}
              padding={true}
              profile={user.profileImg}
            />
          ))}
          <WebServiceSubTitle title="ㄴ" underline />
          <WebServiceSubTitle title="ㄷ" underline />
          <WebServiceSubTitle title="ㄹ" underline />
        </>
      )}
    </>
  );
};

export default WebServiceMessengerInvite;
