/* eslint-disable max-len */
import WebServiceNormalBody from 'components/web_service/body/normal_body';
import WebServiceSecondaryButton from 'components/web_service/button/secondary_button';
import WebServiceProfileCallLog from 'components/web_service/contact/profile_call_log';
import WebServiceBottomSheetRecord from 'components/web_service/fixed_component/record';
import WebServiceTopSheetMenuContainer from 'components/web_service/fixed_component/top_sheet_menu_container';
import WebServiceProfileHeader from 'components/web_service/header/profile';
import WebServiceBookmarkModal from 'components/web_service/modal/bookmark_modal';
import WebServiceOkModal from 'components/web_service/modal/ok_modal';
import WebServiceYNModal from 'components/web_service/modal/yn_modal';
import WebServiceProfileImg from 'components/web_service/profile/profile_img';
import WebServiceWrapper from 'components/web_service_wrapper';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import store from 'redux/store';

const WebServiceContactProfile = () => {
  const [openRecord, setOpenRecord] = useState(false);
  const [openTopSheetMenu, setOpenTopSheetMenu] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openRemoveCompleteModal, setOpenRemoveCompleteModal] = useState(false);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [bokmarkShow, setBookmarkShow] = useState(false);
  const [useBookmark, setUseBookmark] = useState(false);
  const [timerId, setTimerId] = useState<ReturnType<typeof setTimeout>>();
  const navigate = useNavigate();
  const name = '홍길동';

  const removeModalCancel = () => {
    setOpenRemoveModal(false);
    setOpenTopSheetMenu(false);
  };

  const removeModalOk = () => {
    setOpenRemoveModal(false);
    setOpenRemoveCompleteModal(true);
  };

  const addModalCancel = () => {
    setOpenAddModal(false);
    setOpenTopSheetMenu(false);
  };

  const addModalOk = () => {
    setOpenAddModal(false);
    setOpenTopSheetMenu(false);
  };

  const removeCompleteModalOk = () => {
    setOpenRemoveCompleteModal(false);
    navigate(-1);
  };

  const bookmarkHandleChange = () => {
    if (timerId) {
      clearTimeout(timerId);
    }
    setBookmarkShow(true);
    setUseBookmark(!useBookmark);

    const timeoutId = setTimeout(() => {
      setBookmarkShow(false);
    }, 2000);

    setTimerId(timeoutId);
  };
  return (
    <WebServiceWrapper hideNav={false} isCalling={false} scrollLock={false}>
      <WebServiceProfileHeader
        firstButtonClick={() => setOpenAddModal(true)}
        secondButtonClick={bookmarkHandleChange}
        thirdButtonClick={() => setOpenTopSheetMenu(true)}
        useBookmark={useBookmark}
      />
      <WebServiceNormalBody
        type="normal"
        useOverlay={openRecord || openTopSheetMenu}
        background=""
      >
        <div className="web-service-contact-profile-head">
          <WebServiceProfileImg size="big" profile="profile.jpg" state="none" />
          <div className="web-service-contact-profile-name-wrap">
            <div className="web-service-contact-profile-name">{name}</div>
            <div className="web-service-contact-profile-subinfo">
              <div className="web-service-contact-profile-position">대리</div>
              <div className="web-service-contact-profile-subinfo-dot">·</div>
              <div className="web-service-contact-profile-company">
                가나다주식회사 경영지원팀
              </div>
            </div>
          </div>
          {/* <div className="web-service-contact-profile-state my">
            <div />
            <span>온라인</span>
            <img
              src={
                '/icon/' +
                (store.getState().theme.theme === 'light'
                  ? 'lightmode/'
                  : 'darkmode/') +
                'arrow_down-t1.png'
              }
              alt="change state"
            />
          </div> */}
          <div className="web-service-contact-profile-state other">
            <div />
            <span>온라인</span>
          </div>
        </div>
        <div className="web-service-contact-profile-body">
          <div className="web-service-contact-profile-button-section">
            <div className="web-service-contact-profile-button">
              <img
                src={
                  '/icon/' +
                  (store.getState().theme.theme === 'light'
                    ? 'lightmode/'
                    : 'darkmode/') +
                  'sms-outline-p1.png'
                }
                className="web-service-contact-profile-button-icon"
                alt="SMS"
              />
            </div>
            <div className="web-service-contact-profile-button">
              <img
                src={
                  '/icon/' +
                  (store.getState().theme.theme === 'light'
                    ? 'lightmode/'
                    : 'darkmode/') +
                  'messenger-outline-p1.png'
                }
                className="web-service-contact-profile-button-icon"
                alt="chat"
              />
            </div>
            <div className="web-service-contact-profile-button">
              <img
                src={
                  '/icon/' +
                  (store.getState().theme.theme === 'light'
                    ? 'lightmode/'
                    : 'darkmode/') +
                  'call-outline-p1.png'
                }
                className="web-service-contact-profile-button-icon"
                alt="call"
              />
            </div>
          </div>
          <div className="web-service-contact-profile-number-section">
            <div className="web-service-contact-profile-number-container">
              <div className="web-service-contact-profile-number-wrap">
                <div className="web-service-contact-profile-number-border">
                  <div className="web-service-contact-profile-number-name">
                    휴대전화
                  </div>
                  <div className="web-service-contact-profile-number">
                    010-1234-1234
                  </div>
                </div>
              </div>
              <div className="web-service-contact-profile-number-wrap">
                <div className="web-service-contact-profile-number-name">
                  유선전화
                </div>
                <div className="web-service-contact-profile-number">
                  02-1234-1234
                </div>
              </div>
            </div>
          </div>
          <div className="web-service-contact-profile-memo-section">
            <div className="web-service-contact-profile-memo-wrap">
              <div className="web-service-contact-profile-memo-title-wrap">
                <div className="web-service-contact-profile-title">메모</div>
                <div className="web-service-contact-profile-memo-add">
                  <img
                    src={
                      '/icon/' +
                      (store.getState().theme.theme === 'light'
                        ? 'lightmode/'
                        : 'darkmode/') +
                      'plus-t2.png'
                    }
                    className="web-service-contact-profile-memo-add-icon"
                    alt="add memo"
                  />
                  추가
                </div>
              </div>
              <div className="web-service-contact-profile-memo-info">
                <div className="web-service-contact-profile-memo-time">
                  오전 10:35
                </div>
                <div className="web-service-contact-profile-memo-text">
                  PB 교육 관련 신규 이슈 논의 필요. 1시-2시에 다시 통화 하기로
                  함. 통화 전 준비자료 미리 숙지.
                </div>
              </div>
              <WebServiceSecondaryButton
                buttonName="전체보기"
                colorAdjust="up"
                size="normal"
              />
            </div>
          </div>
          <div className="web-service-contact-profile-call-log-section">
            <div className="web-service-contact-profile-call-log-container">
              <div className="web-service-contact-profile-title">통화기록</div>
              <div className="web-service-contact-profile-date">오늘</div>
              <WebServiceProfileCallLog
                callType="absence"
                time="오후 10:33"
                setOpenRecord={setOpenRecord}
              />
              <WebServiceProfileCallLog
                callType="receive"
                time="오후 10:33"
                setOpenRecord={setOpenRecord}
              />
              <WebServiceProfileCallLog
                callType="sent"
                time="오후 10:33"
                setOpenRecord={setOpenRecord}
              />
              <WebServiceProfileCallLog
                callType="block"
                time="오후 10:33"
                setOpenRecord={setOpenRecord}
              />
              <div className="web-service-contact-profile-date">어제</div>
              <WebServiceProfileCallLog
                callType="absence"
                time="오후 10:33"
                setOpenRecord={setOpenRecord}
              />
              <WebServiceProfileCallLog
                callType="receive"
                time="오후 10:33"
                setOpenRecord={setOpenRecord}
              />
              <WebServiceProfileCallLog
                callType="sent"
                time="오후 10:33"
                setOpenRecord={setOpenRecord}
              />
              <WebServiceProfileCallLog
                callType="block"
                time="오후 10:33"
                setOpenRecord={setOpenRecord}
              />
            </div>
          </div>
        </div>
      </WebServiceNormalBody>
      <WebServiceBottomSheetRecord
        setOpenRecord={setOpenRecord}
        openRecord={openRecord}
      />
      <WebServiceTopSheetMenuContainer
        items={[
          {
            type: 'normal',
            name: '수정',
            link: '/app/web_service/add_contact'
          },
          {
            type: 'normal',
            name: '삭제',
            onClick: () => setOpenRemoveModal(true)
          }
        ]}
        openTopSheetMenu={openTopSheetMenu}
        setOpenTopSheetMenu={setOpenTopSheetMenu}
        useAlarm
      />
      <WebServiceYNModal
        title="연락처 삭제"
        text={name + '님을 주소록에서 삭제하시겠습니까?'}
        primaryButtonName="확인"
        secondaryButtonName="취소"
        openModal={openRemoveModal}
        setOpenModal={setOpenRemoveModal}
        onPrimaryClick={() => removeModalOk()}
        onSecondaryClick={() => removeModalCancel()}
      />
      <WebServiceYNModal
        title="연락처 등록"
        text={name + '님을 주소록에 등록하시겠습니까?'}
        primaryButtonName="확인"
        secondaryButtonName="취소"
        openModal={openAddModal}
        setOpenModal={setOpenAddModal}
        onPrimaryClick={() => addModalOk()}
        onSecondaryClick={() => addModalCancel()}
      />
      <WebServiceOkModal
        title="삭제 완료"
        text={name + '님이 주소록에서 삭제되었습니다.'}
        buttonName="확인"
        openModal={openRemoveCompleteModal}
        setOpenModal={setOpenRemoveCompleteModal}
        onClick={() => removeCompleteModalOk()}
      />
      <WebServiceBookmarkModal
        useBookmark={useBookmark}
        bookmarkShow={bokmarkShow}
      />
    </WebServiceWrapper>
  );
};

export default WebServiceContactProfile;
