/* eslint-disable max-len */
/* eslint-disable indent */
import WebServiceNormalBody from 'components/web_service/body/normal_body';
import WebServiceChatBubble from 'components/web_service/chat/bubble';
import WebServiceChatInput from 'components/web_service/fixed_component/chat_input';
import WebServiceBottomFixedPrimaryButton from 'components/web_service/fixed_component/primary_button';
import WebserviceToast from 'components/web_service/fixed_component/toast';
import WebServiceCenterTitleHeader from 'components/web_service/header/center_title';
import WebServiceSelectHeader from 'components/web_service/header/select';
import WebServiceAlarmModal from 'components/web_service/modal/alarm_modal';
import WebServiceWrapper from 'components/web_service_wrapper';
import React, { useState, useEffect } from 'react';
import WebServiceMessengerChatMenu from './messenger_chat_menu';
import { ChatBubbleItems1 } from 'configs/items';
import routes from 'configs/routes';

const WebServiceMessengerChat = (props: any) => {
  const {
    setShowImgStorage,
    setShowFileStorage,
    openChangeName,
    setOpenChangeName,
    showToast,
    setShowToast,
    setOpenInvite
  } = props;
  const [isDeleteMode, setIsDeleteMode] = useState(false);
  const [useAlarm, setUseAlarm] = useState(false);
  const [alarmShow, setAlarmShow] = useState(false);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [isChecked, setIsChecked] = useState<number[]>([]);
  const [timerId, setTimerId] = useState<ReturnType<typeof setTimeout>>();
  const [isPopover, setIsPopover] = useState<number>();
  const [showReply, setShowReply] = useState(false);
  const [replyTarget, setReplyTarget] = useState<string>('');
  const [openMenu, setOpenMenu] = useState(false);

  const checkboxHandleChange = (idx: number) => {
    if (isDeleteMode) {
      if (isChecked.includes(idx)) {
        const activeList = isChecked.filter((i) => idx !== i);
        setIsChecked(activeList);
      } else {
        setIsChecked([...isChecked, idx]);
      }
    }
  };

  const checkboxAllHandleChange = () => {
    if (!isCheckedAll) {
      const activeList = ChatBubbleItems1.map((v, i) => i);
      setIsChecked(activeList);
    } else {
      setIsChecked([]);
    }
    setIsCheckedAll(!isCheckedAll);
  };

  const alarmHandleChagne = () => {
    if (timerId) {
      clearTimeout(timerId);
    }
    setUseAlarm(!useAlarm);
    setAlarmShow(true);

    const timeoutId = setTimeout(() => {
      setAlarmShow(false);
    }, 2000);
    console.log(timeoutId);

    setTimerId(timeoutId);
  };

  useEffect(() => {
    isChecked.length === ChatBubbleItems1.length
      ? setIsCheckedAll(true)
      : setIsCheckedAll(false);
  }, [isChecked]);

  const replyHandleChange = (item: any) => {
    setShowReply(true);
    setReplyTarget(item);
  };

  useEffect(() => {
    if (showToast.show) {
      setTimeout(() => {
        setShowToast({ text: '', show: false });
      }, 2500);
    }
  }, [showToast]);

  return (
    <WebServiceWrapper hideNav={false} isCalling={false} scrollLock={openMenu}>
      {isDeleteMode ? (
        <WebServiceSelectHeader
          isChecked={isCheckedAll}
          onClick={checkboxAllHandleChange}
          onCancel={() => setIsDeleteMode(false)}
        />
      ) : (
        <WebServiceCenterTitleHeader
          title="홍길동"
          rightIcon="menu-outline-t1"
          rightOnClick={() => setOpenMenu(true)}
          onBackClick={routes.web_service_messenger}
        />
      )}
      <WebServiceNormalBody type="chat" useOverlay={openMenu} background="bg4">
        {ChatBubbleItems1.map((item, idx) => (
          <>
            {ChatBubbleItems1[idx - 1] &&
            item.date === ChatBubbleItems1[idx - 1].date ? null : (
              <div className="web-service-chat-date-wrap">
                <div className="web-service-chat-date">{item.date}</div>
              </div>
            )}
            <WebServiceChatBubble
              key={idx}
              userId={item.userId}
              type={item.type}
              profileImg={item.profileImg}
              name={item.name}
              text={item.text}
              time={item.time}
              fileName={item.fileName}
              fileSize={item.fileSize}
              fileFormat={item.fileFormat}
              fileDownloadPeriod={item.fileDownloadPeriod}
              fileDownloaded={item.fileDownloaded}
              image={item.image}
              url={item.url}
              isDeleteMode={isDeleteMode}
              isFirstBubble={
                ChatBubbleItems1[idx - 1] &&
                item.name === ChatBubbleItems1[idx - 1].name &&
                item.time === ChatBubbleItems1[idx - 1].time
                  ? false
                  : true
              }
              isLastBubble={
                ChatBubbleItems1[idx + 1] &&
                item.name === ChatBubbleItems1[idx + 1].name &&
                item.time === ChatBubbleItems1[idx + 1].time
                  ? false
                  : true
              }
              isChecked={isChecked.includes(idx)}
              onCheckboxClick={() => checkboxHandleChange(idx)}
              isPopover={isPopover === idx ? true : false}
              setIsPopover={() => setIsPopover(idx)}
              unsetIsPopover={() => setIsPopover(undefined)}
              onReplyClick={() => replyHandleChange(item)}
            />
          </>
        ))}
        {isDeleteMode ? null : (
          <WebServiceChatInput
            showReply={showReply}
            replyTarget={replyTarget}
            closeReply={() => setShowReply(false)}
          />
        )}
        {showToast.show ? <WebserviceToast showToast={showToast} /> : null}
      </WebServiceNormalBody>
      {isDeleteMode && isChecked.length > 0 ? (
        <WebServiceBottomFixedPrimaryButton
          isDisabled={false}
          buttonName="선택한 항목 삭제"
        />
      ) : null}
      <WebServiceMessengerChatMenu
        openMenu={openMenu}
        setOpenMenu={setOpenMenu}
        setShowImgStorage={setShowImgStorage}
        setShowFileStorage={setShowFileStorage}
        openChangeName={openChangeName}
        setOpenChangeName={setOpenChangeName}
        alarmHandleChagne={alarmHandleChagne}
        useAlarm={useAlarm}
        setOpenInvite={setOpenInvite}
      />
      <WebServiceAlarmModal useAlarm={useAlarm} alarmShow={alarmShow} />
    </WebServiceWrapper>
  );
};

export default WebServiceMessengerChat;
