/* eslint-disable indent */
/* eslint-disable max-len */
import React, { useState } from 'react';

import WebServiceMessengerChat from './messenger_chat';
import WebServiceMessengerChatImgStorage from './messenger_chat_img_storage';
import WebServiceMessengerChatFileStorage from './messenger_chart_file_storage';
import WebServiceMessengerChatChangeName from './messenger_chat_change_name';
import WebServiceMessengerChatInvite from './messenger_chat_invite';

const WebServiceMessengerControl = () => {
  const [showImgStorage, setShowImgStorage] = useState(false);
  const [showFileStorage, setShowFileStorage] = useState(false);
  const [openChangeName, setOpenChangeName] = useState(false);
  const [showToast, setShowToast] = useState<{ text: string; show: boolean }>({
    text: '',
    show: false
  });
  const [openInvite, setOpenInvite] = useState(false);

  if (!showImgStorage && !showFileStorage && !openChangeName && !openInvite) {
    return (
      <WebServiceMessengerChat
        showImgStorage={showImgStorage}
        setShowImgStorage={setShowImgStorage}
        showFileStorage={showFileStorage}
        setShowFileStorage={setShowFileStorage}
        openChangeName={openChangeName}
        setOpenChangeName={setOpenChangeName}
        showToast={showToast}
        setShowToast={setShowToast}
        setOpenInvite={setOpenInvite}
      />
    );
  } else if (
    showImgStorage &&
    !showFileStorage &&
    !openChangeName &&
    !openInvite
  ) {
    return (
      <WebServiceMessengerChatImgStorage
        showImgStorage={showImgStorage}
        setShowImgStorage={setShowImgStorage}
      />
    );
  } else if (
    !showImgStorage &&
    !showFileStorage &&
    openChangeName &&
    !openInvite
  ) {
    return (
      <WebServiceMessengerChatChangeName
        openChangeName={openChangeName}
        setOpenChangeName={setOpenChangeName}
        setShowToast={setShowToast}
      />
    );
  } else if (
    !showImgStorage &&
    !showFileStorage &&
    !openChangeName &&
    openInvite
  ) {
    return (
      <WebServiceMessengerChatInvite
        openInvite={openInvite}
        setOpenInvite={setOpenInvite}
      />
    );
  } else {
    return (
      <WebServiceMessengerChatFileStorage
        showFileStorage={showFileStorage}
        setShowFileStorage={setShowFileStorage}
      />
    );
  }
};

export default WebServiceMessengerControl;
