/* eslint-disable react/prop-types */
/* eslint-disable operator-linebreak */
import React from 'react';
import WebServicePrimaryButton from '../button/primary_button';

const WebServiceBottomFixedPrimaryButton = (props: any) => {
  const { isDisabled, buttonName } = props;

  return (
    <div className="web-service-bottom-sheet-button-conatiner">
      <WebServicePrimaryButton
        isDisabled={isDisabled}
        buttonName={buttonName}
      />
    </div>
  );
};

export default WebServiceBottomFixedPrimaryButton;
