/* eslint-disable object-curly-spacing */
/* eslint-disable indent */
/* eslint-disable operator-linebreak */
import React from 'react';
import 'stylesheets/web_service/search.scss';
import store from 'redux/store';
import { Link } from 'react-router-dom';

const WebServiceSearchResultCardCallHistoryButton = (props: any) => {
  const { imgName, text, onButtonClick, link } = props;
  return (
    <Link to={link}>
      <div
        className="web-service-search-result-card-call-history-button-wrap"
        onClick={onButtonClick}
      >
        <div className="web-service-search-result-card-call-history-active-icon-wrap">
          <img
            src={`/icon/${
              store.getState().theme.theme === 'light'
                ? 'lightmode/'
                : 'darkmode/'
            }${imgName}-outline-t2.png`}
            className="web-service-search-result-card-call-history-active-icon"
            alt="profile image"
          />
        </div>
        <div className="web-service-search-result-card-call-history-active-icon-name">
          {text}
        </div>
      </div>
    </Link>
  );
};

export default WebServiceSearchResultCardCallHistoryButton;
