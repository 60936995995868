/* eslint-disable react/prop-types */
/* eslint-disable operator-linebreak */
import React from 'react';
import 'stylesheets/web_service/more.scss';
import store from 'redux/store';

const WebServiceNoticeListCard = (props: any) => {
  const { title, date, bottomBorder } = props;

  return (
    <div className="web-service-notice-list-card-wrap">
      <div
        className={
          'web-service-notice-list-card' +
          (bottomBorder ? ' bottom-border' : '')
        }
      >
        <div className="web-service-notice-list-card-left">
          <div className="web-service-notice-list-card-title">{title}</div>
          <div className="web-service-notice-list-card-date">{date}</div>
        </div>
        <img
          src={
            '/icon/' +
            (store.getState().theme.theme === 'light'
              ? 'lightmode/'
              : 'darkmode/') +
            'arrow_right-t3.png'
          }
          className="web-service-notice-list-card-arrow"
          alt="right arrow"
        />
      </div>
    </div>
  );
};

export default WebServiceNoticeListCard;
