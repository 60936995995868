/* eslint-disable react/prop-types */
/* eslint-disable operator-linebreak */
import React from 'react';
import WebServicePrimaryButton from '../button/primary_button';

// body scroll disable need

const WebServiceOkModal = (props: any) => {
  const { title, text, buttonName, openModal, setOpenModal, onClick } = props;

  return (
    <div className={'web-service-sheet-area' + (openModal ? ' active' : '')}>
      <div
        className={'web-service-overlay' + (openModal ? ' active' : '')}
        onClick={() => {
          setOpenModal(false);
        }}
      />
      <div className="web-service-modal-container">
        <div className="web-service-modal-title-wrap">{title}</div>
        <div className="web-service-modal-text-wrap lh-P">{text}</div>
        <div className="web-service-modal-btn-wrap">
          <WebServicePrimaryButton buttonName={buttonName} onClick={onClick} />
        </div>
      </div>
    </div>
  );
};

export default WebServiceOkModal;
