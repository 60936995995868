/* eslint-disable max-len */
/* eslint-disable indent */
import WebServiceNormalBody from 'components/web_service/body/normal_body';
import WebServiceSubTitle from 'components/web_service/content_title/sub_title';
import WebServiceBottomFixedPrimaryButton from 'components/web_service/fixed_component/primary_button';
import WebServiceCenterTitleHeader from 'components/web_service/header/center_title';
import WebServiceMessengerFileInStorage from 'components/web_service/messenger/file_in_storage';
import WebServiceWrapper from 'components/web_service_wrapper';
import React, { useState } from 'react';

const WebServiceMessengerChatFileStorage = (props: any) => {
  const { showFileStorage, setShowFileStorage } = props;
  const [isFileChecked, setIsFileChecked] = useState<number[]>([]);
  const [fileSelectMode, setFileSelectMode] = useState(false);
  const fileData = [
    {
      name: 'zeka.docxzeka.docxzeka.docxzeka.docxzeka.docxzeka.docxzeka.docxzeka.docxzeka.docx',
      fileFormat: 'docx',
      subinfo: '9.1 MB, 유효기간 ~ 12.11',
      downloaded: false
    },
    {
      name: 'super.docx',
      fileFormat: 'docx',
      subinfo: '9.1 MB, 유효기간 ~ 12.11',
      downloaded: true
    },
    {
      name: 'zipzoong.zip',
      fileFormat: 'zip',
      subinfo: '9.1 MB, 유효기간 ~ 12.11',
      downloaded: true
    }
  ];
  const fileCheckboxHandleChange = (idx: number) => {
    if (fileSelectMode) {
      if (isFileChecked.includes(idx)) {
        const activeList = isFileChecked.filter((i) => idx !== i);
        setIsFileChecked(activeList);
      } else {
        setIsFileChecked([...isFileChecked, idx]);
      }
    }
  };
  return (
    <WebServiceWrapper hideNav={false} isCalling={false} scrollLock={''}>
      <WebServiceCenterTitleHeader
        title="파일 전체보기"
        rightIcon={!fileSelectMode ? 'delete-outline-t1' : null}
        rightText={fileSelectMode ? '취소' : null}
        rightOnClick={() => setFileSelectMode(!fileSelectMode)}
        backState={showFileStorage}
        setBackState={setShowFileStorage}
        leftIcon="close"
      />
      <WebServiceNormalBody type="normal" useOverlay={false} background="">
        <WebServiceSubTitle title="2023.01.23" />
        {fileData.map((item: any, idx: number) => (
          <WebServiceMessengerFileInStorage
            key={idx}
            fileFormat={item.fileFormat}
            downloaded={item.downloaded}
            name={item.name}
            subinfo={item.subinfo}
            isFileChecked={isFileChecked.includes(idx)}
            onClick={() => fileCheckboxHandleChange(idx)}
            fileSelectMode={fileSelectMode}
          />
        ))}
      </WebServiceNormalBody>
      <WebServiceBottomFixedPrimaryButton
        buttonName={
          isFileChecked.length > 0
            ? isFileChecked.length + '개 항목 삭제'
            : '삭제'
        }
        isDisabled={isFileChecked.length < 1}
      />
    </WebServiceWrapper>
  );
};

export default WebServiceMessengerChatFileStorage;
