/* eslint-disable react/prop-types */
/* eslint-disable operator-linebreak */
import React from 'react';
import WebServicePrimaryButton from '../button/primary_button';
import WebServiceSecondaryButton from '../button/secondary_button';

// body scroll disable need

const WebServiceYNModal = (props: any) => {
  const {
    title,
    text,
    primaryButtonName,
    onPrimaryClick,
    secondaryButtonName,
    onSecondaryClick,
    openModal,
    setOpenModal
  } = props;

  return (
    <div className={'web-service-sheet-area' + (openModal ? ' active' : '')}>
      <div
        className={'web-service-overlay' + (openModal ? ' active' : '')}
        onClick={() => {
          setOpenModal(false);
        }}
      />
      <div className="web-service-modal-container">
        <div className="web-service-modal-title-wrap">{title}</div>
        <div className="web-service-modal-text-wrap lh-P">{text}</div>
        <div className="web-service-modal-btn-wrap">
          <WebServiceSecondaryButton
            size="normal"
            buttonName={secondaryButtonName}
            onClick={onSecondaryClick}
          />
          <WebServicePrimaryButton
            buttonName={primaryButtonName}
            onClick={onPrimaryClick}
          />
        </div>
      </div>
    </div>
  );
};

export default WebServiceYNModal;
