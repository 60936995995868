/* eslint-disable react/prop-types */
/* eslint-disable operator-linebreak */
import React from 'react';

const WebServiceSecondaryButton = (props: any) => {
  const { isDisabled, buttonName, size, colorAdjust, onClick } = props;

  return (
    <button
      className={`web-service-secondary-button${
        isDisabled ? ' diabled' : ''
      } ${size}${colorAdjust === 'down' ? ' down' : ' '} ${
        colorAdjust === 'up' ? ' up' : ''
      }`}
      onClick={onClick}
    >
      {buttonName}
    </button>
  );
};

export default WebServiceSecondaryButton;
