/* eslint-disable max-len */
import WebServiceNoticeListCard from 'components/more/notice_list_card';
import WebServiceNormalBody from 'components/web_service/body/normal_body';
import WebServiceCenterTitleHeader from 'components/web_service/header/center_title';
import WebServiceWrapper from 'components/web_service_wrapper';
import routes from 'configs/routes';
import React from 'react';
import { Link } from 'react-router-dom';

const WebServiceNoticeList = () => {
  const notice = [
    {
      title: '공지사항 제목입니다.',
      date: '2023.01.04',
      bottomBorder: true
    },
    {
      title:
        '공지사항 제목입니다. 이름이 길어져도 한줄 이상 노출되지 않습니다.',
      date: '2023.01.04',
      bottomBorder: true
    },
    {
      title:
        '공지사항 제목입니다. 이름이 길어져도 한줄 이상 노출되지 않습니다.',
      date: '2023.01.04',
      bottomBorder: true
    },
    {
      title: '공지사항 제목입니다.',
      date: '2023.01.04',
      bottomBorder: true
    },
    {
      title: '공지사항 제목입니다.',
      date: '2023.01.04',
      bottomBorder: true
    },
    {
      title:
        '공지사항 제목입니다. 이름이 길어져도 한줄 이상 노출되지 않습니다.',
      date: '2023.01.04',
      bottomBorder: true
    },
    {
      title: '공지사항 제목입니다.',
      date: '2023.01.04',
      bottomBorder: true
    },
    {
      title: '공지사항 제목입니다.',
      date: '2023.01.04',
      bottomBorder: true
    },
    {
      title: '공지사항 제목입니다.',
      date: '2023.01.04',
      bottomBorder: true
    },
    {
      title:
        '공지사항 제목입니다. 이름이 길어져도 한줄 이상 노출되지 않습니다.',
      date: '2023.01.04',
      bottomBorder: false
    }
  ];
  return (
    <WebServiceWrapper hideNav={false} isCalling={false} scrollLock={false}>
      <WebServiceCenterTitleHeader title="공지사항" />
      <WebServiceNormalBody type="more-78" background="" useOverlay={false}>
        {notice.map((item: any, idx: number) => (
          <Link to={routes.web_service_more_notice_read} key={idx}>
            <WebServiceNoticeListCard
              title={item.title}
              date={item.date}
              bottomBorder={item.bottomBorder}
            />
          </Link>
        ))}
      </WebServiceNormalBody>
    </WebServiceWrapper>
  );
};

export default WebServiceNoticeList;
