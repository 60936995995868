/* eslint-disable operator-linebreak */
import { createSlice } from '@reduxjs/toolkit';

type forwardingSettingState = {
  useForwarding: boolean;
  forwardingNumber: string;
};

const initialState: forwardingSettingState = {
  useForwarding: false,
  forwardingNumber: ''
};

export const forwardingSettingSlice = createSlice({
  name: 'forwardingSetting',
  initialState,
  reducers: {
    switchUseForwarding: (state) => {
      state.useForwarding = !state.useForwarding;
    },
    changeForwardingNumber: (state, action) => {
      state.forwardingNumber = action.payload;
    }
  }
});

export const { switchUseForwarding, changeForwardingNumber } =
  forwardingSettingSlice.actions;

export default forwardingSettingSlice.reducer;
