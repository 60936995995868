/* eslint-disable operator-linebreak */
import React from 'react';
import store from 'redux/store';
import { useNavigate } from 'react-router-dom';

import 'stylesheets/web_service/nav.scss';

const WebServiceCenterTitleHeader = (props: any) => {
  const navigate = useNavigate();
  const {
    title,
    rightText,
    rightIcon,
    rightOnClick,
    backState,
    setBackState,
    leftIcon
  } = props;

  const goBack = () => {
    if (backState) {
      setBackState(false);
    } else navigate(-1);
  };

  return (
    <div className="web-service-center-title-header-container">
      <div className="web-service-center-title-header-left" onClick={goBack}>
        <img
          src={
            leftIcon === 'close'
              ? '/icon/' +
                (store.getState().theme.theme === 'light'
                  ? 'lightmode/'
                  : 'darkmode/') +
                'close-t1.png'
              : '/icon/' +
                (store.getState().theme.theme === 'light'
                  ? 'lightmode/'
                  : 'darkmode/') +
                'arrow_left-t1.png'
          }
          className="web-service-center-title-left-icon"
          alt="back"
        />
      </div>
      <div className="web-service-center-title-header-center">
        <div className="web-service-center-title-header-title">{title}</div>
      </div>
      <div
        className={
          'web-service-center-title-header-right' +
          (rightIcon || rightText ? ' useArea' : '')
        }
        onClick={rightOnClick}
      >
        {rightIcon ? (
          <img
            src={
              '/icon/' +
              (store.getState().theme.theme === 'light'
                ? 'lightmode/'
                : 'darkmode/') +
              rightIcon +
              '.png'
            }
            className="web-service-center-title-left-icon"
            alt="action"
          />
        ) : null}
        {rightText ? rightText : null}
      </div>
    </div>
  );
};

export default WebServiceCenterTitleHeader;
