/* eslint-disable react/prop-types */
/* eslint-disable operator-linebreak */
import React from 'react';
import 'stylesheets/web_service/more.scss';

const WebServiceMoreTextarea = (props: any) => {
  return (
    <>
      <div className="web-service-more-type-textarea-wrap">
        <textarea
          className="web-service-more-type-textarea"
          placeholder="안내문구를 입력하세요."
          spellCheck={false}
        ></textarea>
      </div>
      <div className="web-service-more-type-bottom-wrap">
        <div className="web-service-more-type-max-length">52/100</div>
        <div className="web-service-more-type-img-wrap">
          <img
            src="/icon/common/volume.png"
            className="web-service-more-type-img"
            alt="미리듣기"
          />
        </div>
      </div>
    </>
  );
};

export default WebServiceMoreTextarea;
