/* eslint-disable react/prop-types */
/* eslint-disable operator-linebreak */
import React from 'react';

const WebServiceSubTitle = (props: any) => {
  const { title, underline } = props;

  return (
    <div className="web-service-sub-title-wrap">
      <div className={'web-service-subtitle' + (underline ? ' underline' : '')}>
        {title}
      </div>
    </div>
  );
};

export default WebServiceSubTitle;
