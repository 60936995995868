/* eslint-disable operator-linebreak */
import { createSlice } from '@reduxjs/toolkit';

type ringingToneSettingState = {
  useRingingTone: boolean;
  ringingTone: { name: string; type: string; selected: boolean }[];
  ringingToneVoiceType: { name: string; type: string; selected: boolean }[];
};

const initialState: ringingToneSettingState = {
  useRingingTone: false,
  ringingTone: [
    { name: 'HOW ARE YOU', type: 'radioOnly', selected: true },
    { name: 'Waltz For A Child', type: 'radioOnly', selected: false },
    { name: 'Morning', type: 'radioOnly', selected: false }
  ],
  ringingToneVoiceType: [
    { name: '남', type: 'radioOnly', selected: true },
    { name: '여', type: 'radioOnly', selected: false }
  ]
};

export const ringingToneSettingSlice = createSlice({
  name: 'ringingToneSetting',
  initialState,
  reducers: {
    switchUseRingingTone: (state) => {
      state.useRingingTone = !state.useRingingTone;
    },
    selectRingingTone: (state, action) => {
      state.ringingTone.forEach(
        (item: any, idx: number) => (state.ringingTone[idx].selected = false)
      );
      state.ringingTone[action.payload].selected = true;
    },
    selectRingingToneVoiceType: (state, action) => {
      state.ringingToneVoiceType.forEach(
        (item: any, idx: number) =>
          (state.ringingToneVoiceType[idx].selected = false)
      );
      state.ringingToneVoiceType[action.payload].selected = true;
    }
  }
});

export const {
  switchUseRingingTone,
  selectRingingTone,
  selectRingingToneVoiceType
} = ringingToneSettingSlice.actions;

export default ringingToneSettingSlice.reducer;
