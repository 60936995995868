/* eslint-disable indent */
/* eslint-disable operator-linebreak */
import React from 'react';

import 'stylesheets/web_service/common.scss';

type Props = {
  children: any;
  type: string;
  useOverlay: boolean;
  background: string;
};

const WebServiceNormalBody = ({
  children,
  type,
  useOverlay,
  background
}: Props) => {
  return (
    <div
      className={
        'web-service-normal-body ' +
        type +
        (useOverlay ? ' overlay' : '') +
        (background === 'bg4' ? ' bg4' : '')
      }
    >
      {children}
    </div>
  );
};

export default WebServiceNormalBody;
