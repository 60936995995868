/* eslint-disable max-len */
import WebServiceNormalBody from 'components/web_service/body/normal_body';
import WebServiceContactMemo from 'components/web_service/contact/memo_card';
import WebServiceBottomFixedPrimaryButton from 'components/web_service/fixed_component/primary_button';
import WebServiceCenterTitleHeader from 'components/web_service/header/center_title';
import WebServiceWrapper from 'components/web_service_wrapper';
import React, { useState } from 'react';

const WebServiceMemoLists = () => {
  const [checkMode, setCheckMode] = useState(false);
  const [isChecked, setIsChecked] = useState<number[]>([]);
  const memoDate = [
    {
      text: 'PB 교육 관련 신규 이슈 논의 필요. 1시-2시에 다시 통화 하기로 함. 통화 전 준비자료 미리 숙지.PB 교육 관련 신규 이슈 논의 필요. 1시-2시에 다시 통화 하기로 함. 통화 전 준비자료 미리 숙지.PB교육 관련 신규 이슈 논의 필요. 1시-2시에 다시 통화 하기로 함. 통화 전 준비자료 미리 숙지.',
      date: '오전 10:35'
    },
    {
      text: 'PB 교육 관련 신규 이슈 논의 필요. 1시-2시에 다시 통화 하기로 함. 통화 전 준비자료 미리 숙지.PB 교육 관련 신규 이슈 논의 필요. 1시-2시에 다시 통화 하기로 함. 통화 전 준비자료 미리 숙지.PB교육 관련 신규 이슈 논의 필요. 1시-2시에 다시 통화 하기로 함. 통화 전 준비자료 미리 숙지.',
      date: '오전 10:35'
    },
    {
      text: 'PB 교육 관련 신규 이슈 논의 필요. 1시-2시에 다시 통화 하기로 함. 통화 전 준비자료 미리 숙지.PB 교육 관련 신규 이슈 논의 필요. 1시-2시에 다시 통화 하기로 함. 통화 전 준비자료 미리 숙지.PB교육 관련 신규 이슈 논의 필요. 1시-2시에 다시 통화 하기로 함. 통화 전 준비자료 미리 숙지.',
      date: '오전 10:35'
    },
    {
      text: 'PB 교육 관련 신규 이슈 논의 필요. 1시-2시에 다시 통화 하기로 함. 통화 전 준비자료 미리 숙지.PB 교육 관련 신규 이슈 논의 필요. 1시-2시에 다시 통화 하기로 함. 통화 전 준비자료 미리 숙지.PB교육 관련 신규 이슈 논의 필요. 1시-2시에 다시 통화 하기로 함. 통화 전 준비자료 미리 숙지.',
      date: '오전 10:35'
    },
    {
      text: 'PB 교육 관련 신규 이슈 논의 필요. 1시-2시에 다시 통화 하기로 함. 통화 전 준비자료 미리 숙지.PB 교육 관련 신규 이슈 논의 필요. 1시-2시에 다시 통화 하기로 함. 통화 전 준비자료 미리 숙지.PB교육 관련 신규 이슈 논의 필요. 1시-2시에 다시 통화 하기로 함. 통화 전 준비자료 미리 숙지.',
      date: '오전 10:35'
    }
  ];

  const checkboxHandleChange = (idx: number) => {
    if (checkMode) {
      if (isChecked.includes(idx)) {
        const activeList = isChecked.filter((i) => idx !== i);
        setIsChecked(activeList);
      } else {
        setIsChecked([...isChecked, idx]);
      }
    }
  };

  return (
    <WebServiceWrapper hideNav={false} isCalling={false} scrollLock={false}>
      <WebServiceCenterTitleHeader
        title="홍길동"
        rightIcon={checkMode ? '' : 'delete-outline-t1'}
        rightText={checkMode ? '취소' : ''}
        rightOnClick={() => setCheckMode(!checkMode)}
      />
      <WebServiceNormalBody type="normal" useOverlay={false} background="">
        <div className="web-service-page-title">메모</div>
        <div className="web-service-memo-card-set">
          {memoDate.map((item: any, idx: number) => (
            <WebServiceContactMemo
              key={idx}
              text={item.text}
              date={item.date}
              checkMode={checkMode}
              isChecked={isChecked?.includes(idx)}
              onClick={() => checkboxHandleChange(idx)}
            />
          ))}
        </div>
        {checkMode ? (
          <WebServiceBottomFixedPrimaryButton
            buttonName={
              isChecked.length > 0
                ? isChecked.length + '개 항목 삭제'
                : '삭제할 항목을 선택하세요'
            }
            isDisabled={isChecked.length > 0 ? false : true}
          />
        ) : (
          ''
        )}
      </WebServiceNormalBody>
    </WebServiceWrapper>
  );
};

export default WebServiceMemoLists;
