/* eslint-disable object-curly-spacing */
/* eslint-disable indent */
/* eslint-disable operator-linebreak */
import React from 'react';
import 'stylesheets/web_service/search.scss';
import WebServiceInviteSelectedUser from './selected_user';

const WebServiceInviteSelectedGroup = (props: any) => {
  const { users } = props;
  return (
    <div className="web-service-invite-selected-group">
      {users.map((user: any, idx: any) => (
        <WebServiceInviteSelectedUser name={user.name} key={idx} />
      ))}
    </div>
  );
};

export default WebServiceInviteSelectedGroup;
