/* eslint-disable react/prop-types */
/* eslint-disable operator-linebreak */
import React from 'react';
import store from 'redux/store';
import 'stylesheets/web_service/contact.scss';

const WebServiceProfileCallLog = (props: any) => {
  const { callType, time, setOpenRecord } = props;
  return (
    <div
      className="web-service-contact-profile-call-log"
      onClick={() => setOpenRecord(true)}
    >
      <div className="web-service-contact-profile-call-log-left">
        <img
          src={
            '/icon/' +
            (store.getState().theme.theme === 'light'
              ? 'lightmode/'
              : 'darkmode/') +
            'call_' +
            callType +
            '.png'
          }
          className="web-service-contact-profile-call-log-icon"
          alt="call type"
        />
        <div className="web-service-contact-profile-call-log-time">{time}</div>
      </div>
      <div className="web-service-contact-profile-call-log-record-wrap">
        <img
          src={
            '/icon/' +
            (store.getState().theme.theme === 'light'
              ? 'lightmode/'
              : 'darkmode/') +
            'play-fill-t2.png'
          }
          alt="call type"
          className="web-service-contact-profile-call-log-record-icon"
        />
      </div>
    </div>
  );
};

export default WebServiceProfileCallLog;
