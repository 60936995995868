/* eslint-disable operator-linebreak */
import { createSlice } from '@reduxjs/toolkit';

type availableSettingState = {
  useAvailableTime: boolean;
  availableStartTime: string;
  availableEndTime: string;
  availableDate: { name: string; selected: boolean }[];
  availableVoice: {
    name: string;
    type: string;
    stateType: string;
    selected: boolean;
  }[];
};

const initialState: availableSettingState = {
  useAvailableTime: false,
  availableStartTime: '09:00',
  availableEndTime: '18:00',
  availableDate: [
    { name: '월', selected: true },
    { name: '화', selected: true },
    { name: '수', selected: true },
    { name: '목', selected: true },
    { name: '금', selected: true },
    { name: '토', selected: true },
    { name: '일', selected: true }
  ],
  availableVoice: [
    {
      name: '근무시간이 아닙니다. 다시 전화주세요.',
      type: 'radioOnly',
      stateType: 'normal',
      selected: true
    },
    {
      name: '휴가중입니다. 다시 전화주세요.',
      type: 'radioOnly',
      stateType: 'normal',
      selected: false
    },
    {
      name: '직접입력',
      type: 'radioOnly',
      stateType: 'custom',
      selected: false
    }
  ]
};

export const availableSettingSlice = createSlice({
  name: 'availableSetting',
  initialState,
  reducers: {
    switchUseAvailableTime: (state) => {
      state.useAvailableTime = !state.useAvailableTime;
    },
    selectAvailableVoice: (state, action) => {
      state.availableVoice.forEach(
        (item: any, idx: number) => (state.availableVoice[idx].selected = false)
      );
      state.availableVoice[action.payload].selected = true;
    },
    selectAvailableDate: (state, action) => {
      state.availableDate[action.payload].selected =
        !state.availableDate[action.payload].selected;
    },
    changeAvailableStartTime: (state, action) => {
      state.availableStartTime = action.payload;
    },
    changeAvailableEndTime: (state, action) => {
      state.availableEndTime = action.payload;
    }
  }
});

export const {
  switchUseAvailableTime,
  selectAvailableVoice,
  selectAvailableDate,
  changeAvailableStartTime,
  changeAvailableEndTime
} = availableSettingSlice.actions;

export default availableSettingSlice.reducer;
