/* eslint-disable object-curly-spacing */
/* eslint-disable indent */
/* eslint-disable operator-linebreak */
import React from 'react';
import 'stylesheets/web_service/search.scss';
import WebServiceSearchResultCardContact from './result_card_contact';
import WebServiceSearchResultCardCallHistory from './result_card_call_history';
import WebServiceSubTitle from '../content_title/sub_title';
import WebServiceSMSListCard from '../sms/sms_list_card';

const WebServiceSearchResultGroupShort = (props: any) => {
  const { type, data, setTabSelected, onCardClick } = props;

  return (
    <div className="web-service-search-group-short">
      <WebServiceSubTitle
        title={
          type === 'contact'
            ? '연락처'
            : type === 'call_log'
            ? '통화기록'
            : type === 'sms'
            ? '문자메시지'
            : type === 'messenger'
            ? '채팅'
            : null
        }
      />
      {type === 'contact' && data.length < 3
        ? data.map((item: any, idx: any) => (
            <WebServiceSearchResultCardContact
              key={idx}
              equalName={item.equalName}
              name={item.name}
              equalNumber={item.equalNumber}
              number={item.number}
              profile={'profile.jpg'}
              state={item.state}
              hoverEffect={true}
              onClick={() => onCardClick(item.number)}
            />
          ))
        : null}
      {type === 'contact' && data.length >= 3 ? (
        <>
          <WebServiceSearchResultCardContact
            equalName={data[0].equalName}
            name={data[0].name}
            equalNumber={data[0].equalNumber}
            number={data[0].number}
            profile={'profile.jpg'}
            state={data[0].state}
            hoverEffect={true}
            onClick={() => onCardClick(data[0].number)}
          />
          <WebServiceSearchResultCardContact
            equalName={data[1].equalName}
            name={data[1].name}
            equalNumber={data[1].equalNumber}
            number={data[1].number}
            profile={'profile.jpg'}
            state={data[1].state}
            hoverEffect={true}
            onClick={() => onCardClick(data[1].number)}
          />
          <WebServiceSearchResultCardContact
            equalName={data[2].equalName}
            name={data[2].name}
            equalNumber={data[2].equalNumber}
            number={data[2].number}
            profile={'profile.jpg'}
            state={data[2].state}
            hoverEffect={true}
            onClick={() => onCardClick(data[2].number)}
          />
        </>
      ) : null}
      {type === 'call_log' && data.length < 3
        ? data.map((item: any, idx: any) => (
            <WebServiceSearchResultCardCallHistory
              callType={item.callType}
              equalName={item.equalName}
              name={item.name}
              time={item.time}
              canSpread={false}
              key={idx}
              profile={'profile.jpg'}
            />
          ))
        : null}
      {type === 'call_log' && data.length >= 3 ? (
        <>
          <WebServiceSearchResultCardCallHistory
            callType={data[0].callType}
            equalName={data[0].equalName}
            name={data[0].name}
            time={data[0].time}
            canSpread={false}
            profile={'profile.jpg'}
          />
          <WebServiceSearchResultCardCallHistory
            callType={data[1].callType}
            equalName={data[1].equalName}
            name={data[1].name}
            time={data[1].time}
            canSpread={false}
            profile={'profile.jpg'}
          />
          <WebServiceSearchResultCardCallHistory
            callType={data[2].callType}
            equalName={data[2].equalName}
            name={data[2].name}
            time={data[2].time}
            canSpread={false}
            profile={'profile.jpg'}
          />
        </>
      ) : null}
      {type === 'sms' && data.length < 3
        ? data.map((item: any, idx: any) => (
            <WebServiceSMSListCard
              name={item.name}
              text={item.text}
              date={item.date}
              alarmAmount={item.alarmAmount}
              editMode={false}
              key={idx}
              underline={true}
              profile={'profile.jpg'}
            />
          ))
        : null}
      {type === 'sms' && data.length >= 3 ? (
        <>
          <WebServiceSMSListCard
            name={data[0].name}
            text={data[0].text}
            date={data[0].date}
            alarmAmount={data[0].alarmAmount}
            editMode={false}
            underline={true}
            profile={'profile.jpg'}
          />
          <WebServiceSMSListCard
            name={data[1].name}
            text={data[1].text}
            date={data[1].date}
            alarmAmount={data[1].alarmAmount}
            editMode={false}
            underline={true}
            profile={'profile.jpg'}
          />
          <WebServiceSMSListCard
            name={data[2].name}
            text={data[2].text}
            date={data[2].date}
            alarmAmount={data[2].alarmAmount}
            editMode={false}
            underline={true}
            profile={'profile.jpg'}
          />
        </>
      ) : null}
      {type === 'messenger' && data.length < 3
        ? data.map((item: any, idx: any) => (
            <WebServiceSMSListCard
              name={item.name}
              text={item.text}
              date={item.date}
              alarmAmount={item.alarmAmount}
              editMode={false}
              key={idx}
              underline={true}
              profile={'profile.jpg'}
            />
          ))
        : null}
      {type === 'messenger' && data.length >= 3 ? (
        <>
          <WebServiceSMSListCard
            name={data[0].name}
            text={data[0].text}
            date={data[0].date}
            alarmAmount={data[0].alarmAmount}
            editMode={false}
            underline={true}
            profile={'profile.jpg'}
          />
          <WebServiceSMSListCard
            name={data[1].name}
            text={data[1].text}
            date={data[1].date}
            alarmAmount={data[1].alarmAmount}
            editMode={false}
            underline={true}
            profile={'profile.jpg'}
          />
          <WebServiceSMSListCard
            name={data[2].name}
            text={data[2].text}
            date={data[2].date}
            alarmAmount={data[2].alarmAmount}
            editMode={false}
            underline={true}
            profile={'profile.jpg'}
          />
        </>
      ) : null}
      <div
        className="web-service-search-result-group-short-seeAll"
        onClick={() => setTabSelected(type === 'contact' ? 1 : 2)}
      >
        {type === 'contact' ? '연락처' : null}
        {type === 'call_log' ? '통화기록' : null}
        {type === 'sms' ? '문자메시지' : null} 전체보기
      </div>
    </div>
  );
};

export default WebServiceSearchResultGroupShort;
