/* eslint-disable object-curly-spacing */
/* eslint-disable indent */
/* eslint-disable operator-linebreak */
import React from 'react';
import 'stylesheets/web_service/messenger.scss';
import store from 'redux/store';
import WebServiceCheckbox from '../input/checkbox';

const WebServiceMessengerFileInStorage = (props: any) => {
  const {
    fileFormat,
    name,
    subinfo,
    downloaded,
    isFileChecked,
    onClick,
    fileSelectMode
  } = props;
  return (
    <div className="web-service-messenger-file-in-storage" onClick={onClick}>
      <div className="web-service-messenger-file-in-storage-icon-wrap">
        {downloaded ? (
          <img
            src={
              '/icon/' +
              (store.getState().theme.theme === 'light'
                ? 'lightmode/'
                : 'darkmode/') +
              (fileFormat === 'zip'
                ? 'compress_file-outline-p1'
                : fileFormat === 'docx'
                ? 'memo_file-outline-p1'
                : '') +
              '.png'
            }
            className="web-service-messenger-file-in-storage-icon"
            alt="file"
          />
        ) : (
          <img
            src={
              '/icon/' +
              (store.getState().theme.theme === 'light'
                ? 'lightmode/'
                : 'darkmode/') +
              'download-outline-t2.png'
            }
            className="web-service-messenger-file-in-storage-icon"
            alt="file"
          />
        )}
      </div>
      <div className="web-service-messenger-file-in-storage-text-wrap">
        <div className="web-service-messenger-file-in-storage-name">{name}</div>
        <div className="web-service-messenger-file-in-storage-subinfo">
          {subinfo}
        </div>
      </div>
      {fileSelectMode ? <WebServiceCheckbox isChecked={isFileChecked} /> : null}
    </div>
  );
};

export default WebServiceMessengerFileInStorage;
