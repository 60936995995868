/* eslint-disable indent */
import React, { useState } from 'react';

import WebServiceWrapper from 'components/web_service_wrapper';
import WebServiceNormalBody from 'components/web_service/body/normal_body';
import WebServiceCenterTitleHeader from 'components/web_service/header/center_title';
import WebServiceMoreCard from 'components/more/card';
import { useSelector, useDispatch } from 'react-redux';
import {
  switchUseForwarding,
  changeForwardingNumber
} from 'redux/more/forwarding_setting';
import WebServiceBackTextHeader from 'components/web_service/header/back_text';
import WebServiceInput from 'components/web_service/input/input';
import { useEffect } from 'react';

const WebServiceMoreForwarding = () => {
  const [useTextInput, setUseTextInput] = useState(false);
  if (useTextInput) {
    return (
      <WebServiceMoreForwardingType
        useTextInput={useTextInput}
        setUseTextInput={setUseTextInput}
      />
    );
  } else {
    return (
      <WebServiceMoreForwardingSetting
        useTextInput={useTextInput}
        setUseTextInput={setUseTextInput}
      />
    );
  }
};

const WebServiceMoreForwardingType = (props: any) => {
  const dispatch = useDispatch();
  const { useTextInput, setUseTextInput } = props;
  const { forwardingSetting }: any = useSelector((state) => state);
  const [inputValue, setInputValue] = useState(
    forwardingSetting.forwardingNumber
  );
  const inputHandleChange = (e: any) => {
    const text = e.target.value.replace(/-/g, '');
    setInputValue(text);

    if (text.length === 9) {
      setInputValue(text.replace(/(\d{2})(\d{3})(\d{4})/, '$1-$2-$3'));
    } else if (text.length === 10 && text.substring(0, 2) === '02') {
      setInputValue(text.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3'));
    } else if (text.length === 10 && text.substring(0, 2) !== '02') {
      setInputValue(text.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'));
    } else if (text.length === 11) {
      setInputValue(text.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'));
    }
  };

  const onReset = () => {
    setInputValue('');
  };

  const submit = (inputValue: string) => {
    dispatch(changeForwardingNumber(inputValue));
    setTimeout(() => {
      setUseTextInput(false);
    }, 500);
  };

  useEffect(() => {}, [inputValue]);

  return (
    <WebServiceWrapper hideNav={false} isCalling={false} scrollLock={false}>
      <WebServiceBackTextHeader
        title="착신 전환 설정"
        rightButtonText="완료"
        rightOnClick={() => submit(inputValue)}
        backState={useTextInput}
        setBackState={setUseTextInput}
      />
      <WebServiceNormalBody type="normal" useOverlay={false} background="">
        <div className="web-service-more-type-textarea-wrap">
          <WebServiceInput
            hasLabel={false}
            type="withRemove"
            inputType="text"
            placeholder="착신 전환 번호"
            inputValue={inputValue}
            onChange={inputHandleChange}
            onReset={onReset}
          />
        </div>
      </WebServiceNormalBody>
    </WebServiceWrapper>
  );
};

const WebServiceMoreForwardingSetting = (props: any) => {
  const dispatch = useDispatch();
  const { forwardingSetting }: any = useSelector((state) => state);
  const { setUseTextInput } = props;
  return (
    <WebServiceWrapper hideNav={false} isCalling={false} scrollLock={false}>
      <WebServiceCenterTitleHeader title="착신 전환 설정" />
      <WebServiceNormalBody type="more-78" useOverlay={false} background="">
        <WebServiceMoreCard
          type="switch"
          name="착신 전환 사용"
          bottomBorder={forwardingSetting.useForwarding}
          isSwitchOn={forwardingSetting.useForwarding}
          onClick={() => dispatch(switchUseForwarding())}
        />
        <div
          className={
            'web-service-more-hide' +
            (forwardingSetting.useForwarding ? ' active mh200' : '')
          }
        >
          <WebServiceMoreCard
            type="arrowWithText"
            name="착신 전환 전화번호"
            bottomBorder={false}
            rightText={forwardingSetting.forwardingNumber}
            outerText="설정한 전화번호로 착신 전환됩니다."
            onClick={() => setUseTextInput(true)}
          />
        </div>
        <div className="web-service-more-forwarding-info-section">
          <div className="web-service-more-forwarding-info-wrap">
            <div className="web-service-more-forwarding-info-hypen">-</div>
            <div className="web-service-more-forwarding-info-text">
              착신전환 설정은 걸려오는 전화를 사용자가 지정한 전화번호로 자동
              연결시켜주는 서비스입니다.
            </div>
          </div>
          <div className="web-service-more-forwarding-info-wrap">
            <div className="web-service-more-forwarding-info-hypen">-</div>
            <div className="web-service-more-forwarding-info-text">
              부득이하게 전화를 받지 못하는 상황이라면 대신 전화를 받을 수 있는
              담당자의 사무실번호나 휴대전화번호를 입력하세요.
            </div>
          </div>
          <div className="web-service-more-forwarding-info-wrap">
            <div className="web-service-more-forwarding-info-hypen">-</div>
            <div className="web-service-more-forwarding-info-text">
              기본설정은 본인의 사무실번호로 걸려오는 전화가 휴대전화번호로
              연결되는 방식이므로 별도의 연결설정이 필요하지 않습니다.
            </div>
          </div>
        </div>
      </WebServiceNormalBody>
    </WebServiceWrapper>
  );
};

export default WebServiceMoreForwarding;
