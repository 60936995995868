/* eslint-disable operator-linebreak */
import React from 'react';

import { Link } from 'react-router-dom';

type MainNavItem = {
  iconUrl: string;
  menuLabel: string;
  link: string;
};

const MainNavItem = ({ iconUrl, menuLabel, link }: MainNavItem) => {
  const isActive = window.location.pathname === link;
  const _iconUrl = isActive
    ? `${iconUrl.split('.png')[0]}-active.png`
    : iconUrl;

  const classes = isActive ? 'main-nav-item active' : 'main-nav-item';

  return (
    <Link to={link}>
      <div className={classes}>
        <img src={_iconUrl} alt="icon" />
        <div>{menuLabel}</div>
      </div>
    </Link>
  );
};

export default MainNavItem;
