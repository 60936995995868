import React from 'react';

import 'stylesheets/landing_nav.scss';

const LandingNav = () => {
  return (
    <div className="landing-nav-container">
      <div className="landing-nav-logo">
        <img src="img/logo/horizontal_logo.png" alt="touchcall logo" />
      </div>
      <div className="landing-nav-menu-wrap">
        <div className="landing-nav-menu-btn">
          기능설명
          <div className="landing-nav-menu-btn-underline"></div>
        </div>
        <div className="landing-nav-menu-btn">
          다운로드
          <div className="landing-nav-menu-btn-underline"></div>
        </div>
        <div className="landing-nav-menu-btn">
          기타
          <div className="landing-nav-menu-btn-underline"></div>
        </div>
      </div>
    </div>
  );
};

export default LandingNav;
