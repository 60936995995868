/* eslint-disable react/prop-types */
/* eslint-disable operator-linebreak */
import React from 'react';
import store from 'redux/store';

const WebServiceChatInput = (props: any) => {
  const { inputValue, showReply, replyTarget, closeReply } = props;

  const handleChange = (e: any) => {
    const target = document.getElementById('textarea');
    if (target) {
      target.style.height = 'auto';
      target.style.height = `${target.scrollHeight}px`;
    }
  };

  return (
    <div className="web-service-chat-input-container">
      {showReply ? (
        <div className="web-service-chat-reply-wrap">
          <div className="web-service-chat-reply-contents">
            <div className="web-service-chat-reply-target">
              {replyTarget.name}에게 답장
            </div>
            <div className="web-service-chat-reply-text">
              {replyTarget.type === 'text' ? replyTarget.text : null}
              {replyTarget.type === 'image'
                ? '사진 ' + replyTarget.image.length + '장'
                : null}
              {replyTarget.type === 'file' ? replyTarget.fileName : null}
              {replyTarget.type === 'url' ? replyTarget.url : null}
            </div>
          </div>
          <div
            className="web-service-chat-reply-close-button-area"
            onClick={closeReply}
          >
            <img
              src={
                '/icon/' +
                (store.getState().theme.theme === 'light'
                  ? 'lightmode/'
                  : 'darkmode/') +
                'close-t1.png'
              }
              className="web-service-chat-reply-close-button"
              alt="reply close"
            />
          </div>
        </div>
      ) : (
        ''
      )}
      <div className="web-service-chat-input-area">
        <div className="web-service-chat-input-more-button">
          <img
            src={
              '/icon/' +
              (store.getState().theme.theme === 'light'
                ? 'lightmode/'
                : 'darkmode/') +
              'add_circle-outline-t1.png'
            }
            className="web-service-chat-input-more-icon"
            alt="more"
          />
        </div>

        <div className="web-service-chat-input-wrap">
          <textarea
            id="textarea"
            className="web-service-chat-input"
            value={inputValue}
            onChange={handleChange}
            rows={1}
          ></textarea>
          <div className="web-service-chat-input-submit-wrap">
            <button className="web-service-chat-input-submit">
              <img
                src="/icon/common/chat_submit.png"
                className="web-service-chat-input-more-icon"
                alt="more"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebServiceChatInput;
