/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';

import WebServiceWrapper from 'components/web_service_wrapper';
import WebServiceNormalBody from 'components/web_service/body/normal_body';
import WebServiceCallLogHeader from 'components/web_service/header/call_log';
// import WebServiceNoResult from 'components/web_service/no_result/no_result';
// import WebServiceSearchResultGroupCallLog from 'components/web_service/search/call_log/result_group_call_log';
import WebServiceSearchHeader from 'components/web_service/header/search';
import WebServiceBottomSheetRecord from 'components/web_service/fixed_component/record';
import WebServiceTopSheetMenuContainer from 'components/web_service/fixed_component/top_sheet_menu_container';
import WebServiceSubTitle from 'components/web_service/content_title/sub_title';
import WebServiceSearchResultCardCallHistory from 'components/web_service/search/result_card_call_history';
import { CallLogListItems } from 'configs/items';

const WebServiceCallLog = () => {
  const [selectedIdx, setSelectedIdx] = useState(0);
  const [openTopSheetMenu, setOpenTopSheetMenu] = useState(false);
  const [searchMod, setSearchMod] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [openRecord, setOpenRecord] = useState(false);
  const [isbottomSheetOpen, setIsBottomSheetOpen] = useState(false);
  useEffect(() => {
    if (openTopSheetMenu || openRecord) {
      setIsBottomSheetOpen(true);
    } else {
      setIsBottomSheetOpen(false);
    }
  }, [openTopSheetMenu, openRecord]);

  useEffect(() => {
    setOpenTopSheetMenu(false);
  }, [selectedIdx]);

  const handleChange = (e: any) => {
    setSearchValue(e.target.value);
  };

  const onReset = () => {
    setSearchValue('');
    console.log('sdfs');
  };

  const items = [
    { name: '전체 통화기록', type: 'radio' },
    { name: '발신전화', type: 'radio' },
    { name: '수신전화', type: 'radio' },
    { name: '부재중 전화', type: 'radio' },
    { name: '거절한 전화', type: 'radio' }
  ];

  return (
    <WebServiceWrapper
      hideNav={false}
      isCalling={false}
      scrollLock={isbottomSheetOpen}
    >
      {searchMod ? (
        <WebServiceSearchHeader
          onCancel={() => setSearchMod(false)}
          onSearchValueChange={handleChange}
          onSearchValueRemove={onReset}
          searchValue={searchValue}
        />
      ) : (
        <WebServiceCallLogHeader
          onDropdownClick={() => setOpenTopSheetMenu(!openTopSheetMenu)}
          selectedFilter={selectedIdx}
          items={items}
          onButtonClick={() => setSearchMod(true)}
        />
      )}
      <WebServiceNormalBody
        type="call-log"
        useOverlay={isbottomSheetOpen}
        background=""
      >
        <WebServiceSubTitle title={'2023년 1월 5일'} />
        {CallLogListItems.map((item: any, idx: number) => (
          <WebServiceSearchResultCardCallHistory
            key={idx}
            callType={item.callType}
            equalName={item.equalName}
            name={item.name}
            equalNumber={item.equalNumber}
            number={item.number}
            time={item.time}
            onRecordClick={() => setOpenRecord(true)}
            canSpread={true}
          />
        ))}
        {/* {dateList.map((item: any, idx: number) => console.log(item))} */}

        {CallLogListItems.map((item: any, idx: number) => (
          <WebServiceSearchResultCardCallHistory
            key={idx}
            callType={item.callType}
            equalName={item.equalName}
            name={item.name}
            equalNumber={item.equalNumber}
            number={item.number}
            time={item.time}
            onRecordClick={() => setOpenRecord(true)}
            canSpread={true}
          />
        ))}
      </WebServiceNormalBody>
      <WebServiceTopSheetMenuContainer
        openTopSheetMenu={openTopSheetMenu}
        setOpenTopSheetMenu={setOpenTopSheetMenu}
        items={items}
        selectedIdx={selectedIdx}
        setSelectedIdx={setSelectedIdx}
      />
      <WebServiceBottomSheetRecord
        openRecord={openRecord}
        setOpenRecord={setOpenRecord}
      />
    </WebServiceWrapper>
  );
};

export default WebServiceCallLog;
