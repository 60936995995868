/* eslint-disable no-unused-vars */
export enum Theme {
  dark = 'dark',
  light = 'light'
}

export enum Language {
  kr = 'korean',
  en = 'english',
  jp = 'japanese'
}

export enum BottomNavMenu {
  dial = 'dial',
  callLog = 'callLog',
  sms = 'sms',
  messenger = 'messenger',
  contact = 'contact',
  more = 'more'
}
