/* eslint-disable react/prop-types */
/* eslint-disable operator-linebreak */
import React from 'react';
import store from 'redux/store';

const WebServiceInput = (props: any) => {
  const {
    hasLabel,
    label,
    type,
    inputType,
    placeholder,
    inputValue,
    onChange,
    onReset,
    onInnerButtonClick,
    innerButtonName
  } = props;

  return (
    <div className="web-service-input-wrap">
      <div className={`web-service-input-label ${hasLabel} fs-14 fw-400`}>
        {/* boolean:false 일때만 display none */}
        {label}
      </div>
      <div className="web-service-input-wrap">
        <input
          className="web-service-input"
          type={inputType}
          placeholder={placeholder}
          value={inputValue}
          onChange={onChange}
        />
        {type === 'withRemove' && inputValue ? (
          <div className="web-service-input-delete-wrap" onClick={onReset}>
            <img
              src={
                '/icon/' +
                (store.getState().theme.theme === 'light'
                  ? 'lightmode/'
                  : 'darkmode/') +
                'close_circle-t4.png'
              }
              className="web-service-input-delete"
              alt="remove text"
            />
            <img
              src={
                '/icon/' +
                (store.getState().theme.theme === 'light'
                  ? 'lightmode/'
                  : 'darkmode/') +
                'close_circle-t2.png'
              }
              className="web-service-input-delete hover-effect"
              alt="remove text"
            />
          </div>
        ) : null}
        {type === 'innerButton' ? (
          <div
            className={
              'web-service-input-innerButton-wrap' +
              (inputValue.length > 9 ? ' active' : '')
            }
            onClick={onInnerButtonClick}
          >
            {innerButtonName}
          </div>
        ) : null}

        {type === 'timer' ? (
          <div className={'web-service-input-timer-wrap'}>3:00</div>
        ) : null}
      </div>
    </div>
  );
};

export default WebServiceInput;
