/* eslint-disable react/prop-types */
/* eslint-disable operator-linebreak */
import React from 'react';
import store from 'redux/store';

const WebServiceAlarmModal = (props: any) => {
  const { useAlarm, alarmShow } = props;

  return (
    <div className={'web-service-alarm-modal' + (alarmShow ? ' active' : '')}>
      <img
        src={
          '/icon/' +
          (store.getState().theme.theme === 'light'
            ? 'lightmode/'
            : 'darkmode/') +
          (useAlarm ? 'alarm-outline-t1.png' : 'alarm_off-outline-t1.png')
        }
        className="web-service-alarm-modal-icon"
        alt="alarm on"
      />
    </div>
  );
};

export default WebServiceAlarmModal;
