import React, { useState } from 'react';

import WebServiceWrapper from 'components/web_service_wrapper';
import WebServiceNormalBody from 'components/web_service/body/normal_body';
import WebServiceCenterTitleHeader from 'components/web_service/header/center_title';
import WebServiceMoreCard from 'components/more/card';
import { useSelector, useDispatch } from 'react-redux';
import {
  switchUseAbsenceVoice,
  selectAbsenceVoice,
  selectRingDuration
} from 'redux/more/absence_setting';
import WebServiceSubTitle from 'components/web_service/content_title/sub_title';
import WebServiceBackTextHeader from 'components/web_service/header/back_text';
import WebServiceMoreTextarea from 'components/more/textarea';

const WebServiceMoreAbsence = () => {
  const [useTextInput, setUseTextInput] = useState(false);
  if (useTextInput) {
    return (
      <WebServiceMoreAbsenceType
        useTextInput={useTextInput}
        setUseTextInput={setUseTextInput}
      />
    );
  } else {
    return (
      <WebServiceMoreAbsenceSetting
        useTextInput={useTextInput}
        setUseTextInput={setUseTextInput}
      />
    );
  }
};

const WebServiceMoreAbsenceType = (props: any) => {
  const { useTextInput, setUseTextInput } = props;
  return (
    <WebServiceWrapper hideNav={false} isCalling={false} scrollLock={false}>
      <WebServiceBackTextHeader
        title="부재중 안내문구"
        rightButtonText="완료"
        rightOnClick={() => setUseTextInput(false)}
        backState={useTextInput}
        setBackState={setUseTextInput}
      />
      <WebServiceNormalBody type="normal" useOverlay={false} background="">
        <WebServiceMoreTextarea />
      </WebServiceNormalBody>
    </WebServiceWrapper>
  );
};

const WebServiceMoreAbsenceSetting = (props: any) => {
  const { setUseTextInput } = props;
  const dispatch = useDispatch();
  const { absenceSetting }: any = useSelector((state) => state);
  const useAbsenceHandleChange = () => {
    dispatch(switchUseAbsenceVoice());
  };
  return (
    <WebServiceWrapper hideNav={false} isCalling={false} scrollLock={false}>
      <WebServiceCenterTitleHeader title="부재중 설정" />
      <WebServiceNormalBody type="more-78" useOverlay={false} background="">
        <WebServiceMoreCard
          type="switch"
          name="부재중 안내 사용"
          bottomBorder={false}
          outerText="부재중 ARS 음성과 시간을 사용합니다."
          isSwitchOn={absenceSetting.useAbsenceVoice}
          onClick={useAbsenceHandleChange}
        />
        <div
          className={
            'web-service-more-hide' +
            (absenceSetting.useAbsenceVoice ? ' active mh800' : '')
          }
        >
          <WebServiceSubTitle title="부재중 ARS 음성" />
          {absenceSetting.absenceVoice.map((item: any, idx: number) => (
            <WebServiceMoreCard
              key={idx}
              type={item.type}
              name={item.name}
              bottomBorder={absenceSetting.absenceVoice.length !== idx + 1}
              isRadioOn={item.selected}
              onClick={() => dispatch(selectAbsenceVoice(idx))}
            />
          ))}
          <div
            className={
              'web-service-more-hide' +
              (absenceSetting.absenceVoice.find(
                (item: any) => item.stateType === 'custom'
              ).selected
                ? ' active mh200'
                : '')
            }
          >
            <WebServiceSubTitle title="출력할 음성 설정" />
            <WebServiceMoreCard
              type="arrowOnly"
              name="출력할 음성"
              bottomBorder={false}
              innerText="안녕하세요.
믿음과 신뢰를 바탕으로 고객만족에 최선을 다하는 000 입니다.
전화주셔서 감사합니다. 오늘도 좋은하루되시길 기원하면서 오늘의 노래들려드립니다."
              onClick={() => setUseTextInput(true)}
            />
          </div>
          <WebServiceSubTitle title="통화연결음 지속시간" />
          {absenceSetting.ringDuration.map((item: any, idx: number) => (
            <WebServiceMoreCard
              key={idx}
              type={item.type}
              name={`${item.time}초`}
              bottomBorder={absenceSetting.ringDuration.length !== idx + 1}
              isRadioOn={item.selected}
              onClick={() => dispatch(selectRingDuration(idx))}
            />
          ))}
        </div>
      </WebServiceNormalBody>
    </WebServiceWrapper>
  );
};

export default WebServiceMoreAbsence;
