/* eslint-disable max-len */
/* eslint-disable indent */
import WebServiceNormalBody from 'components/web_service/body/normal_body';
import WebServiceCenterTitleHeader from 'components/web_service/header/center_title';
import WebServiceInput from 'components/web_service/input/input';
import WebServiceWrapper from 'components/web_service_wrapper';
import React, { useState } from 'react';

const WebServiceMessengerChatChangeName = (props: any) => {
  const { openChangeName, setOpenChangeName, setShowToast } = props;
  const [inputValue, setInputValue] = useState('기존 채팅방 이름');
  const handleChange = (e: any) => {
    setInputValue(e.target.value);
  };

  const onReset = () => {
    setInputValue('');
  };

  const rightOnClick = (t: any) => {
    setOpenChangeName(false);
    setShowToast({ text: t, show: true });
  };

  return (
    <WebServiceWrapper hideNav={false} isCalling={false} scrollLock={''}>
      <WebServiceCenterTitleHeader
        title="채팅방 이름 변경"
        rightText={'저장'}
        rightOnClick={() => rightOnClick('채팅방 이름이 변경되었습니다.')}
        backState={openChangeName}
        setBackState={setOpenChangeName}
        leftIcon="close"
      />
      <WebServiceNormalBody type="normal-78" useOverlay={false} background="">
        <div className="web-service-messenger-chat-change-name-wrap">
          <WebServiceInput
            hasLabel={false}
            type="withRemove"
            inputType="text"
            placeholder="변경할 채팅방 이름"
            inputValue={inputValue}
            onChange={handleChange}
            onReset={onReset}
          />
        </div>
      </WebServiceNormalBody>
    </WebServiceWrapper>
  );
};

export default WebServiceMessengerChatChangeName;
