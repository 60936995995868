/* eslint-disable operator-linebreak */
import React from 'react';
import store from 'redux/store';
import { Link } from 'react-router-dom';

import 'stylesheets/web_service/nav.scss';
import routes from 'configs/routes';

const WebServiceDialHeader = (props: any) => {
  const { title, onButtonClick } = props;

  return (
    <div className="web-service-left-title-header-container">
      <div className="web-service-left-title-header-left">
        <div className="web-service-left-title-header-title">{title}</div>
      </div>
      <div className="web-service-left-title-header-right">
        <Link to={routes.web_service_group_call_search}>
          <div className="web-service-group-call-clickarea">
            <div className="web-service-group-call-button">
              <img
                src={
                  '/icon/' +
                  (store.getState().theme.theme === 'light'
                    ? 'lightmode/'
                    : 'darkmode/') +
                  'call-fill-t2.png'
                }
                className="web-service-group-call-icon"
                alt="group call"
              />
              <div className="web-service-group-call-text">그룹콜</div>
            </div>
          </div>
        </Link>
        <div
          className="web-service-icon-button-clickarea"
          onClick={onButtonClick}
        >
          <img
            src={
              '/icon/' +
              (store.getState().theme.theme === 'light'
                ? 'lightmode/'
                : 'darkmode/') +
              'search-outline-t1.png'
            }
            className="web-service-left-title-right-icon"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default WebServiceDialHeader;
