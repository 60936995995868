/* eslint-disable indent */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import store from 'redux/store';

import WebServiceNormalBody from 'components/web_service/body/normal_body';
import WebServiceWrapper from 'components/web_service_wrapper';
import WebServiceCenterTitleHeader from 'components/web_service/header/center_title';
import WebServiceInput from 'components/web_service/input/input';
import WebServiceSubTitle from 'components/web_service/content_title/sub_title';

import 'stylesheets/web_service/search.scss';
import routes from 'configs/routes';
import WebServiceInviteSelectedGroup from 'components/web_service/search/group_call/selected_group';
import WebServiceSearchResultCardContact from 'components/web_service/search/result_card_contact';
import WebServiceBottomFixedPrimaryButton from 'components/web_service/fixed_component/primary_button';

const WebServiceGroupCallSearch = () => {
  const [inputValue, setInputValue] = useState('');
  const [isChecked, setIsChecked] = useState<number[]>([]);
  const [equalList, setEqualList] = useState<any[]>([]);
  const contactData = [
    {
      type: 'withCheckbox',
      name: '홍길동',
      number: '010-1234-1234',
      profile: 'profile.jpg',
      state: 'online'
    },
    {
      type: 'withCheckbox',
      name: '김정수',
      number: '010-1234-1235',
      profile: 'profile.jpg',
      state: 'calling'
    },
    {
      type: 'withCheckbox',
      name: '김민수',
      number: '010-1234-1236',
      profile: 'profile.jpg',
      state: 'idle'
    },
    {
      type: 'withCheckbox',
      name: '010-1234-1238',
      number: '010-1234-1237',
      profile: 'profile.jpg',
      state: 'DNdisturb'
    },
    {
      type: 'withCheckbox',
      name: '주성치',
      number: '010-1234-1238',
      profile: 'profile.jpg',
      state: 'online'
    },
    {
      type: 'withCheckbox',
      name: '홍길동',
      number: '010-1234-1239',
      profile: 'profile.jpg',
      state: 'online'
    },
    {
      type: 'withCheckbox',
      name: '기름집',
      number: '1544-0000',
      profile: 'profile.jpg',
      state: 'offline'
    },
    {
      type: 'withCheckbox',
      name: '박민주',
      number: '010-1234-1234',
      profile: 'profile.jpg',
      state: 'online'
    },
    {
      type: 'withCheckbox',
      name: '중갓동',
      number: '010-0000-1234',
      profile: 'profile.jpg',
      state: 'online'
    },
    {
      type: 'withCheckbox',
      name: '나성현',
      number: '010-1234-1234',
      profile: 'profile.jpg',
      state: 'online'
    }
  ];

  const handleChange = (e: any) => {
    setInputValue(e.target.value);
  };

  useEffect(() => {
    setEqualList([]);
    let checkEqual: any = [];
    let checkItem: any;

    contactData.forEach((item: any, idx: number) => {
      if (
        item.name.indexOf(inputValue) > -1 &&
        item.number?.replaceAll('-', '').indexOf(inputValue) === -1
      ) {
        checkEqual = [...checkEqual, Object.assign(item, { seq: idx })];
        for (let i = 0; i < inputValue.length; i++) {
          item.equalNameStart = item.name.indexOf(inputValue);
        }
      } else if (
        item.name.indexOf(inputValue) === -1 &&
        item.number?.replaceAll('-', '').indexOf(inputValue) > -1
      ) {
        checkItem = item.number.toString();
        let checkHypen: any = [];
        while (checkItem.indexOf('-') > -1) {
          checkHypen = [...checkHypen, checkItem.indexOf('-')];
          checkItem = checkItem.replace('-', '');
        }
        Object.assign(item, { hypen: checkHypen });
        checkEqual = [...checkEqual, Object.assign(item, { seq: idx })];
        for (let i = 0; i < inputValue.length; i++) {
          item.equalNumberStart = item.number
            .replaceAll('-', '')
            .indexOf(inputValue);
        }
      }
    });
    setEqualList(checkEqual);
  }, [inputValue]);

  const onReset = () => {
    setInputValue('');
  };

  const checkboxHandleChange = (idx: number) => {
    if (isChecked.includes(idx)) {
      const activeList = isChecked.filter((i) => idx !== i);
      setIsChecked(activeList);
    } else {
      setIsChecked([...isChecked, idx]);
    }
  };

  return (
    <WebServiceWrapper hideNav={false} isCalling={false} scrollLock={false}>
      <WebServiceCenterTitleHeader
        title="그룹콜"
        onBackClick={routes.web_service_dial}
      />
      <WebServiceNormalBody
        type="bottom-button"
        useOverlay={false}
        background=""
      >
        <WebServiceInviteSelectedGroup
          users={
            isChecked.length > 0
              ? isChecked.map((item: any, idx: number) => contactData[item])
              : []
          }
        />
        <div className="web-service-invite-search-input-wrap">
          <WebServiceInput
            hasLabel={false}
            type="withRemove"
            inputType="text"
            placeholder="이름 또는 전화번호 검색"
            inputValue={inputValue}
            onChange={handleChange}
            onReset={onReset}
          />
        </div>
        {inputValue ? (
          <>
            {!Number.isNaN(Number(inputValue)) ? (
              <div className="web-service-invite-search-add-button-wrap">
                <button className="web-service-invite-search-add-button">
                  <div className="web-service-invite-search-add-button-img-wrap">
                    <img
                      src={
                        '/icon/' +
                        (store.getState().theme.theme === 'light'
                          ? 'lightmode/'
                          : 'darkmode/') +
                        'add_circle-outline-t2.png'
                      }
                      className="web-service-invite-search-add-button-img"
                      alt="add"
                    />
                  </div>
                  <div className="web-service-invite-search-add-button-name">
                    {inputValue.substring(0, 2) === '02'
                      ? inputValue.replace(
                          /(\d{2})(\d{3,4})(\d{4})/,
                          '$1-$2-$3'
                        )
                      : inputValue.replace(
                          /(\d{2,3})(\d{3,4})(\d{4})/,
                          '$1-$2-$3'
                        )}{' '}
                    추가하기
                  </div>
                </button>
              </div>
            ) : null}
            {equalList?.map((item: any, idx: number) => (
              <WebServiceSearchResultCardContact
                key={idx}
                type={item.type}
                name={item.name}
                number={item.number}
                padding={true}
                profile={item.profile}
                state={item.state}
                canSpread={false}
                hoverEffect={true}
                equalNameStart={item.equalNameStart}
                equalNumberStart={item.equalNumberStart}
                inputValue={inputValue}
                onClick={() => checkboxHandleChange(item.seq)}
                isChecked={isChecked.includes(item.seq)}
                hypen={item.hypen}
              />
            ))}
            <WebServiceBottomFixedPrimaryButton
              isDisabled={true}
              buttonName="그룹콜 시작"
            />
          </>
        ) : (
          <>
            <WebServiceSubTitle title="ㄱ" underline />
            {contactData.map((item: any, idx: any) => (
              <WebServiceSearchResultCardContact
                key={idx}
                type={item.type}
                name={item.name}
                number={item.number}
                padding={true}
                profile={item.profile}
                canSpread={false}
                onClick={() => checkboxHandleChange(idx)}
                isChecked={isChecked.includes(idx)}
                state={item.state}
                hoverEffect={true}
              />
            ))}
            <WebServiceBottomFixedPrimaryButton
              buttonName={
                isChecked.length > 0
                  ? isChecked.length + '명 초대'
                  : '초대할 대상을 선택하세요'
              }
              isDisabled={isChecked.length > 0 ? false : true}
            />
          </>
        )}
      </WebServiceNormalBody>
    </WebServiceWrapper>
  );
};

export default WebServiceGroupCallSearch;
