/* eslint-disable max-len */
/* eslint-disable indent */

import React from 'react';
import store from 'redux/store';

const WebServiceMessengerChatMenu = (props: any) => {
  const {
    openMenu,
    setOpenMenu,
    setShowImgStorage,
    setShowFileStorage,
    setOpenChangeName,
    alarmHandleChagne,
    useAlarm,
    setOpenInvite
  } = props;
  return (
    <div className={'web-service-sheet-area' + (openMenu ? ' active' : '')}>
      <div
        className={'web-service-overlay' + (openMenu ? ' active' : '')}
        onClick={() => {
          setOpenMenu(false);
        }}
      />
      <div
        className={
          'web-service-messenger-chat-menu' + (openMenu ? ' active' : '')
        }
      >
        <div className="web-service-messenger-chat-menu-relative">
          <div className="web-service-messenger-chat-menu-section">
            <div className="web-service-messenger-chat-menu-subtitle">
              <div className="web-service-messenger-chat-menu-subtitle-underline">
                사진 및 파일
              </div>
            </div>
            <div
              className="web-service-messenger-chat-menu-icon-wrap"
              onClick={() => setShowImgStorage(true)}
            >
              <div className="web-service-messenger-chat-menu-icon-underline">
                <img
                  src={
                    '/icon/' +
                    (store.getState().theme.theme === 'light'
                      ? 'lightmode/'
                      : 'darkmode/') +
                    'image-outline-t1.png'
                  }
                  className="web-service-messenger-chat-menu-icon"
                  alt="image"
                />
                이미지 전체보기
              </div>
            </div>
            <div
              className="web-service-messenger-chat-menu-icon-wrap"
              onClick={() => setShowFileStorage(true)}
            >
              <div className="web-service-messenger-chat-menu-icon-underline">
                <img
                  src={
                    '/icon/' +
                    (store.getState().theme.theme === 'light'
                      ? 'lightmode/'
                      : 'darkmode/') +
                    'attached_file-outline-t1.png'
                  }
                  className="web-service-messenger-chat-menu-icon"
                  alt="image"
                />
                파일 전체보기
              </div>
            </div>
          </div>
          <div className="web-service-messenger-chat-menu-section">
            <div className="web-service-messenger-chat-menu-subtitle">
              <div className="web-service-messenger-chat-menu-subtitle-underline">
                채팅방 설정
              </div>
            </div>
            <div
              className="web-service-messenger-chat-menu-icon-wrap"
              onClick={() => setOpenChangeName(true)}
            >
              <div className="web-service-messenger-chat-menu-icon-underline">
                <img
                  src={
                    '/icon/' +
                    (store.getState().theme.theme === 'light'
                      ? 'lightmode/'
                      : 'darkmode/') +
                    'write-outline-t1.png'
                  }
                  className="web-service-messenger-chat-menu-icon"
                  alt="image"
                />
                채팅방 이름 변경
              </div>
            </div>
            <div
              className="web-service-messenger-chat-menu-icon-wrap"
              onClick={() => alarmHandleChagne()}
            >
              <div className="web-service-messenger-chat-menu-icon-underline">
                <img
                  src={
                    '/icon/' +
                    (store.getState().theme.theme === 'light'
                      ? 'lightmode/'
                      : 'darkmode/') +
                    (useAlarm
                      ? 'alarm-outline-t1.png'
                      : 'alarm_off-outline-t1.png')
                  }
                  className="web-service-messenger-chat-menu-icon"
                  alt="image"
                />
                {useAlarm ? '알람 켜짐' : '알람 꺼짐'}
              </div>
            </div>
          </div>
          <div className="web-service-messenger-chat-menu-section">
            <div className="web-service-messenger-chat-menu-subtitle">
              <div className="web-service-messenger-chat-menu-subtitle-underline">
                채팅방 멤버
              </div>
            </div>
            <div
              className="web-service-messenger-chat-menu-member"
              onClick={() => setOpenInvite(true)}
            >
              <div className="web-service-messenger-chat-menu-member-underline">
                <div className="web-service-messenger-chat-menu-member-invite-icon-wrap">
                  <img
                    src={
                      '/icon/' +
                      (store.getState().theme.theme === 'light'
                        ? 'lightmode/'
                        : 'darkmode/') +
                      'add_circle-outline-t2.png'
                    }
                    className="web-service-messenger-chat-menu-member-invite-icon"
                    alt="invite member"
                  />
                </div>
                멤버 초대
              </div>
            </div>
            <div className="web-service-messenger-chat-menu-member">
              <div className="web-service-messenger-chat-menu-member-underline">
                <img
                  src={'/img/profile/profile.jpg'}
                  className="web-service-messenger-chat-menu-member-profile"
                  alt="invite member"
                />
                <div className="web-service-messenger-chat-menu-member-name-wrap">
                  <div className="web-service-messenger-chat-menu-member-name">
                    홍길동 대리
                  </div>
                  <div className="web-service-messenger-chat-menu-member-company">
                    주식회사 가나다
                  </div>
                </div>
              </div>
            </div>
            <div className="web-service-messenger-chat-menu-member">
              <div className="web-service-messenger-chat-menu-member-underline">
                <img
                  src={'/img/profile/profile.jpg'}
                  className="web-service-messenger-chat-menu-member-profile"
                  alt="invite member"
                />
                <div className="web-service-messenger-chat-menu-member-name-wrap">
                  <div className="web-service-messenger-chat-menu-member-name">
                    홍길동 대리
                  </div>
                  <div className="web-service-messenger-chat-menu-member-company">
                    주식회사 가나다
                  </div>
                </div>
              </div>
            </div>
            <div className="web-service-messenger-chat-menu-member">
              <div className="web-service-messenger-chat-menu-member-underline">
                <img
                  src={'/img/profile/profile.jpg'}
                  className="web-service-messenger-chat-menu-member-profile"
                  alt="invite member"
                />
                <div className="web-service-messenger-chat-menu-member-name-wrap">
                  <div className="web-service-messenger-chat-menu-member-name">
                    홍길동 대리
                  </div>
                  <div className="web-service-messenger-chat-menu-member-company">
                    주식회사 가나다
                  </div>
                </div>
              </div>
            </div>
            <div className="web-service-messenger-chat-menu-member">
              <div className="web-service-messenger-chat-menu-member-underline">
                <img
                  src={'/img/profile/profile.jpg'}
                  className="web-service-messenger-chat-menu-member-profile"
                  alt="invite member"
                />
                <div className="web-service-messenger-chat-menu-member-name-wrap">
                  <div className="web-service-messenger-chat-menu-member-name">
                    홍길동 대리
                  </div>
                  <div className="web-service-messenger-chat-menu-member-company">
                    주식회사 가나다
                  </div>
                </div>
              </div>
            </div>
            <div className="web-service-messenger-chat-menu-member">
              <div className="web-service-messenger-chat-menu-member-underline">
                <img
                  src={'/img/profile/profile.jpg'}
                  className="web-service-messenger-chat-menu-member-profile"
                  alt="invite member"
                />
                <div className="web-service-messenger-chat-menu-member-name-wrap">
                  <div className="web-service-messenger-chat-menu-member-name">
                    홍길동 대리
                  </div>
                  <div className="web-service-messenger-chat-menu-member-company">
                    주식회사 가나다
                  </div>
                </div>
              </div>
            </div>
            <div className="web-service-messenger-chat-menu-member">
              <div className="web-service-messenger-chat-menu-member-underline">
                <img
                  src={'/img/profile/profile.jpg'}
                  className="web-service-messenger-chat-menu-member-profile"
                  alt="invite member"
                />
                <div className="web-service-messenger-chat-menu-member-name-wrap">
                  <div className="web-service-messenger-chat-menu-member-name">
                    홍길동 대리
                  </div>
                  <div className="web-service-messenger-chat-menu-member-company">
                    주식회사 가나다
                  </div>
                </div>
              </div>
            </div>
            <div className="web-service-messenger-chat-menu-member">
              <div className="web-service-messenger-chat-menu-member-underline">
                <img
                  src={'/img/profile/profile.jpg'}
                  className="web-service-messenger-chat-menu-member-profile"
                  alt="invite member"
                />
                <div className="web-service-messenger-chat-menu-member-name-wrap">
                  <div className="web-service-messenger-chat-menu-member-name">
                    홍길동 대리
                  </div>
                  <div className="web-service-messenger-chat-menu-member-company">
                    주식회사 가나다
                  </div>
                </div>
              </div>
            </div>
            <div className="web-service-messenger-chat-menu-member">
              <div className="web-service-messenger-chat-menu-member-underline">
                <img
                  src={'/img/profile/profile.jpg'}
                  className="web-service-messenger-chat-menu-member-profile"
                  alt="invite member"
                />
                <div className="web-service-messenger-chat-menu-member-name-wrap">
                  <div className="web-service-messenger-chat-menu-member-name">
                    홍길동 대리
                  </div>
                  <div className="web-service-messenger-chat-menu-member-company">
                    주식회사 가나다
                  </div>
                </div>
              </div>
            </div>
            <div className="web-service-messenger-chat-menu-member">
              <div className="web-service-messenger-chat-menu-member-underline">
                <img
                  src={'/img/profile/profile.jpg'}
                  className="web-service-messenger-chat-menu-member-profile"
                  alt="invite member"
                />
                <div className="web-service-messenger-chat-menu-member-name-wrap">
                  <div className="web-service-messenger-chat-menu-member-name">
                    홍길동 대리
                  </div>
                  <div className="web-service-messenger-chat-menu-member-company">
                    주식회사 가나다
                  </div>
                </div>
              </div>
            </div>
            <div className="web-service-messenger-chat-menu-member">
              <div className="web-service-messenger-chat-menu-member-underline">
                <img
                  src={'/img/profile/profile.jpg'}
                  className="web-service-messenger-chat-menu-member-profile"
                  alt="invite member"
                />
                <div className="web-service-messenger-chat-menu-member-name-wrap">
                  <div className="web-service-messenger-chat-menu-member-name">
                    홍길동 대리홍길동 대리홍길동 대리홍길동 대리
                  </div>
                  <div className="web-service-messenger-chat-menu-member-company">
                    주식회사 가나다주식회사 가나다주식회사 가나다주식회사 가나다
                  </div>
                </div>
              </div>
            </div>
            <div className="web-service-messenger-chat-menu-member">
              <div className="web-service-messenger-chat-menu-member-underline">
                <img
                  src={'/img/profile/profile.jpg'}
                  className="web-service-messenger-chat-menu-member-profile"
                  alt="invite member"
                />
                <div className="web-service-messenger-chat-menu-member-name-wrap">
                  <div className="web-service-messenger-chat-menu-member-name">
                    홍길동 대리
                  </div>
                  <div className="web-service-messenger-chat-menu-member-company">
                    주식회사 가나다
                  </div>
                </div>
              </div>
            </div>
            <div className="web-service-messenger-chat-menu-member">
              <div className="web-service-messenger-chat-menu-member-underline">
                <img
                  src={'/img/profile/profile.jpg'}
                  className="web-service-messenger-chat-menu-member-profile"
                  alt="invite member"
                />
                <div className="web-service-messenger-chat-menu-member-name-wrap">
                  <div className="web-service-messenger-chat-menu-member-name">
                    홍길동 대리
                  </div>
                  <div className="web-service-messenger-chat-menu-member-company">
                    주식회사 가나다
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              'web-service-messenger-chat-menu-foot' +
              (openMenu ? ' active' : '')
            }
          >
            <img
              src={
                '/icon/' +
                (store.getState().theme.theme === 'light'
                  ? 'lightmode/'
                  : 'darkmode/') +
                'out-outline-t1.png'
              }
              className="web-service-messenger-chat-menu-out-icon"
              alt="out"
            />
            채팅방 나가기
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebServiceMessengerChatMenu;
