/* eslint-disable indent */
import React from 'react';

import WebServiceWrapper from 'components/web_service_wrapper';
import WebServiceNormalBody from 'components/web_service/body/normal_body';
import WebServiceCenterTitleHeader from 'components/web_service/header/center_title';
import WebServiceMoreCard from 'components/more/card';
import { switchUseRecord } from 'redux/more/record_setting';
import { useDispatch, useSelector } from 'react-redux';

const WebServiceMoreRecord = () => {
  const dispatch = useDispatch();
  const { recordSetting }: any = useSelector((state) => state);
  return (
    <WebServiceWrapper hideNav={false} isCalling={false} scrollLock={false}>
      <WebServiceCenterTitleHeader title="착신 전환 설정" />
      <WebServiceNormalBody type="more-78" useOverlay={false} background="">
        <WebServiceMoreCard
          type="switch"
          name="녹취 사용"
          bottomBorder={false}
          isSwitchOn={recordSetting.useRecord}
          outerText={`앱을 통한 모든 통화를 녹취합니다. \n (녹취된 파일은 서버에서 관리됩니다.)`}
          onClick={() => dispatch(switchUseRecord())}
        />
      </WebServiceNormalBody>
    </WebServiceWrapper>
  );
};

export default WebServiceMoreRecord;
