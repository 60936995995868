/* eslint-disable react/prop-types */
/* eslint-disable operator-linebreak */
import React, { MutableRefObject, useRef } from 'react';
import { Link } from 'react-router-dom';
import store from 'redux/store';

import 'stylesheets/web_service/dial.scss';

const WebServiceDialPad = (props: any) => {
  const dialInput = useRef() as MutableRefObject<HTMLInputElement>;
  const {
    type,
    onHideClick,
    inputValue,
    setInputValue,
    inputValueChange,
    receiverName
  } = props;

  const dialButtonClick = (buttonInput: string) => {
    setInputValue(inputValue + buttonInput);
    dialInput.current.focus();
  };

  const deleteClick = () => {
    setInputValue(inputValue.slice(0, -1));
    dialInput.current.focus();
  };

  return (
    <>
      <div className="web-service-dial-input-wrap">
        <input
          className="web-service-dial-input"
          type="text"
          value={inputValue}
          onChange={inputValueChange}
          autoFocus
          ref={dialInput}
        />
        {type === 'calling' ? null : (
          <div className="web-service-dial-name">{receiverName}</div>
        )}
      </div>
      <div className="web-service-dial-wrap">
        <div className="web-service-dial-row">
          <div
            className="web-service-dial-btn"
            onClick={() => dialButtonClick('1')}
          >
            1
          </div>
          <div
            className="web-service-dial-btn"
            onClick={() => dialButtonClick('2')}
          >
            2
          </div>
          <div
            className="web-service-dial-btn"
            onClick={() => dialButtonClick('3')}
          >
            3
          </div>
        </div>
        <div className="web-service-dial-row">
          <div
            className="web-service-dial-btn"
            onClick={() => dialButtonClick('4')}
          >
            4
          </div>
          <div
            className="web-service-dial-btn"
            onClick={() => dialButtonClick('5')}
          >
            5
          </div>
          <div
            className="web-service-dial-btn"
            onClick={() => dialButtonClick('6')}
          >
            6
          </div>
        </div>
        <div className="web-service-dial-row">
          <div
            className="web-service-dial-btn"
            onClick={() => dialButtonClick('7')}
          >
            7
          </div>
          <div
            className="web-service-dial-btn"
            onClick={() => dialButtonClick('8')}
          >
            8
          </div>
          <div
            className="web-service-dial-btn"
            onClick={() => dialButtonClick('9')}
          >
            9
          </div>
        </div>
        <div className="web-service-dial-row">
          <div
            className="web-service-dial-btn"
            onClick={() => dialButtonClick('*')}
          >
            <img
              src={
                '/icon/' +
                (store.getState().theme.theme === 'light'
                  ? 'lightmode/'
                  : 'darkmode/') +
                'dial/star.png'
              }
              className="web-service-dial-char"
              alt="star"
            />
          </div>
          <div
            className="web-service-dial-btn"
            onClick={() => dialButtonClick('0')}
          >
            0
          </div>
          <div
            className="web-service-dial-btn"
            onClick={() => dialButtonClick('#')}
          >
            <img
              src={
                '/icon/' +
                (store.getState().theme.theme === 'light'
                  ? 'lightmode/'
                  : 'darkmode/') +
                'dial/sharp.png'
              }
              className="web-service-dial-char"
              alt="sharp"
            />
          </div>
        </div>
        <div className="web-service-dial-row">
          <div className="web-service-dial-btn-empty"></div>
          {type === 'calling' ? (
            <div className="web-service-dial-btn-hang-up-wrap">
              <div className="web-service-dial-btn-hang-up">
                <img
                  src={'/icon/common/no_call.png'}
                  className="web-service-dial-hang-up"
                  alt="hang-up"
                />
              </div>
            </div>
          ) : (
            <Link to="/app/web_service/call">
              <div className="web-service-dial-btn-call">
                <img
                  src={
                    '/icon/' +
                    (store.getState().theme.theme === 'light'
                      ? 'lightmode/'
                      : 'darkmode/') +
                    'dial/call.png'
                  }
                  className="web-service-dial-call"
                  alt="call"
                />
              </div>
            </Link>
          )}
          {type === 'calling' ? (
            <div className="web-service-dial-btn" onClick={onHideClick}>
              <div className="web-service-dial-hide">가리기</div>
            </div>
          ) : (
            <div className="web-service-dial-btn" onClick={deleteClick}>
              <img
                src={
                  '/icon/' +
                  (store.getState().theme.theme === 'light'
                    ? 'lightmode/'
                    : 'darkmode/') +
                  'dial/delete.png'
                }
                className="web-service-dial-call"
                alt="delete"
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default WebServiceDialPad;
