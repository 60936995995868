/* eslint-disable max-len */
import WebServiceMoreCard from 'components/more/card';
import WebServiceMoreSubtitle from 'components/more/subtitle';
import WebServiceNormalBody from 'components/web_service/body/normal_body';
import WebServiceCenterTitleHeader from 'components/web_service/header/center_title';
import WebServiceWrapper from 'components/web_service_wrapper';
import React from 'react';

const WebServiceNetwork = () => {
  return (
    <WebServiceWrapper hideNav={false} isCalling={false} scrollLock={false}>
      <WebServiceCenterTitleHeader title="네트워크 상태" />
      <WebServiceNormalBody type="more" background="" useOverlay={false}>
        <WebServiceMoreSubtitle title="네트워크" />
        <WebServiceMoreCard
          type="textOnly"
          name="인터넷 연결 상태"
          bottomBorder={true}
          rightText="정상"
        />
        <WebServiceMoreCard
          type="textOnly"
          name="연결 속도"
          bottomBorder={true}
          rightText="5.20 mbps"
          rightTextColor="text1"
        />
        <WebServiceMoreSubtitle title="서비스 연결" />
        <WebServiceMoreCard
          type="textOnly"
          name="로그인 및 서비스"
          bottomBorder={true}
          rightText="정상"
        />
        <WebServiceMoreCard
          type="textOnly"
          name="장애감지"
          bottomBorder={true}
          rightText="정상"
        />
        <WebServiceMoreCard
          type="textOnly"
          name="업데이트 및 파일서버"
          bottomBorder={true}
          rightText="정상"
        />
        <WebServiceMoreCard
          type="textOnly"
          name="기타 서비스"
          bottomBorder={true}
          rightText="정상"
        />
      </WebServiceNormalBody>
    </WebServiceWrapper>
  );
};

export default WebServiceNetwork;
