/* eslint-disable max-len */
/* eslint-disable operator-linebreak */
import WebServiceWrapper from 'components/web_service_wrapper';
import WebServiceInput from 'components/web_service/input/input';
import WebServicePrimaryButton from 'components/web_service/button/primary_button';
import WebServiceSecondaryButton from 'components/web_service/button/secondary_button';
// import WebServiceOkModal from 'components/web_service/modal/ok_modal';
import WebServiceCheckbox from 'components/web_service/input/checkbox';
import React, { useState } from 'react';
import store from 'redux/store';

import 'stylesheets/web_service/login.scss';
import WebServiceBottomNav from 'components/web_service/nav/bottom';

const WebServiceLogIn = () => {
  const [isChecked, setIsChecked] = useState('false');

  return (
    <WebServiceWrapper hideNav={true} isCalling={false} scrollLock={false}>
      <div className="web-service-login-wrap">
        <img
          src={
            '/img/service/' +
            (store.getState().theme.theme === 'light'
              ? 'lightmode/'
              : 'darkmode/') +
            'logo_login.png'
          }
          className="web-service-login-logo"
          alt="touch call logo"
        />
        <div className="web-service-login-inputset">
          <WebServiceInput
            hasLabel={true}
            label="아이디"
            inputType="text"
            placeholder="아이디를 입력하세요"
          />
          <WebServiceInput
            hasLabel={true}
            label="비밀번호"
            inputType="password"
            placeholder="비밀번호를 입력하세요"
          />
        </div>
        <WebServicePrimaryButton isDisabled={false} buttonName="로그인" />
        <div
          className="web-service-login-loginset"
          onClick={() => {
            isChecked === 'true' ? setIsChecked('false') : setIsChecked('true');
          }}
        >
          <WebServiceCheckbox
            isLabel={true}
            label="자동 로그인"
            isChecked={isChecked}
            hoverEffect={true}
          />
        </div>
        <div className="web-service-subbtnset">
          <WebServiceSecondaryButton
            isDisabled={false}
            buttonName="비밀번호 찾기"
            size="small"
          />
          <WebServiceSecondaryButton
            isDisabled={false}
            buttonName="가이드"
            size="small"
          />
        </div>
      </div>
      {/* <WebServiceOkModal
        title="로그인 실패"
        text="아이디 또는 비밀번호를 확인하세요"
        buttonName="확인"
      /> */}
      <WebServiceBottomNav />
    </WebServiceWrapper>
  );
};

export default WebServiceLogIn;
