/* eslint-disable indent */
/* eslint-disable react/prop-types */
/* eslint-disable operator-linebreak */
import React from 'react';
import store from 'redux/store';
import 'stylesheets/web_service/chat.scss';

const WebServiceChatBubbleFile = (props: any) => {
  const {
    userId,
    fileName,
    fileSize,
    fileFormat,
    fileDownloadPeriod,
    fileDownloaded
  } = props;

  return (
    <>
      {fileDownloaded && fileFormat === 'compressedFile' ? (
        <div
          className={
            'web-service-chat-bubble-file' +
            (userId === 'myId' ? ' myBubble' : '')
          }
        >
          <div className="web-service-chat-bubble-file-icon-wrap">
            <img
              src={
                userId === 'myId'
                  ? '/icon/common/compress_file.png'
                  : '/icon/' +
                    (store.getState().theme.theme === 'light'
                      ? 'lightmode/'
                      : 'darkmode/') +
                    'compress_file-outline-p1.png'
              }
              className="web-service-chat-bubble-file-icon"
            />
          </div>
          <div className="web-service-chat-bubble-file-info">
            <div
              className={
                'web-service-chat-bubble-file-name' +
                (userId === 'myId' ? ' myBubble' : '')
              }
            >
              {fileName}
            </div>
            <div
              className={
                'web-service-chat-bubble-file-subInfo' +
                (userId === 'myId' ? ' myBubble' : '')
              }
            >
              <div>{fileSize}</div>
              <div>{fileDownloadPeriod + '까지'}</div>
            </div>
          </div>
        </div>
      ) : null}

      {fileDownloaded && fileFormat === 'document' ? (
        <div
          className={
            'web-service-chat-bubble-file' +
            (userId === 'myId' ? ' myBubble' : '')
          }
        >
          <div className="web-service-chat-bubble-file-icon-wrap">
            <img
              src={
                userId === 'myId'
                  ? '/icon/common/memo_file.png'
                  : '/icon/' +
                    (store.getState().theme.theme === 'light'
                      ? 'lightmode/'
                      : 'darkmode/') +
                    'memo_file-outline-p1.png'
              }
              className="web-service-chat-bubble-file-icon"
            />
          </div>
          <div className="web-service-chat-bubble-file-info">
            <div
              className={
                'web-service-chat-bubble-file-name' +
                (userId === 'myId' ? ' myBubble' : '')
              }
            >
              {fileName}
            </div>
            <div
              className={
                'web-service-chat-bubble-file-subInfo' +
                (userId === 'myId' ? ' myBubble' : '')
              }
            >
              <div>{fileSize}</div>
              <div>{fileDownloadPeriod + '까지'}</div>
            </div>
          </div>
        </div>
      ) : null}

      {!fileDownloaded ? (
        <div
          className={
            'web-service-chat-bubble-file' +
            (userId === 'myId' ? ' myBubble' : '')
          }
        >
          <div className="web-service-chat-bubble-file-icon-wrap">
            <img
              src={
                userId === 'myId'
                  ? '/icon/common/download.png'
                  : '/icon/' +
                    (store.getState().theme.theme === 'light'
                      ? 'lightmode/'
                      : 'darkmode/') +
                    'download-outline-t2.png'
              }
              className="web-service-chat-bubble-file-icon"
            />
          </div>
          <div className="web-service-chat-bubble-file-info">
            <div
              className={
                'web-service-chat-bubble-file-name' +
                (userId === 'myId' ? ' myBubble' : '')
              }
            >
              {fileName}
            </div>
            <div
              className={
                'web-service-chat-bubble-file-subInfo' +
                (userId === 'myId' ? ' myBubble' : '')
              }
            >
              <div>{fileSize}</div>
              <div>{fileDownloadPeriod + '까지'}</div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default WebServiceChatBubbleFile;
