/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import routes from 'configs/routes';

import WebServiceWrapper from 'components/web_service_wrapper';
import WebServiceSearchHeader from 'components/web_service/header/search';
import WebServiceNormalBody from 'components/web_service/body/normal_body';
import WebServiceNoResult from 'components/web_service/no_result/no_result';
import WebServiceSMSHeader from 'components/web_service/header/sms';
import WebServiceSMSListCard from 'components/web_service/sms/sms_list_card';
import WebServiceSelectHeader from 'components/web_service/header/select';
import WebServiceSearchTabGroup from 'components/web_service/search/dial/search_tab_group';
import { ContactListItems, SMSListItems } from 'configs/items';
import WebServiceCenterTitleHeader from 'components/web_service/header/center_title';
import WebServiceSMSSelectReceiver from 'components/web_service/sms/sms_select_receiver';

const WebServiceSMS = () => {
  const [searchValue, setSearchValue] = useState('');
  const [searchMode, setSearchMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [writeMode, setWriteMode] = useState(false);
  const [isChecked, setIsChecked] = useState<number[]>([]);
  const [isCheckedAll, setIsCheckedAll] = useState(false);

  const handleChange = (e: any) => {
    setSearchValue(e.target.value);
  };

  const onReset = () => {
    setSearchValue('');
  };

  const checkboxHandleChange = (idx: number) => {
    if (isChecked.includes(idx)) {
      const activeList = isChecked.filter((i) => idx !== i);
      setIsChecked(activeList);
    } else {
      setIsChecked([...isChecked, idx]);
    }
  };

  const checkboxAllHandleChange = () => {
    if (!isCheckedAll) {
      const activeList = SMSListItems.map((v, i) => i);
      setIsChecked(activeList);
    } else {
      setIsChecked([]);
    }
    setIsCheckedAll(!isCheckedAll);
  };

  useEffect(() => {
    isChecked.length === SMSListItems.length
      ? setIsCheckedAll(true)
      : setIsCheckedAll(false);
  }, [isChecked]);

  return (
    <WebServiceWrapper hideNav={false} isCalling={false} scrollLock={false}>
      {searchMode && !editMode && !writeMode ? (
        <WebServiceSearchHeader
          onCancel={() => setSearchMode(false)}
          onSearchValueChange={handleChange}
          onSearchValueRemove={onReset}
          searchValue={searchValue}
        />
      ) : null}
      {!searchMode && editMode && !writeMode ? (
        <WebServiceSelectHeader
          isChecked={isCheckedAll}
          onClick={checkboxAllHandleChange}
          onCancel={() => setEditMode(false)}
        />
      ) : null}
      {!searchMode && !editMode && writeMode ? (
        <WebServiceCenterTitleHeader
          title={'메세지 작성'}
          backState={writeMode}
          setBackState={setWriteMode}
        />
      ) : null}
      {!searchMode && !editMode && !writeMode ? (
        <WebServiceSMSHeader
          title="문자메시지"
          onSearchButtonClick={() => setSearchMode(true)}
          onSettingButtonClick={() => setEditMode(!editMode)}
          onWriteButtonClick={() => setWriteMode(true)}
        />
      ) : null}
      <WebServiceNormalBody type="sms" useOverlay={false} background="">
        {searchMode && !editMode && !writeMode ? (
          <WebServiceSearchTabGroup
            searchValue={searchValue}
            from="sms"
            dataFirst={ContactListItems}
            dataSecond={SMSListItems}
            tabName1="연락처"
            tabName2="문자메시지"
          />
        ) : !searchMode && !editMode && writeMode ? (
          <WebServiceSMSSelectReceiver />
        ) : !searchMode && editMode && !writeMode ? (
          SMSListItems.map((item: any, idx: number) => (
            <WebServiceSMSListCard
              key={idx}
              name={item.name}
              text={item.text}
              date={item.date}
              alarmAmount={item.alarmAmount}
              isChecked={isChecked.includes(idx)}
              onClick={
                editMode
                  ? () => checkboxHandleChange(idx)
                  : () => console.log('enter sms chat')
              }
              editMode={editMode}
              profile={item.profile}
              state={item.state}
            />
          ))
        ) : (
          SMSListItems.map((item: any, idx: number) => (
            <Link to={routes.web_service_sms_chat} key={idx}>
              <WebServiceSMSListCard
                name={item.name}
                text={item.text}
                date={item.date}
                alarmAmount={item.alarmAmount}
                isChecked={isChecked.includes(idx)}
                onClick={
                  editMode
                    ? () => checkboxHandleChange(idx)
                    : () => console.log('enter sms chat')
                }
                editMode={editMode}
                profile={item.profile}
                state={item.state}
              />
            </Link>
          ))
        )}

        {SMSListItems.length === 0 ? (
          <WebServiceNoResult
            type="sms"
            text={
              '문자 메시지를 보낸 기록이 없습니다.\n첫 메시지를 작성해보세요.'
            }
          />
        ) : null}
      </WebServiceNormalBody>
    </WebServiceWrapper>
  );
};

export default WebServiceSMS;
