/* eslint-disable indent */
/* eslint-disable react/prop-types */
/* eslint-disable operator-linebreak */
import React, { useState } from 'react';

import WebServiceSearchTab from '../tab';
import WebServiceSearchResultGroupShort from '../result_group_short';
import WebServiceSearchResultCardContact from '../result_card_contact';
import WebServiceSearchResultCardCallHistory from '../result_card_call_history';
import WebServiceSearchHistory from '../search_history';
import WebServiceSMSListCard from 'components/web_service/sms/sms_list_card';
import WebServiceNoResult from 'components/web_service/no_result/no_result';

const WebServiceSearchTabGroup = (props: any) => {
  const {
    searchValue,
    from,
    dataFirst,
    dataSecond,
    tabName1,
    tabName2,
    onCardClick,
    equalList
  } = props;
  const [tabSelected, setTabSelected] = useState(0);
  const [searchHistory, setSearchHistory] = useState([
    {
      keyword: '홍길동',
      date: '4일 전',
      link: '/app/web_service/contact/profile'
    },
    {
      keyword: '홍길동',
      date: '4일 전',
      link: '/app/web_service/contact/profile'
    },
    {
      keyword: '홍길동',
      date: '4일 전',
      link: '/app/web_service/contact/profile'
    },
    {
      keyword: '홍길동',
      date: '4일 전',
      link: '/app/web_service/contact/profile'
    },
    {
      keyword: '홍길동',
      date: '4일 전',
      link: '/app/web_service/contact/profile'
    }
  ]);

  const deleteHistory = (idx: number) => {
    setSearchHistory(
      searchHistory.filter((item) => item !== searchHistory[idx])
    );
  };

  return (
    <>
      {searchValue ? (
        <>
          <WebServiceSearchTab
            items={[
              {
                name: '전체',
                selected: tabSelected === 0,
                tabOnClick: () => setTabSelected(0)
              },
              {
                name: tabName1,
                selected: tabSelected === 1,
                tabOnClick: () => setTabSelected(1)
              },
              {
                name: tabName2,
                selected: tabSelected === 2,
                tabOnClick: () => setTabSelected(2)
              }
            ]}
          />
          <div className="web-service-search-result-groups-wrap">
            {tabSelected === 0 && equalList.length > 0 ? (
              <WebServiceSearchResultGroupShort
                type="contact"
                data={equalList}
                setTabSelected={setTabSelected}
                onCardClick={onCardClick}
              />
            ) : null}
            {from === 'dial' ? (
              <WebServiceSearchResultGroupShort
                type="call_log"
                data={dataSecond}
                setTabSelected={setTabSelected}
              />
            ) : from === 'sms' ? (
              <WebServiceSearchResultGroupShort type="sms" data={dataSecond} />
            ) : from === 'messenger' ? (
              <WebServiceSearchResultGroupShort
                type="messenger"
                data={dataSecond}
              />
            ) : null}
          </div>

          <div className="web-service-search-result-groups-wrap">
            <div className="web-service-search-group-all">
              {tabSelected === 1 && from === 'dial'
                ? dataFirst.map((item: any, idx: any) => (
                    <WebServiceSearchResultCardContact
                      key={idx}
                      equalName={item.equalName}
                      name={item.name}
                      equalNumber={item.equalNumber}
                      number={item.number}
                      profile={'profile.jpg'}
                      state={item.state}
                      hoverEffect={true}
                      onClick={() => onCardClick(item.number)}
                    />
                  ))
                : null}
              {tabSelected === 2 && from === 'dial'
                ? dataSecond.map((item: any, idx: any) => (
                    <WebServiceSearchResultCardCallHistory
                      key={idx}
                      callType={item.callType}
                      equalName={item.equalName}
                      name={item.name}
                      time={item.time}
                      canSpread={false}
                    />
                  ))
                : null}
              {tabSelected === 1 && from === 'sms'
                ? dataFirst.map((item: any, idx: any) => (
                    <WebServiceSearchResultCardContact
                      key={idx}
                      equalName={item.equalName}
                      name={item.name}
                      equalNumber={item.equalNumber}
                      number={item.number}
                      profile={'profile.jpg'}
                      hoverEffect={true}
                    />
                  ))
                : null}
              {tabSelected === 2 && from === 'sms'
                ? dataSecond.map((item: any, idx: any) => (
                    <WebServiceSMSListCard
                      key={idx}
                      name={item.name}
                      text={item.text}
                      date={item.date}
                      alarmAmount={item.alarmAmount}
                      editMode={false}
                      isChecked={false}
                      underline={true}
                    />
                  ))
                : null}
              {tabSelected === 1 && from === 'messenger'
                ? dataFirst.map((item: any, idx: any) => (
                    <WebServiceSearchResultCardContact
                      key={idx}
                      equalName={item.equalName}
                      name={item.name}
                      equalNumber={item.equalNumber}
                      number={item.number}
                      profile={'profile.jpg'}
                      hoverEffect={true}
                    />
                  ))
                : null}
              {tabSelected === 2 && from === 'messenger'
                ? dataSecond.map((item: any, idx: any) => (
                    <WebServiceSMSListCard
                      key={idx}
                      name={item.name}
                      text={item.text}
                      date={item.date}
                      alarmAmount={item.alarmAmount}
                      editMode={false}
                      isChecked={false}
                      underline={true}
                    />
                  ))
                : null}
            </div>
          </div>
        </>
      ) : searchHistory.length > 0 ? (
        <WebServiceSearchHistory
          items={searchHistory}
          onDeleteClick={deleteHistory}
        />
      ) : (
        <WebServiceNoResult
          icon="search-outline-t4.png"
          text="검색 기록이 없습니다."
          isButton={false}
        />
      )}
    </>
  );
};

export default WebServiceSearchTabGroup;
